{
  "glyphs": {
    "0": {
      "ha": 903,
      "x_min": 65,
      "x_max": 838,
      "o": "m 451 -17 l 451 -17 q 245 45 332 -17 q 112 220 158 107 q 65 485 65 333 l 65 485 q 112 750 65 638 q 245 924 158 863 q 451 986 332 986 l 451 986 q 658 925 572 986 q 791 751 744 864 q 838 485 838 639 l 838 485 q 791 219 838 332 q 658 45 744 107 q 451 -17 572 -17 z m 451 150 l 451 150 q 585 236 540 150 q 629 485 629 322 l 629 485 q 585 734 629 649 q 451 819 540 819 l 451 819 q 319 734 364 819 q 275 485 275 649 l 275 485 q 319 236 275 322 q 451 150 364 150 z "
    },
    "1": {
      "ha": 903,
      "x_min": 85,
      "x_max": 826,
      "o": "m 603 0 l 401 0 l 401 536 l 413 738 l 251 606 l 85 474 l 85 704 l 457 969 l 603 969 l 603 0 z m 826 0 l 85 0 l 85 167 l 826 167 l 826 0 z "
    },
    "2": {
      "ha": 903,
      "x_min": 89,
      "x_max": 811,
      "o": "m 811 0 l 92 0 l 92 140 q 387 369 275 271 q 549 544 499 468 q 600 683 600 619 l 600 683 q 560 782 600 746 q 457 818 521 818 l 457 818 q 336 771 383 818 q 289 642 289 724 l 289 642 l 89 642 q 140 826 92 749 q 271 945 188 904 q 461 986 354 986 l 461 986 q 642 949 564 986 q 765 846 721 913 q 808 689 808 779 l 808 689 q 767 514 808 597 q 635 346 726 431 q 394 167 544 261 l 394 167 l 811 167 l 811 0 z "
    },
    "3": {
      "ha": 903,
      "x_min": 85,
      "x_max": 822,
      "o": "m 457 -17 l 457 -17 q 269 24 354 -17 q 134 144 183 65 q 89 333 85 222 l 89 333 l 289 333 q 308 240 288 281 q 367 176 329 199 q 457 154 406 154 l 457 154 q 571 190 528 154 q 614 283 614 226 l 614 283 q 590 358 614 329 q 523 401 565 388 q 426 415 481 415 l 426 415 l 376 415 l 376 581 l 429 581 q 508 597 475 581 q 558 643 540 614 q 576 707 576 672 l 576 707 q 542 790 576 761 q 451 818 507 818 l 451 818 q 340 776 382 818 q 297 663 297 735 l 297 663 l 97 663 q 147 836 99 764 q 275 947 194 908 q 454 986 356 986 l 454 986 q 629 954 556 986 q 743 865 703 922 q 783 728 783 807 l 783 728 q 763 633 783 676 q 709 559 743 590 q 629 511 675 528 l 629 511 q 731 466 688 499 q 799 385 775 433 q 822 279 822 336 l 822 279 q 778 126 822 193 q 651 21 733 58 q 457 -17 569 -17 z "
    },
    "4": {
      "ha": 903,
      "x_min": 42,
      "x_max": 864,
      "o": "m 707 0 l 500 0 l 500 218 l 42 218 l 42 357 l 429 969 l 707 969 l 707 385 l 864 385 l 864 218 l 707 218 l 707 0 z m 401 633 l 247 385 l 500 385 l 500 607 l 504 814 l 401 633 z "
    },
    "5": {
      "ha": 903,
      "x_min": 99,
      "x_max": 822,
      "o": "m 443 -15 l 443 -15 q 266 21 342 -15 q 147 123 190 57 q 99 278 103 189 l 99 278 l 299 278 q 344 185 303 219 q 444 151 385 151 l 444 151 q 535 174 497 151 q 594 240 574 197 q 615 340 615 283 l 615 340 q 573 461 615 414 q 463 508 531 508 l 463 508 q 363 481 408 508 q 294 418 318 454 l 294 418 l 110 418 l 193 969 l 767 969 l 767 803 l 358 803 l 325 597 q 413 655 363 633 q 518 676 463 676 l 518 676 q 674 634 606 676 q 783 518 743 592 q 822 344 822 444 l 822 344 q 775 153 822 233 q 642 28 728 72 q 443 -15 557 -15 z "
    },
    "6": {
      "ha": 903,
      "x_min": 82,
      "x_max": 825,
      "o": "m 508 635 l 508 635 q 664 598 592 635 q 781 491 736 561 q 825 321 825 421 l 825 321 q 779 147 825 224 q 652 27 733 71 q 464 -17 571 -17 l 464 -17 q 258 40 343 -17 q 127 201 172 97 q 82 449 82 306 l 82 449 q 132 724 82 603 q 275 916 182 846 q 496 986 368 986 l 496 986 q 641 958 581 986 q 740 888 701 931 q 797 799 778 844 q 817 715 815 753 l 817 715 l 611 715 q 571 792 604 764 q 489 819 538 819 l 489 819 q 395 785 435 819 q 331 690 356 751 q 297 550 306 629 l 297 550 q 390 613 340 592 q 508 635 439 635 z m 464 150 l 464 150 q 544 172 510 150 q 597 231 578 193 q 617 317 617 268 l 617 317 q 598 399 617 365 q 544 451 579 433 q 464 469 510 469 l 464 469 q 371 448 413 469 q 294 383 329 426 l 294 383 q 347 213 304 275 q 464 150 389 150 z "
    },
    "7": {
      "ha": 903,
      "x_min": 86,
      "x_max": 838,
      "o": "m 447 0 l 243 0 q 289 226 257 114 q 367 446 321 339 q 473 644 414 553 q 600 804 532 736 l 600 804 l 86 804 l 86 969 l 838 969 l 838 832 q 691 664 757 757 q 576 465 625 571 q 494 241 526 358 q 447 0 463 124 l 447 0 z m 86 611 l 86 969 l 276 969 l 276 611 l 86 611 z "
    },
    "8": {
      "ha": 903,
      "x_min": 92,
      "x_max": 810,
      "o": "m 450 -17 l 450 -17 q 263 21 344 -17 q 137 122 182 58 q 92 269 92 186 l 92 269 q 115 378 92 331 q 178 463 138 426 q 274 519 218 499 l 274 519 l 628 519 q 724 461 683 497 q 788 378 765 425 q 810 274 810 332 l 810 274 q 765 122 810 188 q 638 20 719 57 q 450 -17 557 -17 z m 450 150 l 450 150 q 527 167 493 150 q 581 213 561 183 q 601 281 601 243 l 601 281 q 583 346 601 317 q 530 394 564 375 q 450 415 496 414 l 450 415 q 372 394 406 414 q 319 346 339 375 q 300 279 300 317 l 300 279 q 321 213 300 242 q 376 167 342 183 q 450 150 410 150 z m 450 569 l 450 569 q 523 588 492 572 q 572 632 554 604 q 590 697 590 660 l 590 697 q 550 789 590 754 q 450 824 510 824 l 450 824 q 352 789 392 824 q 313 697 313 754 l 313 697 q 330 632 313 660 q 378 588 347 604 q 450 569 410 572 z m 619 482 l 283 482 q 191 534 231 503 q 130 610 151 565 q 108 711 108 654 l 108 711 q 151 854 108 792 q 272 951 194 917 q 450 986 349 986 l 450 986 q 629 951 553 986 q 749 854 706 917 q 793 708 793 792 l 793 708 q 772 609 793 653 q 711 534 750 565 q 619 482 672 503 l 619 482 z "
    },
    "9": {
      "ha": 903,
      "x_min": 78,
      "x_max": 821,
      "o": "m 394 335 l 394 335 q 239 372 311 335 q 122 478 167 408 q 78 649 78 549 l 78 649 q 124 822 78 746 q 251 942 169 899 q 439 986 332 986 l 439 986 q 645 929 560 986 q 776 768 731 872 q 821 521 821 664 l 821 521 q 771 245 821 367 q 628 53 721 124 q 407 -17 535 -17 l 407 -17 q 262 11 322 -17 q 163 82 201 39 q 106 171 125 125 q 86 254 88 217 l 86 254 l 292 254 q 332 178 299 206 q 414 150 365 150 l 414 150 q 508 184 468 150 q 572 279 547 218 q 606 419 597 340 l 606 419 q 513 356 563 378 q 394 335 464 335 z m 439 819 l 439 819 q 359 798 393 819 q 306 739 325 776 q 286 653 286 701 l 286 653 q 305 570 286 604 q 358 518 324 536 q 439 500 393 500 l 439 500 q 532 522 490 500 q 608 586 574 543 l 608 586 q 556 757 599 694 q 439 819 514 819 z "
    },
    "A": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z "
    },
    "Á": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 493 1054 l 328 1054 l 447 1292 l 649 1292 l 493 1054 z "
    },
    "Ă": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 440 1051 l 440 1051 q 309 1081 365 1051 q 222 1164 253 1110 q 192 1292 192 1218 l 192 1292 l 333 1292 q 363 1201 333 1232 q 440 1169 393 1169 l 440 1169 q 519 1201 489 1169 q 549 1292 549 1232 l 549 1292 l 692 1292 q 660 1164 692 1218 q 573 1081 629 1110 q 440 1051 517 1051 z "
    },
    "Â": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 351 1054 l 172 1054 l 339 1292 l 547 1292 l 714 1054 l 536 1054 l 443 1194 l 351 1054 z "
    },
    "Ä": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 700 1082 l 494 1082 l 494 1268 l 700 1268 l 700 1082 z m 390 1082 l 186 1082 l 186 1268 l 390 1268 l 390 1082 z "
    },
    "À": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 443 1293 l 563 1056 l 396 1056 l 240 1293 l 443 1293 z "
    },
    "Ā": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 688 1085 l 199 1085 l 199 1226 l 688 1226 l 688 1085 z "
    },
    "Ą": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 810 -183 l 810 -300 q 788 -303 800 -301 q 756 -304 776 -304 l 756 -304 q 622 -265 668 -304 q 575 -157 575 -225 l 575 -157 q 602 -62 575 -104 q 666 8 629 -21 q 733 53 703 38 l 733 53 l 810 0 q 742 -55 768 -18 q 717 -126 717 -92 l 717 -126 q 740 -172 717 -157 q 785 -186 764 -186 l 785 -186 q 796 -185 790 -186 q 810 -183 801 -185 l 810 -183 z "
    },
    "Å": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 442 1021 l 442 1021 q 316 1065 364 1021 q 268 1183 268 1110 l 268 1183 q 317 1300 268 1256 q 442 1344 365 1344 l 442 1344 q 569 1300 521 1344 q 618 1183 618 1256 l 618 1183 q 569 1065 618 1110 q 442 1021 521 1021 z m 442 1115 l 442 1115 q 493 1134 474 1115 q 513 1183 513 1153 l 513 1183 q 493 1233 513 1214 q 443 1251 474 1251 l 443 1251 q 393 1233 413 1251 q 374 1185 374 1214 l 374 1185 q 393 1134 374 1153 q 442 1115 413 1115 z "
    },
    "Ǻ": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 442 1021 l 442 1021 q 316 1065 364 1021 q 268 1183 268 1110 l 268 1183 q 317 1300 268 1256 q 442 1344 365 1344 l 442 1344 q 569 1300 521 1344 q 618 1183 618 1256 l 618 1183 q 569 1065 618 1110 q 442 1021 521 1021 z m 442 1115 l 442 1115 q 493 1134 474 1115 q 513 1183 513 1153 l 513 1183 q 493 1233 513 1214 q 443 1251 474 1251 l 443 1251 q 393 1233 413 1251 q 374 1185 374 1214 l 374 1185 q 393 1134 374 1153 q 442 1115 413 1115 z m 490 1304 l 325 1304 l 444 1542 l 646 1542 l 490 1304 z "
    },
    "Ã": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 743 206 l 183 206 l 183 372 l 743 372 l 743 206 z m 39 0 l 301 969 l 600 969 l 864 0 l 658 0 l 508 581 l 450 824 l 392 581 l 239 0 l 39 0 z m 299 1056 l 299 1056 l 158 1078 q 208 1222 167 1167 q 324 1278 249 1278 l 324 1278 q 390 1269 361 1278 q 441 1247 418 1260 q 484 1226 464 1235 q 522 1217 504 1217 l 522 1217 q 567 1238 553 1217 q 586 1288 582 1258 l 586 1288 l 728 1265 q 674 1122 717 1178 q 558 1065 632 1065 l 558 1065 q 481 1081 515 1065 q 416 1113 446 1097 q 358 1129 386 1129 l 358 1129 q 316 1107 331 1129 q 299 1056 301 1085 z "
    },
    "Æ": {
      "ha": 903,
      "x_min": 10,
      "x_max": 883,
      "o": "m 185 0 l 10 0 l 379 969 l 542 969 l 185 0 z m 525 194 l 210 194 l 210 361 l 525 361 l 525 194 z m 883 0 l 475 0 l 475 969 l 878 969 l 878 803 l 643 803 l 643 585 l 821 585 l 821 418 l 643 418 l 643 167 l 883 167 l 883 0 z "
    },
    "Ǽ": {
      "ha": 903,
      "x_min": 10,
      "x_max": 883,
      "o": "m 185 0 l 10 0 l 379 969 l 542 969 l 185 0 z m 525 194 l 210 194 l 210 361 l 525 361 l 525 194 z m 883 0 l 475 0 l 475 969 l 878 969 l 878 803 l 643 803 l 643 585 l 821 585 l 821 418 l 643 418 l 643 167 l 883 167 l 883 0 z m 592 1054 l 426 1054 l 546 1292 l 747 1292 l 592 1054 z "
    },
    "B": {
      "ha": 903,
      "x_min": 99,
      "x_max": 842,
      "o": "m 671 507 l 671 507 q 763 458 725 489 q 822 383 801 428 q 842 279 842 338 l 842 279 q 799 131 842 194 q 677 34 756 68 q 492 0 599 0 l 492 0 l 99 0 l 99 969 l 478 969 q 730 901 640 969 q 819 721 819 832 l 819 721 q 801 628 819 669 q 750 557 783 588 q 671 507 717 526 z m 467 803 l 467 803 l 300 803 l 300 574 l 467 574 q 572 606 532 574 q 611 690 611 639 l 611 690 q 571 772 611 742 q 467 803 531 803 z m 300 407 l 300 167 l 486 167 q 593 199 553 167 q 633 286 633 232 l 633 286 q 593 375 633 343 q 481 407 553 407 l 481 407 l 300 407 z "
    },
    "C": {
      "ha": 903,
      "x_min": 42,
      "x_max": 868,
      "o": "m 464 -17 l 464 -17 q 239 46 333 -17 q 93 222 144 108 q 42 485 42 335 l 42 485 q 94 750 42 638 q 244 924 147 863 q 471 986 340 986 l 471 986 q 660 946 574 986 q 801 830 746 906 q 863 647 856 754 l 863 647 l 642 647 q 589 772 633 725 q 471 819 544 819 l 471 819 q 357 781 407 819 q 278 667 307 742 q 250 485 250 592 l 250 485 q 278 303 250 378 q 354 189 306 228 q 464 150 403 150 l 464 150 q 554 172 515 150 q 617 233 593 193 q 647 329 642 274 l 647 329 l 868 329 q 806 146 857 224 q 668 26 754 68 q 464 -17 582 -17 z "
    },
    "Ć": {
      "ha": 903,
      "x_min": 42,
      "x_max": 868,
      "o": "m 464 -17 l 464 -17 q 239 46 333 -17 q 93 222 144 108 q 42 485 42 335 l 42 485 q 94 750 42 638 q 244 924 147 863 q 471 986 340 986 l 471 986 q 660 946 574 986 q 801 830 746 906 q 863 647 856 754 l 863 647 l 642 647 q 589 772 633 725 q 471 819 544 819 l 471 819 q 357 781 407 819 q 278 667 307 742 q 250 485 250 592 l 250 485 q 278 303 250 378 q 354 189 306 228 q 464 150 403 150 l 464 150 q 554 172 515 150 q 617 233 593 193 q 647 329 642 274 l 647 329 l 868 329 q 806 146 857 224 q 668 26 754 68 q 464 -17 582 -17 z m 519 1057 l 354 1057 l 474 1294 l 675 1294 l 519 1057 z "
    },
    "Č": {
      "ha": 903,
      "x_min": 42,
      "x_max": 868,
      "o": "m 464 -17 l 464 -17 q 239 46 333 -17 q 93 222 144 108 q 42 485 42 335 l 42 485 q 94 750 42 638 q 244 924 147 863 q 471 986 340 986 l 471 986 q 660 946 574 986 q 801 830 746 906 q 863 647 856 754 l 863 647 l 642 647 q 589 772 633 725 q 471 819 544 819 l 471 819 q 357 781 407 819 q 278 667 307 742 q 250 485 250 592 l 250 485 q 278 303 250 378 q 354 189 306 228 q 464 150 403 150 l 464 150 q 554 172 515 150 q 617 233 593 193 q 647 329 642 274 l 647 329 l 868 329 q 806 146 857 224 q 668 26 754 68 q 464 -17 582 -17 z m 364 1057 l 197 1294 l 379 1294 l 468 1158 l 558 1294 l 739 1294 l 572 1057 l 364 1057 z "
    },
    "Ç": {
      "ha": 903,
      "x_min": 42,
      "x_max": 868,
      "o": "m 464 -17 l 464 -17 q 239 46 333 -17 q 93 222 144 108 q 42 485 42 335 l 42 485 q 94 750 42 638 q 244 924 147 863 q 471 986 340 986 l 471 986 q 660 946 574 986 q 801 830 746 906 q 863 647 856 754 l 863 647 l 642 647 q 589 772 633 725 q 471 819 544 819 l 471 819 q 357 781 407 819 q 278 667 307 742 q 250 485 250 592 l 250 485 q 278 303 250 378 q 354 189 306 228 q 464 150 403 150 l 464 150 q 554 172 515 150 q 617 233 593 193 q 647 329 642 274 l 647 329 l 868 329 q 806 146 857 224 q 668 26 754 68 q 464 -17 582 -17 z m 461 -308 l 461 -308 q 392 -301 428 -308 q 325 -285 357 -294 l 325 -285 l 325 -197 q 375 -210 347 -206 q 429 -215 403 -215 l 429 -215 q 481 -206 458 -215 q 503 -179 503 -197 l 503 -179 q 483 -153 503 -162 q 436 -144 463 -144 l 436 -144 q 410 -147 424 -144 q 386 -153 397 -149 l 386 -153 l 356 -103 l 440 38 l 536 6 l 488 -68 q 500 -66 494 -67 q 511 -65 506 -65 l 511 -65 q 575 -78 544 -65 q 625 -115 606 -90 q 644 -179 644 -140 l 644 -179 q 619 -252 644 -224 q 553 -294 594 -281 q 461 -308 511 -308 z "
    },
    "Ĉ": {
      "ha": 903,
      "x_min": 42,
      "x_max": 868,
      "o": "m 464 -17 l 464 -17 q 239 46 333 -17 q 93 222 144 108 q 42 485 42 335 l 42 485 q 94 750 42 638 q 244 924 147 863 q 471 986 340 986 l 471 986 q 660 946 574 986 q 801 830 746 906 q 863 647 856 754 l 863 647 l 642 647 q 589 772 633 725 q 471 819 544 819 l 471 819 q 357 781 407 819 q 278 667 307 742 q 250 485 250 592 l 250 485 q 278 303 250 378 q 354 189 306 228 q 464 150 403 150 l 464 150 q 554 172 515 150 q 617 233 593 193 q 647 329 642 274 l 647 329 l 868 329 q 806 146 857 224 q 668 26 754 68 q 464 -17 582 -17 z m 376 1057 l 197 1057 l 364 1294 l 572 1294 l 739 1057 l 561 1057 l 468 1197 l 376 1057 z "
    },
    "Ċ": {
      "ha": 903,
      "x_min": 42,
      "x_max": 868,
      "o": "m 464 -17 l 464 -17 q 239 46 333 -17 q 93 222 144 108 q 42 485 42 335 l 42 485 q 94 750 42 638 q 244 924 147 863 q 471 986 340 986 l 471 986 q 660 946 574 986 q 801 830 746 906 q 863 647 856 754 l 863 647 l 642 647 q 589 772 633 725 q 471 819 544 819 l 471 819 q 357 781 407 819 q 278 667 307 742 q 250 485 250 592 l 250 485 q 278 303 250 378 q 354 189 306 228 q 464 150 403 150 l 464 150 q 554 172 515 150 q 617 233 593 193 q 647 329 642 274 l 647 329 l 868 329 q 806 146 857 224 q 668 26 754 68 q 464 -17 582 -17 z m 571 1083 l 365 1083 l 365 1268 l 571 1268 l 571 1083 z "
    },
    "D": {
      "ha": 903,
      "x_min": 82,
      "x_max": 865,
      "o": "m 399 0 l 82 0 l 82 969 l 399 969 q 593 935 507 969 q 740 838 679 901 q 833 686 801 775 q 865 488 865 597 l 865 488 q 833 287 865 376 q 740 133 801 197 q 593 34 679 68 q 399 0 507 0 l 399 0 z m 283 803 l 283 167 l 399 167 q 538 206 481 167 q 626 319 596 246 q 656 488 656 392 l 656 488 q 626 655 656 585 q 538 764 596 725 q 399 803 481 803 l 399 803 l 283 803 z "
    },
    "Ð": {
      "ha": 903,
      "x_min": 1,
      "x_max": 865,
      "o": "m 529 411 l 1 411 l 1 565 l 529 565 l 529 411 z m 399 0 l 82 0 l 82 969 l 399 969 q 593 935 507 969 q 740 838 679 901 q 833 686 801 775 q 865 488 865 597 l 865 488 q 833 287 865 376 q 740 133 801 197 q 593 34 679 68 q 399 0 507 0 l 399 0 z m 283 803 l 283 167 l 399 167 q 538 206 481 167 q 626 318 596 246 q 656 488 656 390 l 656 488 q 626 655 656 585 q 538 764 596 725 q 399 803 481 803 l 399 803 l 283 803 z "
    },
    "Ď": {
      "ha": 903,
      "x_min": 82,
      "x_max": 865,
      "o": "m 399 0 l 82 0 l 82 969 l 399 969 q 593 935 507 969 q 740 838 679 901 q 833 686 801 775 q 865 488 865 597 l 865 488 q 833 287 865 376 q 740 133 801 197 q 593 34 679 68 q 399 0 507 0 l 399 0 z m 283 803 l 283 167 l 399 167 q 538 206 481 167 q 626 319 596 246 q 656 488 656 392 l 656 488 q 626 655 656 585 q 538 764 596 725 q 399 803 481 803 l 399 803 l 283 803 z m 307 1056 l 140 1293 l 322 1293 l 411 1157 l 501 1293 l 682 1293 l 515 1056 l 307 1056 z "
    },
    "Đ": {
      "ha": 903,
      "x_min": 1,
      "x_max": 865,
      "o": "m 529 411 l 1 411 l 1 565 l 529 565 l 529 411 z m 399 0 l 82 0 l 82 969 l 399 969 q 593 935 507 969 q 740 838 679 901 q 833 686 801 775 q 865 488 865 597 l 865 488 q 833 287 865 376 q 740 133 801 197 q 593 34 679 68 q 399 0 507 0 l 399 0 z m 283 803 l 283 167 l 399 167 q 538 206 481 167 q 626 318 596 246 q 656 488 656 390 l 656 488 q 626 655 656 585 q 538 764 596 725 q 399 803 481 803 l 399 803 l 283 803 z "
    },
    "E": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z "
    },
    "É": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z m 521 1056 l 356 1056 l 475 1293 l 676 1293 l 521 1056 z "
    },
    "Ĕ": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z m 468 1053 l 468 1053 q 337 1082 393 1053 q 250 1165 281 1111 q 219 1293 219 1219 l 219 1293 l 361 1293 q 391 1202 361 1233 q 468 1171 421 1171 l 468 1171 q 547 1202 517 1171 q 576 1293 576 1233 l 576 1293 l 719 1293 q 688 1165 719 1219 q 601 1082 657 1111 q 468 1053 544 1053 z "
    },
    "Ě": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z m 365 1056 l 199 1293 l 381 1293 l 469 1157 l 560 1293 l 740 1293 l 574 1056 l 365 1056 z "
    },
    "Ê": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z m 378 1056 l 199 1056 l 365 1293 l 574 1293 l 740 1056 l 563 1056 l 469 1196 l 378 1056 z "
    },
    "Ë": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z m 726 1083 l 521 1083 l 521 1269 l 726 1269 l 726 1083 z m 417 1083 l 213 1083 l 213 1269 l 417 1269 l 417 1083 z "
    },
    "Ė": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z m 572 1082 l 367 1082 l 367 1267 l 572 1267 l 572 1082 z "
    },
    "È": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z m 471 1294 l 590 1057 l 424 1057 l 268 1294 l 471 1294 z "
    },
    "Ē": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z m 714 1086 l 225 1086 l 225 1228 l 714 1228 l 714 1086 z "
    },
    "Ę": {
      "ha": 903,
      "x_min": 106,
      "x_max": 822,
      "o": "m 822 0 l 106 0 l 106 969 l 817 969 l 817 803 l 308 803 l 308 585 l 644 585 l 644 418 l 308 418 l 308 167 l 822 167 l 822 0 z m 822 -183 l 822 -300 q 801 -303 813 -301 q 768 -304 789 -304 l 768 -304 q 634 -265 681 -304 q 588 -157 588 -225 l 588 -157 q 615 -62 588 -104 q 678 8 642 -21 q 746 53 715 38 l 746 53 l 822 0 q 755 -55 781 -18 q 729 -126 729 -92 l 729 -126 q 753 -172 729 -157 q 797 -186 776 -186 l 797 -186 q 808 -185 803 -186 q 822 -183 814 -185 l 822 -183 z "
    },
    "F": {
      "ha": 903,
      "x_min": 94,
      "x_max": 817,
      "o": "m 296 0 l 94 0 l 94 969 l 817 969 l 817 803 l 296 803 l 296 585 l 643 585 l 643 418 l 296 418 l 296 0 z "
    },
    "G": {
      "ha": 903,
      "x_min": 33,
      "x_max": 853,
      "o": "m 458 -17 l 458 -17 q 234 47 329 -17 q 86 222 139 110 q 33 485 33 335 l 33 485 q 86 748 33 635 q 238 924 139 861 q 471 986 336 986 l 471 986 q 655 947 571 986 q 792 832 739 907 q 853 649 846 757 l 853 649 l 631 649 q 581 772 624 724 q 467 819 538 819 l 467 819 q 351 780 401 819 q 271 666 300 740 q 242 485 242 592 l 242 485 q 272 303 242 378 q 355 189 301 228 q 478 150 408 150 l 478 150 q 577 168 535 150 q 654 219 619 186 l 654 219 l 654 357 l 511 350 l 518 519 l 853 519 l 853 163 q 693 31 792 79 q 458 -17 594 -17 z "
    },
    "Ğ": {
      "ha": 903,
      "x_min": 33,
      "x_max": 853,
      "o": "m 458 -17 l 458 -17 q 234 47 329 -17 q 86 222 139 110 q 33 485 33 335 l 33 485 q 86 748 33 635 q 238 924 139 861 q 471 986 336 986 l 471 986 q 655 947 571 986 q 792 832 739 907 q 853 649 846 757 l 853 649 l 631 649 q 581 772 624 724 q 467 819 538 819 l 467 819 q 351 780 401 819 q 271 666 300 740 q 242 485 242 592 l 242 485 q 272 303 242 378 q 355 189 301 228 q 478 150 408 150 l 478 150 q 577 168 535 150 q 654 219 619 186 l 654 219 l 654 357 l 511 350 l 518 519 l 853 519 l 853 163 q 693 31 792 79 q 458 -17 594 -17 z m 472 1053 l 472 1053 q 341 1082 397 1053 q 254 1165 285 1111 q 224 1293 224 1219 l 224 1293 l 365 1293 q 395 1202 365 1233 q 472 1171 425 1171 l 472 1171 q 551 1202 521 1171 q 581 1293 581 1233 l 581 1293 l 724 1293 q 692 1165 724 1219 q 605 1082 661 1111 q 472 1053 549 1053 z "
    },
    "Ĝ": {
      "ha": 903,
      "x_min": 33,
      "x_max": 853,
      "o": "m 458 -17 l 458 -17 q 234 47 329 -17 q 86 222 139 110 q 33 485 33 335 l 33 485 q 86 748 33 635 q 238 924 139 861 q 471 986 336 986 l 471 986 q 655 947 571 986 q 792 832 739 907 q 853 649 846 757 l 853 649 l 631 649 q 581 772 624 724 q 467 819 538 819 l 467 819 q 351 780 401 819 q 271 666 300 740 q 242 485 242 592 l 242 485 q 272 303 242 378 q 355 189 301 228 q 478 150 408 150 l 478 150 q 577 168 535 150 q 654 219 619 186 l 654 219 l 654 357 l 511 350 l 518 519 l 853 519 l 853 163 q 693 31 792 79 q 458 -17 594 -17 z m 382 1056 l 203 1056 l 369 1293 l 578 1293 l 744 1056 l 567 1056 l 474 1196 l 382 1056 z "
    },
    "Ģ": {
      "ha": 903,
      "x_min": 33,
      "x_max": 853,
      "o": "m 458 -17 l 458 -17 q 234 47 329 -17 q 86 222 139 110 q 33 485 33 335 l 33 485 q 86 748 33 635 q 238 924 139 861 q 471 986 336 986 l 471 986 q 655 947 571 986 q 792 832 739 907 q 853 649 846 757 l 853 649 l 631 649 q 581 772 624 724 q 467 819 538 819 l 467 819 q 351 780 401 819 q 271 666 300 740 q 242 485 242 592 l 242 485 q 272 303 242 378 q 355 189 301 228 q 478 150 408 150 l 478 150 q 577 168 535 150 q 654 219 619 186 l 654 219 l 654 357 l 511 350 l 518 519 l 853 519 l 853 163 q 693 31 792 79 q 458 -17 594 -17 z m 508 -276 l 378 -276 l 378 -62 l 556 -62 l 508 -276 z "
    },
    "Ġ": {
      "ha": 903,
      "x_min": 33,
      "x_max": 853,
      "o": "m 458 -17 l 458 -17 q 234 47 329 -17 q 86 222 139 110 q 33 485 33 335 l 33 485 q 86 748 33 635 q 238 924 139 861 q 471 986 336 986 l 471 986 q 655 947 571 986 q 792 832 739 907 q 853 649 846 757 l 853 649 l 631 649 q 581 772 624 724 q 467 819 538 819 l 467 819 q 351 780 401 819 q 271 666 300 740 q 242 485 242 592 l 242 485 q 272 303 242 378 q 355 189 301 228 q 478 150 408 150 l 478 150 q 577 168 535 150 q 654 219 619 186 l 654 219 l 654 357 l 511 350 l 518 519 l 853 519 l 853 163 q 693 31 792 79 q 458 -17 594 -17 z m 576 1082 l 371 1082 l 371 1267 l 576 1267 l 576 1082 z "
    },
    "H": {
      "ha": 903,
      "x_min": 71,
      "x_max": 833,
      "o": "m 833 0 l 632 0 l 632 969 l 833 969 l 833 0 z m 272 0 l 71 0 l 71 969 l 272 969 l 272 0 z m 653 418 l 250 418 l 250 585 l 653 585 l 653 418 z "
    },
    "Ħ": {
      "ha": 903,
      "x_min": -85,
      "x_max": 988,
      "o": "m 833 0 l 632 0 l 632 969 l 833 969 l 833 0 z m 272 0 l 71 0 l 71 969 l 272 969 l 272 0 z m 653 418 l 250 418 l 250 585 l 653 585 l 653 418 z m 988 699 l -85 699 l -85 843 l 988 843 l 988 699 z "
    },
    "Ĥ": {
      "ha": 903,
      "x_min": 71,
      "x_max": 833,
      "o": "m 833 0 l 632 0 l 632 969 l 833 969 l 833 0 z m 272 0 l 71 0 l 71 969 l 272 969 l 272 0 z m 653 418 l 250 418 l 250 585 l 653 585 l 653 418 z m 360 1056 l 181 1056 l 347 1293 l 556 1293 l 722 1056 l 544 1056 l 451 1196 l 360 1056 z "
    },
    "I": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z "
    },
    "Ĳ": {
      "ha": 1806,
      "x_min": 121,
      "x_max": 1697,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 1374 0 l 1033 0 l 1033 167 l 1374 167 q 1465 203 1435 167 q 1496 303 1496 239 l 1496 303 l 1496 969 l 1697 969 l 1697 314 q 1677 178 1697 236 q 1617 80 1657 119 q 1517 20 1578 40 q 1374 0 1456 0 l 1374 0 z m 1156 803 l 1156 969 l 1681 969 l 1681 803 l 1156 803 z "
    },
    "Í": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 501 1056 l 336 1056 l 456 1293 l 657 1293 l 501 1056 z "
    },
    "Ĭ": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 450 1053 l 450 1053 q 319 1082 375 1053 q 232 1165 263 1111 q 201 1293 201 1219 l 201 1293 l 343 1293 q 373 1202 343 1233 q 450 1171 403 1171 l 450 1171 q 528 1202 499 1171 q 558 1293 558 1233 l 558 1293 l 701 1293 q 670 1165 701 1219 q 583 1082 639 1111 q 450 1053 526 1053 z "
    },
    "Î": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 360 1056 l 181 1056 l 347 1293 l 556 1293 l 722 1056 l 544 1056 l 451 1196 l 360 1056 z "
    },
    "Ï": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 708 1083 l 503 1083 l 503 1269 l 708 1269 l 708 1083 z m 399 1083 l 194 1083 l 194 1269 l 399 1269 l 399 1083 z "
    },
    "İ": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 554 1082 l 349 1082 l 349 1267 l 554 1267 l 554 1082 z "
    },
    "Ì": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 453 1294 l 572 1057 l 406 1057 l 250 1294 l 453 1294 z "
    },
    "Ī": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 696 1086 l 207 1086 l 207 1228 l 696 1228 l 696 1086 z "
    },
    "Į": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 539 -183 l 539 -300 q 517 -303 529 -301 q 485 -304 506 -304 l 485 -304 q 351 -265 397 -304 q 304 -157 304 -225 l 304 -157 q 331 -62 304 -104 q 395 8 358 -21 q 463 53 432 38 l 463 53 l 539 0 q 472 -55 497 -18 q 446 -126 446 -92 l 446 -126 q 469 -172 446 -157 q 514 -186 493 -186 l 514 -186 q 525 -185 519 -186 q 539 -183 531 -185 l 539 -183 z "
    },
    "Ĩ": {
      "ha": 903,
      "x_min": 121,
      "x_max": 781,
      "o": "m 551 0 l 350 0 l 350 969 l 551 969 l 551 0 z m 121 803 l 121 969 l 781 969 l 781 803 l 121 803 z m 121 0 l 121 167 l 781 167 l 781 0 l 121 0 z m 308 1057 l 308 1057 l 168 1079 q 217 1224 176 1168 q 333 1279 258 1279 l 333 1279 q 399 1270 371 1279 q 451 1249 428 1261 q 494 1227 474 1236 q 532 1218 514 1218 l 532 1218 q 577 1239 563 1218 q 596 1289 592 1260 l 596 1289 l 738 1267 q 684 1123 726 1179 q 568 1067 642 1067 l 568 1067 q 490 1083 525 1067 q 426 1115 456 1099 q 368 1131 396 1131 l 368 1131 q 326 1108 340 1131 q 308 1057 311 1086 z "
    },
    "J": {
      "ha": 903,
      "x_min": 131,
      "x_max": 794,
      "o": "m 471 0 l 131 0 l 131 167 l 471 167 q 563 203 532 167 q 593 303 593 239 l 593 303 l 593 969 l 794 969 l 794 314 q 774 178 794 236 q 715 80 754 119 q 614 20 675 40 q 471 0 553 0 l 471 0 z m 253 803 l 253 969 l 778 969 l 778 803 l 253 803 z "
    },
    "Ĵ": {
      "ha": 903,
      "x_min": 131,
      "x_max": 818,
      "o": "m 471 0 l 131 0 l 131 167 l 471 167 q 563 203 532 167 q 593 303 593 239 l 593 303 l 593 969 l 794 969 l 794 314 q 774 178 794 236 q 715 80 754 119 q 614 20 675 40 q 471 0 553 0 l 471 0 z m 253 803 l 253 969 l 778 969 l 778 803 l 253 803 z m 456 1056 l 276 1056 l 443 1293 l 651 1293 l 818 1056 l 640 1056 l 547 1196 l 456 1056 z "
    },
    "K": {
      "ha": 903,
      "x_min": 81,
      "x_max": 876,
      "o": "m 283 0 l 81 0 l 81 969 l 283 969 l 283 750 l 265 568 l 407 740 l 606 969 l 847 969 l 283 313 l 283 0 z m 876 0 l 640 0 l 331 531 l 501 628 l 876 0 z "
    },
    "Ķ": {
      "ha": 903,
      "x_min": 81,
      "x_max": 876,
      "o": "m 283 0 l 81 0 l 81 969 l 283 969 l 283 750 l 265 568 l 407 740 l 606 969 l 847 969 l 283 313 l 283 0 z m 876 0 l 640 0 l 331 531 l 501 628 l 876 0 z m 501 -276 l 371 -276 l 371 -62 l 549 -62 l 501 -276 z "
    },
    "L": {
      "ha": 903,
      "x_min": 128,
      "x_max": 813,
      "o": "m 813 0 l 128 0 l 128 969 l 329 969 l 329 167 l 813 167 l 813 0 z "
    },
    "Ĺ": {
      "ha": 903,
      "x_min": 124,
      "x_max": 813,
      "o": "m 813 0 l 128 0 l 128 969 l 329 969 l 329 167 l 813 167 l 813 0 z m 289 1056 l 124 1056 l 243 1293 l 444 1293 l 289 1056 z "
    },
    "Ľ": {
      "ha": 903,
      "x_min": 128,
      "x_max": 813,
      "o": "m 813 0 l 128 0 l 128 969 l 329 969 l 329 167 l 813 167 l 813 0 z m 561 665 l 429 665 l 446 971 l 614 971 l 561 665 z "
    },
    "Ļ": {
      "ha": 903,
      "x_min": 128,
      "x_max": 813,
      "o": "m 813 0 l 128 0 l 128 969 l 329 969 l 329 167 l 813 167 l 813 0 z m 522 -276 l 392 -276 l 392 -62 l 569 -62 l 522 -276 z "
    },
    "Ŀ": {
      "ha": 903,
      "x_min": 128,
      "x_max": 867,
      "o": "m 813 0 l 128 0 l 128 969 l 329 969 l 329 167 l 813 167 l 813 0 z m 868 428 l 643 428 l 643 646 l 868 646 l 868 428 z "
    },
    "Ł": {
      "ha": 903,
      "x_min": 19,
      "x_max": 813,
      "o": "m 575 526 l 19 331 l 19 496 l 575 693 l 575 526 z m 813 0 l 128 0 l 128 969 l 329 969 l 329 167 l 813 167 l 813 0 z "
    },
    "M": {
      "ha": 903,
      "x_min": 26,
      "x_max": 879,
      "o": "m 218 0 l 26 0 l 94 969 l 346 969 l 429 493 l 456 289 l 482 493 l 564 969 l 810 969 l 879 0 l 682 0 l 667 510 l 664 764 l 624 510 l 531 0 l 371 0 l 276 510 l 238 767 l 235 510 l 218 0 z "
    },
    "N": {
      "ha": 903,
      "x_min": 78,
      "x_max": 826,
      "o": "m 269 0 l 78 0 l 78 969 l 333 969 l 649 253 l 635 588 l 635 969 l 826 969 l 826 0 l 571 0 l 253 706 l 269 382 l 269 0 z "
    },
    "Ń": {
      "ha": 903,
      "x_min": 78,
      "x_max": 826,
      "o": "m 269 0 l 78 0 l 78 969 l 333 969 l 649 253 l 635 588 l 635 969 l 826 969 l 826 0 l 571 0 l 253 706 l 269 382 l 269 0 z m 508 1056 l 343 1056 l 463 1293 l 664 1293 l 508 1056 z "
    },
    "Ň": {
      "ha": 903,
      "x_min": 78,
      "x_max": 826,
      "o": "m 269 0 l 78 0 l 78 969 l 333 969 l 649 253 l 635 588 l 635 969 l 826 969 l 826 0 l 571 0 l 253 706 l 269 382 l 269 0 z m 354 1056 l 188 1293 l 369 1293 l 458 1157 l 549 1293 l 729 1293 l 563 1056 l 354 1056 z "
    },
    "Ņ": {
      "ha": 903,
      "x_min": 78,
      "x_max": 826,
      "o": "m 269 0 l 78 0 l 78 969 l 333 969 l 649 253 l 635 588 l 635 969 l 826 969 l 826 0 l 571 0 l 253 706 l 269 382 l 269 0 z m 499 -276 l 368 -276 l 368 -62 l 546 -62 l 499 -276 z "
    },
    "Ñ": {
      "ha": 903,
      "x_min": 78,
      "x_max": 826,
      "o": "m 269 0 l 78 0 l 78 969 l 333 969 l 649 253 l 635 588 l 635 969 l 826 969 l 826 0 l 571 0 l 253 706 l 269 382 l 269 0 z m 314 1057 l 314 1057 l 174 1079 q 223 1224 182 1168 q 339 1279 264 1279 l 339 1279 q 405 1270 376 1279 q 456 1249 433 1261 q 499 1227 479 1236 q 538 1218 519 1218 l 538 1218 q 583 1239 568 1218 q 601 1289 597 1260 l 601 1289 l 743 1267 q 690 1123 732 1179 q 574 1067 647 1067 l 574 1067 q 496 1083 531 1067 q 431 1115 461 1099 q 374 1131 401 1131 l 374 1131 q 331 1108 346 1131 q 314 1057 317 1086 z "
    },
    "Ŋ": {
      "ha": 903,
      "x_min": 78,
      "x_max": 826,
      "o": "m 826 0 l 596 -58 l 253 707 l 269 382 l 269 0 l 78 0 l 78 969 l 333 969 l 649 253 l 635 588 l 635 969 l 826 969 l 826 0 z m 575 -247 l 431 -247 l 431 -93 l 481 -93 q 576 -74 542 -93 q 611 4 611 -54 l 611 4 l 611 106 l 826 106 l 826 -8 q 796 -128 826 -74 q 709 -215 765 -182 q 575 -247 653 -247 l 575 -247 z "
    },
    "O": {
      "ha": 903,
      "x_min": 32,
      "x_max": 874,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 84 218 136 106 q 32 485 32 331 l 32 485 q 84 753 32 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 821 753 768 865 q 874 485 874 642 l 874 485 q 821 218 874 331 q 674 44 768 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 562 190 514 150 q 637 304 610 229 q 664 485 664 379 l 664 485 q 637 666 664 592 q 562 780 610 740 q 453 819 514 819 l 453 819 q 343 780 392 819 q 267 665 294 740 q 240 485 240 590 l 240 485 q 267 304 240 379 q 343 190 294 229 q 453 150 392 150 z "
    },
    "Ó": {
      "ha": 903,
      "x_min": 32,
      "x_max": 874,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 84 218 136 106 q 32 485 32 331 l 32 485 q 84 753 32 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 821 753 768 865 q 874 485 874 642 l 874 485 q 821 218 874 331 q 674 44 768 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 562 190 514 150 q 637 304 610 229 q 664 485 664 379 l 664 485 q 637 666 664 592 q 562 780 610 740 q 453 819 514 819 l 453 819 q 343 780 392 819 q 267 665 294 740 q 240 485 240 590 l 240 485 q 267 304 240 379 q 343 190 294 229 q 453 150 392 150 z m 504 1057 l 339 1057 l 458 1294 l 660 1294 l 504 1057 z "
    },
    "Ŏ": {
      "ha": 903,
      "x_min": 32,
      "x_max": 874,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 84 218 136 106 q 32 485 32 331 l 32 485 q 84 753 32 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 821 753 768 865 q 874 485 874 642 l 874 485 q 821 218 874 331 q 674 44 768 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 562 190 514 150 q 637 304 610 229 q 664 485 664 379 l 664 485 q 637 666 664 592 q 562 780 610 740 q 453 819 514 819 l 453 819 q 343 780 392 819 q 267 665 294 740 q 240 485 240 590 l 240 485 q 267 304 240 379 q 343 190 294 229 q 453 150 392 150 z m 453 1054 l 453 1054 q 322 1083 378 1054 q 235 1167 265 1113 q 204 1294 204 1221 l 204 1294 l 346 1294 q 376 1203 346 1235 q 453 1172 406 1172 l 453 1172 q 531 1203 501 1172 q 561 1294 561 1235 l 561 1294 l 704 1294 q 673 1167 704 1221 q 585 1083 642 1113 q 453 1054 529 1054 z "
    },
    "Ô": {
      "ha": 903,
      "x_min": 32,
      "x_max": 874,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 84 218 136 106 q 32 485 32 331 l 32 485 q 84 753 32 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 821 753 768 865 q 874 485 874 642 l 874 485 q 821 218 874 331 q 674 44 768 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 562 190 514 150 q 637 304 610 229 q 664 485 664 379 l 664 485 q 637 666 664 592 q 562 780 610 740 q 453 819 514 819 l 453 819 q 343 780 392 819 q 267 665 294 740 q 240 485 240 590 l 240 485 q 267 304 240 379 q 343 190 294 229 q 453 150 392 150 z m 363 1057 l 183 1057 l 350 1294 l 558 1294 l 725 1057 l 547 1057 l 454 1197 l 363 1057 z "
    },
    "Ö": {
      "ha": 903,
      "x_min": 32,
      "x_max": 874,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 84 218 136 106 q 32 485 32 331 l 32 485 q 84 753 32 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 821 753 768 865 q 874 485 874 642 l 874 485 q 821 218 874 331 q 674 44 768 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 562 190 514 150 q 637 304 610 229 q 664 485 664 379 l 664 485 q 637 666 664 592 q 562 780 610 740 q 453 819 514 819 l 453 819 q 343 780 392 819 q 267 665 294 740 q 240 485 240 590 l 240 485 q 267 304 240 379 q 343 190 294 229 q 453 150 392 150 z m 711 1085 l 506 1085 l 506 1271 l 711 1271 l 711 1085 z m 401 1085 l 197 1085 l 197 1271 l 401 1271 l 401 1085 z "
    },
    "Ò": {
      "ha": 903,
      "x_min": 32,
      "x_max": 874,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 84 218 136 106 q 32 485 32 331 l 32 485 q 84 753 32 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 821 753 768 865 q 874 485 874 642 l 874 485 q 821 218 874 331 q 674 44 768 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 562 190 514 150 q 637 304 610 229 q 664 485 664 379 l 664 485 q 637 666 664 592 q 562 780 610 740 q 453 819 514 819 l 453 819 q 343 780 392 819 q 267 665 294 740 q 240 485 240 590 l 240 485 q 267 304 240 379 q 343 190 294 229 q 453 150 392 150 z m 456 1296 l 575 1058 l 408 1058 l 253 1296 l 456 1296 z "
    },
    "Ő": {
      "ha": 903,
      "x_min": 32,
      "x_max": 874,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 84 218 136 106 q 32 485 32 331 l 32 485 q 84 753 32 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 821 753 768 865 q 874 485 874 642 l 874 485 q 821 218 874 331 q 674 44 768 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 562 190 514 150 q 637 304 610 229 q 664 485 664 379 l 664 485 q 637 666 664 592 q 562 780 610 740 q 453 819 514 819 l 453 819 q 343 780 392 819 q 267 665 294 740 q 240 485 240 590 l 240 485 q 267 304 240 379 q 343 190 294 229 q 453 150 392 150 z m 413 1057 l 263 1057 l 364 1294 l 546 1294 l 413 1057 z m 651 1057 l 503 1057 l 611 1294 l 794 1294 l 651 1057 z "
    },
    "Ō": {
      "ha": 903,
      "x_min": 32,
      "x_max": 874,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 84 218 136 106 q 32 485 32 331 l 32 485 q 84 753 32 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 821 753 768 865 q 874 485 874 642 l 874 485 q 821 218 874 331 q 674 44 768 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 562 190 514 150 q 637 304 610 229 q 664 485 664 379 l 664 485 q 637 666 664 592 q 562 780 610 740 q 453 819 514 819 l 453 819 q 343 780 392 819 q 267 665 294 740 q 240 485 240 590 l 240 485 q 267 304 240 379 q 343 190 294 229 q 453 150 392 150 z m 699 1088 l 210 1088 l 210 1229 l 699 1229 l 699 1088 z "
    },
    "Ø": {
      "ha": 903,
      "x_min": 29,
      "x_max": 875,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 83 218 136 106 q 29 485 29 331 l 29 485 q 83 753 29 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 822 753 769 865 q 875 485 875 642 l 875 485 q 822 218 875 331 q 674 44 769 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 563 190 514 150 q 639 306 611 231 q 667 485 667 381 l 667 485 q 639 665 667 590 q 563 780 611 740 q 453 819 514 819 l 453 819 q 343 779 392 819 q 266 665 294 739 q 238 485 238 590 l 238 485 q 266 306 238 381 q 343 190 294 231 q 453 150 392 150 z m 786 1000 l 275 -97 l 121 -28 l 632 1068 l 786 1000 z "
    },
    "Ǿ": {
      "ha": 903,
      "x_min": 29,
      "x_max": 875,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 83 218 136 106 q 29 485 29 331 l 29 485 q 83 753 29 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 822 753 769 865 q 875 485 875 642 l 875 485 q 822 218 875 331 q 674 44 769 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 563 190 514 150 q 639 306 611 231 q 667 485 667 381 l 667 485 q 639 665 667 590 q 563 780 611 740 q 453 819 514 819 l 453 819 q 343 779 392 819 q 266 665 294 739 q 238 485 238 590 l 238 485 q 266 306 238 381 q 343 190 294 231 q 453 150 392 150 z m 786 1000 l 275 -97 l 121 -28 l 632 1068 l 786 1000 z m 483 1054 l 318 1054 l 438 1292 l 639 1292 l 483 1054 z "
    },
    "Õ": {
      "ha": 903,
      "x_min": 32,
      "x_max": 874,
      "o": "m 453 -17 l 453 -17 q 231 44 326 -17 q 84 218 136 106 q 32 485 32 331 l 32 485 q 84 753 32 640 q 231 926 136 865 q 453 986 326 986 l 453 986 q 674 926 579 986 q 821 753 768 865 q 874 485 874 642 l 874 485 q 821 218 874 331 q 674 44 768 106 q 453 -17 579 -17 z m 453 150 l 453 150 q 562 190 514 150 q 637 304 610 229 q 664 485 664 379 l 664 485 q 637 666 664 592 q 562 780 610 740 q 453 819 514 819 l 453 819 q 343 780 392 819 q 267 665 294 740 q 240 485 240 590 l 240 485 q 267 304 240 379 q 343 190 294 229 q 453 150 392 150 z m 311 1058 l 311 1058 l 171 1081 q 220 1225 179 1169 q 336 1281 261 1281 l 336 1281 q 402 1272 374 1281 q 453 1250 431 1263 q 497 1228 476 1238 q 535 1219 517 1219 l 535 1219 q 580 1240 565 1219 q 599 1290 594 1261 l 599 1290 l 740 1268 q 687 1124 729 1181 q 571 1068 644 1068 l 571 1068 q 493 1084 528 1068 q 428 1116 458 1100 q 371 1132 399 1132 l 371 1132 q 328 1110 343 1132 q 311 1058 314 1088 z "
    },
    "Œ": {
      "ha": 903,
      "x_min": 24,
      "x_max": 878,
      "o": "m 792 418 l 592 418 l 592 167 l 878 167 l 878 0 l 426 0 q 263 34 336 0 q 135 133 189 68 q 53 287 82 197 q 24 488 24 376 l 24 488 q 53 686 24 597 q 135 838 82 775 q 263 935 189 901 q 426 969 336 969 l 426 969 l 872 969 l 872 804 l 592 804 l 592 585 l 792 585 l 792 418 z m 426 167 l 426 167 l 436 167 l 436 804 l 426 804 q 308 765 360 804 q 226 655 256 725 q 197 488 197 585 l 197 488 q 226 319 197 392 q 308 206 256 246 q 426 167 360 167 z "
    },
    "P": {
      "ha": 903,
      "x_min": 93,
      "x_max": 844,
      "o": "m 471 321 l 238 321 l 238 488 l 469 488 q 590 532 544 488 q 636 649 636 576 l 636 649 q 592 760 636 718 q 474 803 547 803 l 474 803 l 238 803 l 238 969 l 475 969 q 669 930 586 969 q 799 818 753 890 q 844 649 844 746 l 844 649 q 799 476 844 550 q 669 362 753 403 q 471 321 585 321 l 471 321 z m 294 0 l 93 0 l 93 969 l 294 969 l 294 0 z "
    },
    "Þ": {
      "ha": 903,
      "x_min": 104,
      "x_max": 856,
      "o": "m 469 178 l 249 178 l 249 344 l 469 344 q 601 390 553 344 q 649 504 649 435 l 649 504 q 608 619 649 576 q 496 661 568 661 l 496 661 l 249 661 l 249 828 l 496 828 q 688 788 607 828 q 812 676 768 749 q 856 504 856 603 l 856 504 q 808 334 856 407 q 675 219 761 261 q 469 178 589 178 l 469 178 z m 306 0 l 104 0 l 104 971 l 306 971 l 306 0 z "
    },
    "Q": {
      "ha": 903,
      "x_min": 29,
      "x_max": 900,
      "o": "m 863 -17 l 438 -17 q 226 44 318 -17 q 81 219 133 106 q 29 485 29 332 l 29 485 q 81 753 29 640 q 226 926 133 865 q 439 986 318 986 l 439 986 q 652 926 560 986 q 796 753 744 865 q 847 485 847 642 l 847 485 q 828 338 847 408 q 777 211 810 268 q 701 118 744 154 l 701 118 l 804 144 l 900 151 l 863 -17 z m 439 150 l 439 150 q 541 190 497 150 q 610 306 585 231 q 635 485 635 382 l 635 485 q 610 665 635 590 q 541 779 585 739 q 439 819 497 819 l 439 819 q 336 780 381 819 q 267 665 292 740 q 242 485 242 590 l 242 485 q 267 306 242 381 q 336 190 292 231 q 439 150 381 150 z "
    },
    "R": {
      "ha": 903,
      "x_min": 97,
      "x_max": 842,
      "o": "m 458 376 l 242 376 l 242 543 l 469 543 q 554 560 518 543 q 611 608 590 578 q 632 679 632 639 l 632 679 q 613 744 632 717 q 563 788 594 772 q 490 803 531 803 l 490 803 l 242 803 l 242 969 l 490 969 q 671 935 592 969 q 795 835 750 900 q 840 679 840 771 l 840 679 q 814 569 840 622 q 738 472 788 515 q 617 402 688 428 q 458 376 547 376 l 458 376 z m 299 0 l 97 0 l 97 969 l 299 969 l 299 0 z m 842 0 l 635 0 q 608 126 632 63 q 540 247 583 189 q 433 353 497 306 q 285 435 368 401 l 285 435 l 508 481 q 655 383 593 439 q 758 263 717 326 q 819 133 799 200 q 842 0 839 65 l 842 0 z "
    },
    "Ŕ": {
      "ha": 903,
      "x_min": 97,
      "x_max": 842,
      "o": "m 458 376 l 242 376 l 242 543 l 469 543 q 554 560 518 543 q 611 608 590 578 q 632 679 632 639 l 632 679 q 613 744 632 717 q 563 788 594 772 q 490 803 531 803 l 490 803 l 242 803 l 242 969 l 490 969 q 671 935 592 969 q 795 835 750 900 q 840 679 840 771 l 840 679 q 814 569 840 622 q 738 472 788 515 q 617 402 688 428 q 458 376 547 376 l 458 376 z m 299 0 l 97 0 l 97 969 l 299 969 l 299 0 z m 842 0 l 635 0 q 608 126 632 63 q 540 247 583 189 q 433 353 497 306 q 285 435 368 401 l 285 435 l 508 481 q 655 383 593 439 q 758 263 717 326 q 819 133 799 200 q 842 0 839 65 l 842 0 z m 469 1054 l 304 1054 l 424 1292 l 625 1292 l 469 1054 z "
    },
    "Ř": {
      "ha": 903,
      "x_min": 97,
      "x_max": 842,
      "o": "m 458 376 l 242 376 l 242 543 l 469 543 q 554 560 518 543 q 611 608 590 578 q 632 679 632 639 l 632 679 q 613 744 632 717 q 563 788 594 772 q 490 803 531 803 l 490 803 l 242 803 l 242 969 l 490 969 q 671 935 592 969 q 795 835 750 900 q 840 679 840 771 l 840 679 q 814 569 840 622 q 738 472 788 515 q 617 402 688 428 q 458 376 547 376 l 458 376 z m 299 0 l 97 0 l 97 969 l 299 969 l 299 0 z m 842 0 l 635 0 q 608 126 632 63 q 540 247 583 189 q 433 353 497 306 q 285 435 368 401 l 285 435 l 508 481 q 655 383 593 439 q 758 263 717 326 q 819 133 799 200 q 842 0 839 65 l 842 0 z m 315 1054 l 149 1292 l 331 1292 l 419 1156 l 510 1292 l 690 1292 l 524 1054 l 315 1054 z "
    },
    "Ŗ": {
      "ha": 903,
      "x_min": 97,
      "x_max": 842,
      "o": "m 458 376 l 242 376 l 242 543 l 469 543 q 554 560 518 543 q 611 608 590 578 q 632 679 632 639 l 632 679 q 613 744 632 717 q 563 788 594 772 q 490 803 531 803 l 490 803 l 242 803 l 242 969 l 490 969 q 671 935 592 969 q 795 835 750 900 q 840 679 840 771 l 840 679 q 814 569 840 622 q 738 472 788 515 q 617 402 688 428 q 458 376 547 376 l 458 376 z m 299 0 l 97 0 l 97 969 l 299 969 l 299 0 z m 842 0 l 635 0 q 608 126 632 63 q 540 247 583 189 q 433 353 497 306 q 285 435 368 401 l 285 435 l 508 481 q 655 383 593 439 q 758 263 717 326 q 819 133 799 200 q 842 0 839 65 l 842 0 z m 507 -276 l 376 -276 l 376 -62 l 554 -62 l 507 -276 z "
    },
    "S": {
      "ha": 903,
      "x_min": 82,
      "x_max": 825,
      "o": "m 460 -17 l 460 -17 q 258 28 343 -17 q 128 154 174 72 q 82 350 82 236 l 82 350 l 281 350 q 326 201 281 253 q 460 150 372 150 l 460 150 q 573 182 531 150 q 615 271 615 214 l 615 271 q 588 338 615 313 q 513 378 560 363 q 412 408 467 394 q 301 442 357 422 q 199 492 246 461 q 125 573 153 522 q 97 701 97 624 l 97 701 q 141 847 97 782 q 264 949 185 911 q 450 986 343 986 l 450 986 q 631 949 549 986 q 765 840 714 911 q 819 668 817 769 l 819 668 l 617 668 q 591 751 615 718 q 530 802 567 785 q 450 819 493 819 l 450 819 q 347 790 388 819 q 306 707 306 761 l 306 707 q 333 644 306 668 q 408 606 361 621 q 510 578 454 592 q 620 547 565 565 q 722 495 675 528 q 797 409 768 463 q 825 272 825 356 l 825 272 q 778 122 825 186 q 648 20 731 57 q 460 -17 565 -17 z "
    },
    "Ś": {
      "ha": 903,
      "x_min": 82,
      "x_max": 825,
      "o": "m 460 -17 l 460 -17 q 258 28 343 -17 q 128 154 174 72 q 82 350 82 236 l 82 350 l 281 350 q 326 201 281 253 q 460 150 372 150 l 460 150 q 573 182 531 150 q 615 271 615 214 l 615 271 q 588 338 615 313 q 513 378 560 363 q 412 408 467 394 q 301 442 357 422 q 199 492 246 461 q 125 573 153 522 q 97 701 97 624 l 97 701 q 141 847 97 782 q 264 949 185 911 q 450 986 343 986 l 450 986 q 631 949 549 986 q 765 840 714 911 q 819 668 817 769 l 819 668 l 617 668 q 591 751 615 718 q 530 802 567 785 q 450 819 493 819 l 450 819 q 347 790 388 819 q 306 707 306 761 l 306 707 q 333 644 306 668 q 408 606 361 621 q 510 578 454 592 q 620 547 565 565 q 722 495 675 528 q 797 409 768 463 q 825 272 825 356 l 825 272 q 778 122 825 186 q 648 20 731 57 q 460 -17 565 -17 z m 504 1056 l 339 1056 l 458 1293 l 660 1293 l 504 1056 z "
    },
    "Š": {
      "ha": 903,
      "x_min": 82,
      "x_max": 825,
      "o": "m 460 -17 l 460 -17 q 258 28 343 -17 q 128 154 174 72 q 82 350 82 236 l 82 350 l 281 350 q 326 201 281 253 q 460 150 372 150 l 460 150 q 573 182 531 150 q 615 271 615 214 l 615 271 q 588 338 615 313 q 513 378 560 363 q 412 408 467 394 q 301 442 357 422 q 199 492 246 461 q 125 573 153 522 q 97 701 97 624 l 97 701 q 141 847 97 782 q 264 949 185 911 q 450 986 343 986 l 450 986 q 631 949 549 986 q 765 840 714 911 q 819 668 817 769 l 819 668 l 617 668 q 591 751 615 718 q 530 802 567 785 q 450 819 493 819 l 450 819 q 347 790 388 819 q 306 707 306 761 l 306 707 q 333 644 306 668 q 408 606 361 621 q 510 578 454 592 q 620 547 565 565 q 722 495 675 528 q 797 409 768 463 q 825 272 825 356 l 825 272 q 778 122 825 186 q 648 20 731 57 q 460 -17 565 -17 z m 350 1056 l 183 1293 l 365 1293 l 454 1157 l 544 1293 l 725 1293 l 558 1056 l 350 1056 z "
    },
    "Ş": {
      "ha": 903,
      "x_min": 82,
      "x_max": 825,
      "o": "m 460 -17 l 460 -17 q 258 28 343 -17 q 128 154 174 72 q 82 350 82 236 l 82 350 l 281 350 q 326 201 281 253 q 460 150 372 150 l 460 150 q 573 182 531 150 q 615 271 615 214 l 615 271 q 588 338 615 313 q 513 378 560 363 q 412 408 467 394 q 301 442 357 422 q 199 492 246 461 q 125 573 153 522 q 97 701 97 624 l 97 701 q 141 847 97 782 q 264 949 185 911 q 450 986 343 986 l 450 986 q 631 949 549 986 q 765 840 714 911 q 819 668 817 769 l 819 668 l 617 668 q 591 751 615 718 q 530 802 567 785 q 450 819 493 819 l 450 819 q 347 790 388 819 q 306 707 306 761 l 306 707 q 333 644 306 668 q 408 606 361 621 q 510 578 454 592 q 620 547 565 565 q 722 495 675 528 q 797 409 768 463 q 825 272 825 356 l 825 272 q 778 122 825 186 q 648 20 731 57 q 460 -17 565 -17 z m 442 -308 l 442 -308 q 373 -301 408 -308 q 306 -285 338 -294 l 306 -285 l 306 -197 q 356 -210 328 -206 q 410 -215 383 -215 l 410 -215 q 461 -206 439 -215 q 483 -179 483 -197 l 483 -179 q 463 -153 483 -162 q 417 -144 443 -144 l 417 -144 q 391 -147 404 -144 q 367 -153 378 -149 l 367 -153 l 336 -103 l 421 38 l 517 6 l 468 -68 q 481 -66 475 -67 q 492 -65 486 -65 l 492 -65 q 556 -78 525 -65 q 606 -115 586 -90 q 625 -179 625 -140 l 625 -179 q 600 -252 625 -224 q 533 -294 575 -281 q 442 -308 492 -308 z "
    },
    "Ŝ": {
      "ha": 903,
      "x_min": 82,
      "x_max": 825,
      "o": "m 460 -17 l 460 -17 q 258 28 343 -17 q 128 154 174 72 q 82 350 82 236 l 82 350 l 281 350 q 326 201 281 253 q 460 150 372 150 l 460 150 q 573 182 531 150 q 615 271 615 214 l 615 271 q 588 338 615 313 q 513 378 560 363 q 412 408 467 394 q 301 442 357 422 q 199 492 246 461 q 125 573 153 522 q 97 701 97 624 l 97 701 q 141 847 97 782 q 264 949 185 911 q 450 986 343 986 l 450 986 q 631 949 549 986 q 765 840 714 911 q 819 668 817 769 l 819 668 l 617 668 q 591 751 615 718 q 530 802 567 785 q 450 819 493 819 l 450 819 q 347 790 388 819 q 306 707 306 761 l 306 707 q 333 644 306 668 q 408 606 361 621 q 510 578 454 592 q 620 547 565 565 q 722 495 675 528 q 797 409 768 463 q 825 272 825 356 l 825 272 q 778 122 825 186 q 648 20 731 57 q 460 -17 565 -17 z m 361 1056 l 182 1056 l 349 1293 l 557 1293 l 724 1056 l 546 1056 l 453 1196 l 361 1056 z "
    },
    "Ș": {
      "ha": 903,
      "x_min": 82,
      "x_max": 825,
      "o": "m 460 -17 l 460 -17 q 258 28 343 -17 q 128 154 174 72 q 82 350 82 236 l 82 350 l 281 350 q 326 201 281 253 q 460 150 372 150 l 460 150 q 573 182 531 150 q 615 271 615 214 l 615 271 q 588 338 615 313 q 513 378 560 363 q 412 408 467 394 q 301 442 357 422 q 199 492 246 461 q 125 573 153 522 q 97 701 97 624 l 97 701 q 141 847 97 782 q 264 949 185 911 q 450 986 343 986 l 450 986 q 631 949 549 986 q 765 840 714 911 q 819 668 817 769 l 819 668 l 617 668 q 591 751 615 718 q 530 802 567 785 q 450 819 493 819 l 450 819 q 347 790 388 819 q 306 707 306 761 l 306 707 q 333 644 306 668 q 408 606 361 621 q 510 578 454 592 q 620 547 565 565 q 722 495 675 528 q 797 409 768 463 q 825 272 825 356 l 825 272 q 778 122 825 186 q 648 20 731 57 q 460 -17 565 -17 z m 490 -276 l 360 -276 l 360 -62 l 538 -62 l 490 -276 z "
    },
    "ẞ": {
      "ha": 903,
      "x_min": 86,
      "x_max": 857,
      "o": "m 496 -17 l 496 -17 q 439 -12 469 -17 q 376 -1 408 -8 l 376 -1 l 376 167 q 430 155 400 160 q 485 150 460 150 l 485 150 q 569 166 532 150 q 626 213 606 182 q 647 285 647 243 l 647 285 q 607 375 647 343 q 494 407 567 407 l 494 407 l 376 407 l 376 574 l 464 574 q 570 606 531 574 q 610 690 610 639 l 610 690 q 569 773 610 742 q 464 804 529 804 l 464 804 l 289 804 l 289 0 l 86 0 l 86 969 l 464 969 q 648 936 569 969 q 771 844 726 903 q 815 710 815 785 l 815 710 q 790 608 815 654 q 717 531 765 563 q 597 485 668 499 l 597 485 l 615 524 q 744 481 690 513 q 828 395 799 449 q 857 261 857 342 l 857 261 q 762 57 857 131 q 496 -17 667 -17 z "
    },
    "T": {
      "ha": 903,
      "x_min": 57,
      "x_max": 844,
      "o": "m 551 0 l 350 0 l 350 803 l 57 803 l 57 969 l 844 969 l 844 803 l 551 803 l 551 0 z "
    },
    "Ŧ": {
      "ha": 903,
      "x_min": 57,
      "x_max": 844,
      "o": "m 551 0 l 350 0 l 350 803 l 57 803 l 57 969 l 844 969 l 844 803 l 551 803 l 551 0 z m 702 364 l 194 364 l 194 500 l 702 500 l 702 364 z "
    },
    "Ť": {
      "ha": 903,
      "x_min": 57,
      "x_max": 844,
      "o": "m 551 0 l 350 0 l 350 803 l 57 803 l 57 969 l 844 969 l 844 803 l 551 803 l 551 0 z m 347 1056 l 181 1293 l 363 1293 l 451 1157 l 542 1293 l 722 1293 l 556 1056 l 347 1056 z "
    },
    "Ţ": {
      "ha": 903,
      "x_min": 57,
      "x_max": 844,
      "o": "m 551 0 l 350 0 l 350 803 l 57 803 l 57 969 l 844 969 l 844 803 l 551 803 l 551 0 z m 439 -308 l 439 -308 q 370 -301 406 -308 q 303 -285 335 -294 l 303 -285 l 303 -197 q 353 -210 325 -206 q 407 -215 381 -215 l 407 -215 q 458 -206 436 -215 q 481 -179 481 -197 l 481 -179 q 460 -153 481 -162 q 414 -144 440 -144 l 414 -144 q 388 -147 401 -144 q 364 -153 375 -149 l 364 -153 l 333 -103 l 418 38 l 514 6 l 465 -68 q 478 -66 472 -67 q 489 -65 483 -65 l 489 -65 q 553 -78 522 -65 q 603 -115 583 -90 q 622 -179 622 -140 l 622 -179 q 597 -252 622 -224 q 531 -294 572 -281 q 439 -308 489 -308 z "
    },
    "Ț": {
      "ha": 903,
      "x_min": 57,
      "x_max": 844,
      "o": "m 551 0 l 350 0 l 350 803 l 57 803 l 57 969 l 844 969 l 844 803 l 551 803 l 551 0 z m 493 -276 l 363 -276 l 363 -62 l 540 -62 l 493 -276 z "
    },
    "U": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z "
    },
    "Ú": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 501 1056 l 336 1056 l 456 1293 l 657 1293 l 501 1056 z "
    },
    "Ŭ": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 449 1053 l 449 1053 q 317 1082 374 1053 q 231 1165 261 1111 q 200 1293 200 1219 l 200 1293 l 342 1293 q 372 1202 342 1233 q 449 1171 401 1171 l 449 1171 q 527 1202 497 1171 q 557 1293 557 1233 l 557 1293 l 700 1293 q 669 1165 700 1219 q 581 1082 638 1111 q 449 1053 525 1053 z "
    },
    "Û": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 358 1056 l 179 1056 l 346 1293 l 554 1293 l 721 1056 l 543 1056 l 450 1196 l 358 1056 z "
    },
    "Ü": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 707 1083 l 501 1083 l 501 1269 l 707 1269 l 707 1083 z m 397 1083 l 193 1083 l 193 1269 l 397 1269 l 397 1083 z "
    },
    "Ù": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 451 1294 l 571 1057 l 404 1057 l 249 1294 l 451 1294 z "
    },
    "Ű": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 408 1056 l 258 1056 l 360 1293 l 542 1293 l 408 1056 z m 647 1056 l 499 1056 l 607 1293 l 790 1293 l 647 1056 z "
    },
    "Ū": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 694 1086 l 206 1086 l 206 1228 l 694 1228 l 694 1086 z "
    },
    "Ų": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 569 -183 l 569 -300 q 548 -303 560 -301 q 515 -304 536 -304 l 515 -304 q 381 -265 428 -304 q 335 -157 335 -225 l 335 -157 q 362 -62 335 -104 q 426 8 389 -21 q 493 53 463 38 l 493 53 l 569 0 q 502 -55 528 -18 q 476 -126 476 -92 l 476 -126 q 500 -172 476 -157 q 544 -186 524 -186 l 544 -186 q 556 -185 550 -186 q 569 -183 561 -185 l 569 -183 z "
    },
    "Ů": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 449 1022 l 449 1022 q 323 1067 371 1022 q 275 1185 275 1111 l 275 1185 q 324 1301 275 1257 q 449 1346 372 1346 l 449 1346 q 576 1301 528 1346 q 625 1185 625 1257 l 625 1185 q 576 1067 625 1111 q 449 1022 528 1022 z m 449 1117 l 449 1117 q 500 1135 481 1117 q 519 1185 519 1154 l 519 1185 q 500 1234 519 1215 q 450 1253 481 1253 l 450 1253 q 400 1234 419 1253 q 381 1186 381 1215 l 381 1186 q 400 1135 381 1154 q 449 1117 419 1117 z "
    },
    "Ũ": {
      "ha": 903,
      "x_min": 60,
      "x_max": 843,
      "o": "m 451 -17 l 451 -17 q 242 28 329 -17 q 107 156 154 74 q 60 346 60 238 l 60 346 l 60 969 l 263 969 l 263 346 q 286 243 263 286 q 352 176 310 200 q 451 151 394 151 l 451 151 q 552 176 510 151 q 617 243 594 200 q 640 346 640 286 l 640 346 l 640 969 l 843 969 l 843 346 q 796 156 843 238 q 661 28 749 74 q 451 -17 574 -17 z m 307 1057 l 307 1057 l 167 1079 q 216 1224 175 1168 q 332 1279 257 1279 l 332 1279 q 398 1270 369 1279 q 449 1249 426 1261 q 492 1227 472 1236 q 531 1218 513 1218 l 531 1218 q 576 1239 561 1218 q 594 1289 590 1260 l 594 1289 l 736 1267 q 683 1123 725 1179 q 567 1067 640 1067 l 567 1067 q 489 1083 524 1067 q 424 1115 454 1099 q 367 1131 394 1131 l 367 1131 q 324 1108 339 1131 q 307 1057 310 1086 z "
    },
    "V": {
      "ha": 903,
      "x_min": 39,
      "x_max": 867,
      "o": "m 601 0 l 303 0 l 39 969 l 247 969 l 407 396 l 454 158 l 501 396 l 661 969 l 867 969 l 601 0 z "
    },
    "W": {
      "ha": 903,
      "x_min": 22,
      "x_max": 883,
      "o": "m 369 0 l 113 0 l 22 969 l 215 969 l 249 465 l 258 194 l 292 465 l 372 969 l 535 969 l 619 465 l 656 190 l 664 465 l 694 969 l 883 969 l 789 0 l 540 0 l 479 414 l 454 665 l 428 414 l 369 0 z "
    },
    "Ẃ": {
      "ha": 903,
      "x_min": 22,
      "x_max": 883,
      "o": "m 369 0 l 113 0 l 22 969 l 215 969 l 249 465 l 258 194 l 292 465 l 372 969 l 535 969 l 619 465 l 656 190 l 664 465 l 694 969 l 883 969 l 789 0 l 540 0 l 479 414 l 454 665 l 428 414 l 369 0 z m 514 1056 l 349 1056 l 468 1293 l 669 1293 l 514 1056 z "
    },
    "Ŵ": {
      "ha": 903,
      "x_min": 22,
      "x_max": 883,
      "o": "m 369 0 l 113 0 l 22 969 l 215 969 l 249 465 l 258 194 l 292 465 l 372 969 l 535 969 l 619 465 l 656 190 l 664 465 l 694 969 l 883 969 l 789 0 l 540 0 l 479 414 l 454 665 l 428 414 l 369 0 z m 372 1056 l 193 1056 l 360 1293 l 568 1293 l 735 1056 l 557 1056 l 464 1196 l 372 1056 z "
    },
    "Ẅ": {
      "ha": 903,
      "x_min": 22,
      "x_max": 883,
      "o": "m 369 0 l 113 0 l 22 969 l 215 969 l 249 465 l 258 194 l 292 465 l 372 969 l 535 969 l 619 465 l 656 190 l 664 465 l 694 969 l 883 969 l 789 0 l 540 0 l 479 414 l 454 665 l 428 414 l 369 0 z m 721 1083 l 515 1083 l 515 1269 l 721 1269 l 721 1083 z m 411 1083 l 207 1083 l 207 1269 l 411 1269 l 411 1083 z "
    },
    "Ẁ": {
      "ha": 903,
      "x_min": 22,
      "x_max": 883,
      "o": "m 369 0 l 113 0 l 22 969 l 215 969 l 249 465 l 258 194 l 292 465 l 372 969 l 535 969 l 619 465 l 656 190 l 664 465 l 694 969 l 883 969 l 789 0 l 540 0 l 479 414 l 454 665 l 428 414 l 369 0 z m 465 1294 l 585 1057 l 418 1057 l 263 1294 l 465 1294 z "
    },
    "X": {
      "ha": 903,
      "x_min": 40,
      "x_max": 869,
      "o": "m 265 0 l 40 0 l 315 494 l 47 969 l 276 969 l 393 761 l 454 631 l 517 761 l 633 969 l 858 969 l 590 499 l 869 0 l 644 0 l 518 228 l 454 360 l 390 229 l 265 0 z "
    },
    "Y": {
      "ha": 903,
      "x_min": 36,
      "x_max": 867,
      "o": "m 549 0 l 347 0 l 347 392 l 36 969 l 254 969 l 372 751 l 451 561 l 533 753 l 651 969 l 867 969 l 549 392 l 549 0 z "
    },
    "Ý": {
      "ha": 903,
      "x_min": 36,
      "x_max": 867,
      "o": "m 549 0 l 347 0 l 347 392 l 36 969 l 254 969 l 372 751 l 451 561 l 533 753 l 651 969 l 867 969 l 549 392 l 549 0 z m 503 1056 l 338 1056 l 457 1293 l 658 1293 l 503 1056 z "
    },
    "Ŷ": {
      "ha": 903,
      "x_min": 36,
      "x_max": 867,
      "o": "m 549 0 l 347 0 l 347 392 l 36 969 l 254 969 l 372 751 l 451 561 l 533 753 l 651 969 l 867 969 l 549 392 l 549 0 z m 360 1056 l 181 1056 l 347 1293 l 556 1293 l 722 1056 l 544 1056 l 451 1196 l 360 1056 z "
    },
    "Ÿ": {
      "ha": 903,
      "x_min": 36,
      "x_max": 867,
      "o": "m 549 0 l 347 0 l 347 392 l 36 969 l 254 969 l 372 751 l 451 561 l 533 753 l 651 969 l 867 969 l 549 392 l 549 0 z m 708 1083 l 503 1083 l 503 1269 l 708 1269 l 708 1083 z m 399 1083 l 194 1083 l 194 1269 l 399 1269 l 399 1083 z "
    },
    "Ỳ": {
      "ha": 903,
      "x_min": 36,
      "x_max": 867,
      "o": "m 549 0 l 347 0 l 347 392 l 36 969 l 254 969 l 372 751 l 451 561 l 533 753 l 651 969 l 867 969 l 549 392 l 549 0 z m 453 1294 l 572 1057 l 406 1057 l 250 1294 l 453 1294 z "
    },
    "Z": {
      "ha": 903,
      "x_min": 67,
      "x_max": 832,
      "o": "m 832 0 l 67 0 l 67 140 l 456 651 l 588 803 l 379 803 l 85 803 l 85 969 l 832 969 l 832 831 l 443 318 l 313 167 l 519 167 l 832 167 l 832 0 z "
    },
    "Ź": {
      "ha": 903,
      "x_min": 67,
      "x_max": 832,
      "o": "m 832 0 l 67 0 l 67 140 l 456 651 l 588 803 l 379 803 l 85 803 l 85 969 l 832 969 l 832 831 l 443 318 l 313 167 l 519 167 l 832 167 l 832 0 z m 501 1056 l 336 1056 l 456 1293 l 657 1293 l 501 1056 z "
    },
    "Ž": {
      "ha": 903,
      "x_min": 67,
      "x_max": 832,
      "o": "m 832 0 l 67 0 l 67 140 l 456 651 l 588 803 l 379 803 l 85 803 l 85 969 l 832 969 l 832 831 l 443 318 l 313 167 l 519 167 l 832 167 l 832 0 z m 346 1056 l 179 1293 l 361 1293 l 450 1157 l 540 1293 l 721 1293 l 554 1056 l 346 1056 z "
    },
    "Ż": {
      "ha": 903,
      "x_min": 67,
      "x_max": 832,
      "o": "m 832 0 l 67 0 l 67 140 l 456 651 l 588 803 l 379 803 l 85 803 l 85 969 l 832 969 l 832 831 l 443 318 l 313 167 l 519 167 l 832 167 l 832 0 z m 553 1082 l 347 1082 l 347 1267 l 553 1267 l 553 1082 z "
    },
    "a": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z "
    },
    "á": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 494 856 l 329 856 l 449 1093 l 650 1093 l 494 856 z "
    },
    "ă": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 442 853 l 442 853 q 310 882 367 853 q 224 965 254 911 q 193 1093 193 1019 l 193 1093 l 335 1093 q 365 1002 335 1033 q 442 971 394 971 l 442 971 q 520 1002 490 971 q 550 1093 550 1033 l 550 1093 l 693 1093 q 662 965 693 1019 q 574 882 631 911 q 442 853 518 853 z "
    },
    "â": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 353 856 l 174 856 l 340 1093 l 549 1093 l 715 856 l 538 856 l 444 996 l 353 856 z "
    },
    "ä": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 701 883 l 496 883 l 496 1069 l 701 1069 l 701 883 z m 392 883 l 188 883 l 188 1069 l 392 1069 l 392 883 z "
    },
    "à": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 444 1094 l 564 857 l 397 857 l 242 1094 l 444 1094 z "
    },
    "ā": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 689 886 l 200 886 l 200 1028 l 689 1028 l 689 886 z "
    },
    "ą": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 774 -183 l 774 -300 q 752 -303 764 -301 q 719 -304 740 -304 l 719 -304 q 585 -265 632 -304 q 539 -157 539 -225 l 539 -157 q 566 -62 539 -104 q 630 8 593 -21 q 697 53 667 38 l 697 53 l 774 0 q 706 -55 732 -18 q 681 -126 681 -92 l 681 -126 q 704 -172 681 -157 q 749 -186 728 -186 l 749 -186 q 760 -185 754 -186 q 774 -183 765 -185 l 774 -183 z "
    },
    "å": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 443 822 l 443 822 q 317 867 365 822 q 269 985 269 911 l 269 985 q 318 1101 269 1057 q 443 1146 367 1146 l 443 1146 q 571 1101 522 1146 q 619 985 619 1057 l 619 985 q 571 867 619 911 q 443 822 522 822 z m 443 917 l 443 917 q 494 935 475 917 q 514 985 514 954 l 514 985 q 494 1034 514 1015 q 444 1053 475 1053 l 444 1053 q 394 1034 414 1053 q 375 986 375 1015 l 375 986 q 394 935 375 954 q 443 917 414 917 z "
    },
    "ǻ": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 443 822 l 443 822 q 317 867 365 822 q 269 985 269 911 l 269 985 q 318 1101 269 1057 q 443 1146 367 1146 l 443 1146 q 571 1101 522 1146 q 619 985 619 1057 l 619 985 q 571 867 619 911 q 443 822 522 822 z m 443 917 l 443 917 q 494 935 475 917 q 514 985 514 954 l 514 985 q 494 1034 514 1015 q 444 1053 475 1053 l 444 1053 q 394 1034 414 1053 q 375 986 375 1015 l 375 986 q 394 935 375 954 q 443 917 414 917 z m 492 1106 l 326 1106 l 446 1343 l 647 1343 l 492 1106 z "
    },
    "ã": {
      "ha": 903,
      "x_min": 82,
      "x_max": 801,
      "o": "m 801 0 l 618 0 l 611 150 l 611 513 q 567 613 611 576 q 444 649 522 649 l 444 649 q 321 616 368 649 q 268 525 274 583 l 268 525 l 82 525 q 137 665 89 607 q 265 755 185 724 q 451 786 346 786 l 451 786 q 637 753 558 786 q 758 656 715 719 q 801 501 801 593 l 801 501 l 801 0 z m 361 -17 l 361 -17 q 227 13 285 -17 q 138 94 169 42 q 106 213 106 146 l 106 213 q 197 397 106 332 q 453 463 289 463 l 453 463 l 632 463 l 632 328 l 447 328 q 334 301 375 328 q 293 224 293 275 l 293 224 q 323 161 293 186 q 406 136 353 136 l 406 136 q 532 173 476 136 q 624 274 588 210 l 624 274 l 644 142 q 536 23 608 63 q 361 -17 464 -17 z m 300 857 l 300 857 l 160 879 q 209 1024 168 968 q 325 1079 250 1079 l 325 1079 q 391 1070 363 1079 q 442 1049 419 1061 q 485 1027 465 1036 q 524 1018 506 1018 l 524 1018 q 569 1039 554 1018 q 588 1089 583 1060 l 588 1089 l 729 1067 q 676 923 718 979 q 560 867 633 867 l 560 867 q 482 883 517 867 q 417 915 447 899 q 360 931 388 931 l 360 931 q 317 908 332 931 q 300 857 303 886 z "
    },
    "æ": {
      "ha": 903,
      "x_min": 3,
      "x_max": 892,
      "o": "m 511 165 l 331 165 l 331 539 q 313 608 331 588 q 267 629 296 629 l 267 629 q 231 617 247 629 q 206 581 215 604 q 193 522 196 557 l 193 522 l 3 522 q 42 663 8 604 q 133 754 75 722 q 267 786 190 786 l 267 786 q 445 713 379 786 q 511 504 511 640 l 511 504 l 511 165 z m 226 -17 l 226 -17 q 75 39 136 -17 q 14 207 14 94 l 14 207 q 51 338 14 281 q 151 426 88 394 q 289 458 214 458 l 289 458 l 347 458 l 344 328 l 299 328 q 226 299 257 328 q 194 218 194 271 l 194 218 q 211 160 194 182 q 257 138 228 138 l 257 138 q 317 182 296 138 q 340 322 339 226 l 340 322 l 444 211 q 374 41 433 99 q 226 -17 314 -17 z m 615 -17 l 615 -17 q 406 90 481 -17 q 331 385 331 197 l 331 385 q 410 679 331 572 q 625 786 489 786 l 625 786 q 831 676 771 786 q 879 371 892 565 l 879 371 l 488 371 l 490 501 l 699 501 q 676 605 696 569 q 617 640 656 640 l 617 640 q 538 573 567 640 q 510 378 510 506 l 510 378 q 523 254 510 308 q 560 169 536 200 q 615 139 583 139 l 615 139 q 681 173 657 139 q 713 261 706 207 l 713 261 l 875 261 q 799 58 868 133 q 615 -17 731 -17 z "
    },
    "ǽ": {
      "ha": 903,
      "x_min": 3,
      "x_max": 892,
      "o": "m 511 165 l 331 165 l 331 539 q 313 608 331 588 q 267 629 296 629 l 267 629 q 231 617 247 629 q 206 581 215 604 q 193 522 196 557 l 193 522 l 3 522 q 42 663 8 604 q 133 754 75 722 q 267 786 190 786 l 267 786 q 445 713 379 786 q 511 504 511 640 l 511 504 l 511 165 z m 226 -17 l 226 -17 q 75 39 136 -17 q 14 207 14 94 l 14 207 q 51 338 14 281 q 151 426 88 394 q 289 458 214 458 l 289 458 l 347 458 l 344 328 l 299 328 q 226 299 257 328 q 194 218 194 271 l 194 218 q 211 160 194 182 q 257 138 228 138 l 257 138 q 317 182 296 138 q 340 322 339 226 l 340 322 l 444 211 q 374 41 433 99 q 226 -17 314 -17 z m 615 -17 l 615 -17 q 406 90 481 -17 q 331 385 331 197 l 331 385 q 410 679 331 572 q 625 786 489 786 l 625 786 q 831 676 771 786 q 879 371 892 565 l 879 371 l 488 371 l 490 501 l 699 501 q 676 605 696 569 q 617 640 656 640 l 617 640 q 538 573 567 640 q 510 378 510 506 l 510 378 q 523 254 510 308 q 560 169 536 200 q 615 139 583 139 l 615 139 q 681 173 657 139 q 713 261 706 207 l 713 261 l 875 261 q 799 58 868 133 q 615 -17 731 -17 z m 513 854 l 347 854 l 467 1092 l 668 1092 l 513 854 z "
    },
    "b": {
      "ha": 903,
      "x_min": 88,
      "x_max": 854,
      "o": "m 510 786 l 510 786 q 688 733 611 786 q 810 590 765 681 q 854 385 854 499 l 854 385 q 810 181 854 272 q 688 37 765 90 q 510 -17 611 -17 l 510 -17 q 376 12 438 -17 q 275 88 315 40 l 275 88 l 271 0 l 88 0 l 88 1025 l 278 1025 l 278 686 q 378 760 318 733 q 510 786 439 786 z m 463 142 l 463 142 q 567 174 522 142 q 637 260 611 206 q 663 385 663 315 l 663 385 q 637 510 663 456 q 567 597 611 565 q 463 628 522 628 l 463 628 q 349 591 399 628 q 278 489 300 554 l 278 489 l 278 282 q 349 180 300 218 q 463 142 399 142 z "
    },
    "c": {
      "ha": 903,
      "x_min": 76,
      "x_max": 835,
      "o": "m 467 -17 l 467 -17 q 258 32 346 -17 q 123 171 169 81 q 76 385 76 261 l 76 385 q 126 601 76 511 q 263 739 175 692 q 471 786 351 786 l 471 786 q 651 750 572 786 q 778 648 731 714 q 831 492 825 582 l 831 492 l 639 492 q 584 594 628 560 q 471 629 540 629 l 471 629 q 365 601 411 629 q 294 518 319 572 q 268 385 268 464 l 268 385 q 324 203 268 268 q 467 138 381 138 l 467 138 q 547 154 511 138 q 608 202 583 171 q 643 274 633 233 l 643 274 l 835 274 q 775 120 825 185 q 646 19 725 56 q 467 -17 567 -17 z "
    },
    "ć": {
      "ha": 903,
      "x_min": 76,
      "x_max": 835,
      "o": "m 467 -17 l 467 -17 q 258 32 346 -17 q 123 171 169 81 q 76 385 76 261 l 76 385 q 126 601 76 511 q 263 739 175 692 q 471 786 351 786 l 471 786 q 651 750 572 786 q 778 648 731 714 q 831 492 825 582 l 831 492 l 639 492 q 584 594 628 560 q 471 629 540 629 l 471 629 q 365 601 411 629 q 294 518 319 572 q 268 385 268 464 l 268 385 q 324 203 268 268 q 467 138 381 138 l 467 138 q 547 154 511 138 q 608 202 583 171 q 643 274 633 233 l 643 274 l 835 274 q 775 120 825 185 q 646 19 725 56 q 467 -17 567 -17 z m 521 856 l 356 856 l 475 1093 l 676 1093 l 521 856 z "
    },
    "č": {
      "ha": 903,
      "x_min": 76,
      "x_max": 835,
      "o": "m 467 -17 l 467 -17 q 258 32 346 -17 q 123 171 169 81 q 76 385 76 261 l 76 385 q 126 601 76 511 q 263 739 175 692 q 471 786 351 786 l 471 786 q 651 750 572 786 q 778 648 731 714 q 831 492 825 582 l 831 492 l 639 492 q 584 594 628 560 q 471 629 540 629 l 471 629 q 365 601 411 629 q 294 518 319 572 q 268 385 268 464 l 268 385 q 324 203 268 268 q 467 138 381 138 l 467 138 q 547 154 511 138 q 608 202 583 171 q 643 274 633 233 l 643 274 l 835 274 q 775 120 825 185 q 646 19 725 56 q 467 -17 567 -17 z m 367 856 l 200 1093 l 382 1093 l 471 957 l 561 1093 l 742 1093 l 575 856 l 367 856 z "
    },
    "ç": {
      "ha": 903,
      "x_min": 76,
      "x_max": 835,
      "o": "m 467 -17 l 467 -17 q 258 32 346 -17 q 123 171 169 81 q 76 385 76 261 l 76 385 q 126 601 76 511 q 263 739 175 692 q 471 786 351 786 l 471 786 q 651 750 572 786 q 778 648 731 714 q 831 492 825 582 l 831 492 l 639 492 q 584 594 628 560 q 471 629 540 629 l 471 629 q 365 601 411 629 q 294 518 319 572 q 268 385 268 464 l 268 385 q 324 203 268 268 q 467 138 381 138 l 467 138 q 547 154 511 138 q 608 202 583 171 q 643 274 633 233 l 643 274 l 835 274 q 775 120 825 185 q 646 19 725 56 q 467 -17 567 -17 z m 457 -308 l 457 -308 q 388 -301 424 -308 q 321 -285 353 -294 l 321 -285 l 321 -197 q 371 -210 343 -206 q 425 -215 399 -215 l 425 -215 q 476 -206 454 -215 q 499 -179 499 -197 l 499 -179 q 478 -153 499 -162 q 432 -144 458 -144 l 432 -144 q 406 -147 419 -144 q 382 -153 393 -149 l 382 -153 l 351 -103 l 436 38 l 532 6 l 483 -68 q 496 -66 490 -67 q 507 -65 501 -65 l 507 -65 q 571 -78 540 -65 q 621 -115 601 -90 q 640 -179 640 -140 l 640 -179 q 615 -252 640 -224 q 549 -294 590 -281 q 457 -308 507 -308 z "
    },
    "ĉ": {
      "ha": 903,
      "x_min": 76,
      "x_max": 835,
      "o": "m 467 -17 l 467 -17 q 258 32 346 -17 q 123 171 169 81 q 76 385 76 261 l 76 385 q 126 601 76 511 q 263 739 175 692 q 471 786 351 786 l 471 786 q 651 750 572 786 q 778 648 731 714 q 831 492 825 582 l 831 492 l 639 492 q 584 594 628 560 q 471 629 540 629 l 471 629 q 365 601 411 629 q 294 518 319 572 q 268 385 268 464 l 268 385 q 324 203 268 268 q 467 138 381 138 l 467 138 q 547 154 511 138 q 608 202 583 171 q 643 274 633 233 l 643 274 l 835 274 q 775 120 825 185 q 646 19 725 56 q 467 -17 567 -17 z m 379 856 l 200 856 l 367 1093 l 575 1093 l 742 856 l 564 856 l 471 996 l 379 856 z "
    },
    "ċ": {
      "ha": 903,
      "x_min": 76,
      "x_max": 835,
      "o": "m 467 -17 l 467 -17 q 258 32 346 -17 q 123 171 169 81 q 76 385 76 261 l 76 385 q 126 601 76 511 q 263 739 175 692 q 471 786 351 786 l 471 786 q 651 750 572 786 q 778 648 731 714 q 831 492 825 582 l 831 492 l 639 492 q 584 594 628 560 q 471 629 540 629 l 471 629 q 365 601 411 629 q 294 518 319 572 q 268 385 268 464 l 268 385 q 324 203 268 268 q 467 138 381 138 l 467 138 q 547 154 511 138 q 608 202 583 171 q 643 274 633 233 l 643 274 l 835 274 q 775 120 825 185 q 646 19 725 56 q 467 -17 567 -17 z m 574 882 l 368 882 l 368 1067 l 574 1067 l 574 882 z "
    },
    "d": {
      "ha": 903,
      "x_min": 58,
      "x_max": 824,
      "o": "m 403 786 l 403 786 q 534 760 474 786 q 635 686 594 733 l 635 686 l 635 1025 l 824 1025 l 824 0 l 642 0 l 638 88 q 537 12 597 40 q 403 -17 476 -17 l 403 -17 q 225 37 303 -17 q 103 181 147 90 q 58 385 58 272 l 58 385 q 103 590 58 499 q 225 733 147 681 q 403 786 303 786 z m 450 142 l 450 142 q 563 180 514 142 q 635 282 613 218 l 635 282 l 635 489 q 563 591 613 554 q 450 628 514 628 l 450 628 q 347 597 392 628 q 276 510 301 565 q 250 385 250 456 l 250 385 q 276 260 250 315 q 347 174 301 206 q 450 142 392 142 z "
    },
    "ð": {
      "ha": 903,
      "x_min": 68,
      "x_max": 833,
      "o": "m 236 1025 l 236 1025 l 494 1025 q 625 920 564 981 q 733 786 686 860 q 807 627 781 713 q 833 446 833 542 l 833 446 q 788 210 833 315 q 658 44 743 106 q 451 -17 574 -17 l 451 -17 q 253 25 340 -17 q 117 147 167 67 q 68 343 68 228 l 68 343 q 112 519 68 446 q 229 631 156 592 q 392 669 303 669 l 392 669 q 521 656 464 669 q 622 609 578 643 q 703 513 667 575 l 703 513 l 686 311 q 631 439 667 393 q 548 503 596 485 q 442 522 500 522 l 442 522 q 351 499 390 522 q 288 433 311 476 q 264 325 264 390 l 264 325 q 288 219 264 261 q 351 156 311 176 q 442 136 392 136 l 442 136 q 551 179 507 136 q 619 293 596 222 q 642 446 642 364 l 642 446 q 610 616 642 535 q 521 769 578 697 q 391 906 464 842 q 236 1025 318 971 z m 793 865 l 321 718 l 285 836 l 754 983 l 793 865 z "
    },
    "ď": {
      "ha": 903,
      "x_min": 58,
      "x_max": 1081,
      "o": "m 403 786 l 403 786 q 534 760 474 786 q 635 686 594 733 l 635 686 l 635 1025 l 824 1025 l 824 0 l 642 0 l 638 88 q 537 12 597 40 q 403 -17 476 -17 l 403 -17 q 225 37 303 -17 q 103 181 147 90 q 58 385 58 272 l 58 385 q 103 590 58 499 q 225 733 147 681 q 403 786 303 786 z m 450 142 l 450 142 q 563 180 514 142 q 635 282 613 218 l 635 282 l 635 489 q 563 591 613 554 q 450 628 514 628 l 450 628 q 347 597 392 628 q 276 510 301 565 q 250 385 250 456 l 250 385 q 276 260 250 315 q 347 174 301 206 q 450 142 392 142 z m 1028 719 l 896 719 l 913 1025 l 1081 1025 l 1028 719 z "
    },
    "đ": {
      "ha": 903,
      "x_min": 54,
      "x_max": 921,
      "o": "m 629 650 l 629 650 l 629 1025 l 819 1025 l 819 0 l 636 0 l 629 85 q 538 11 593 39 q 413 -17 482 -17 l 413 -17 q 230 30 311 -17 q 101 163 149 76 q 54 369 54 250 l 54 369 q 98 560 54 474 q 219 697 142 646 q 399 749 297 749 l 399 749 q 531 722 471 749 q 629 650 590 696 z m 446 139 l 446 139 q 558 176 510 139 q 629 272 607 213 l 629 272 l 629 460 q 558 556 607 519 q 446 593 510 593 l 446 593 q 342 563 388 593 q 270 483 296 533 q 244 367 244 432 l 244 367 q 270 250 244 301 q 342 169 296 199 q 446 139 388 139 z m 921 814 l 399 814 l 399 950 l 921 950 l 921 814 z "
    },
    "e": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z "
    },
    "é": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z m 524 856 l 358 856 l 478 1093 l 679 1093 l 524 856 z "
    },
    "ĕ": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z m 471 853 l 471 853 q 340 882 396 853 q 253 965 283 911 q 222 1093 222 1019 l 222 1093 l 364 1093 q 394 1002 364 1033 q 471 971 424 971 l 471 971 q 549 1002 519 971 q 579 1093 579 1033 l 579 1093 l 722 1093 q 691 965 722 1019 q 603 882 660 911 q 471 853 547 853 z "
    },
    "ě": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z m 368 856 l 201 1093 l 383 1093 l 472 957 l 563 1093 l 743 1093 l 576 856 l 368 856 z "
    },
    "ê": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z m 381 856 l 201 856 l 368 1093 l 576 1093 l 743 856 l 565 856 l 472 996 l 381 856 z "
    },
    "ë": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z m 729 883 l 524 883 l 524 1069 l 729 1069 l 729 883 z m 419 883 l 215 883 l 215 1069 l 419 1069 l 419 883 z "
    },
    "ė": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z m 575 882 l 369 882 l 369 1067 l 575 1067 l 575 882 z "
    },
    "è": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z m 474 1094 l 593 857 l 426 857 l 271 1094 l 474 1094 z "
    },
    "ē": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z m 717 886 l 228 886 l 228 1028 l 717 1028 l 717 886 z "
    },
    "ę": {
      "ha": 903,
      "x_min": 64,
      "x_max": 849,
      "o": "m 460 -17 l 460 -17 q 248 32 336 -17 q 112 171 160 81 q 64 385 64 261 l 64 385 q 112 601 64 511 q 250 738 160 690 q 468 786 340 786 l 468 786 q 758 675 667 786 q 835 369 849 564 l 835 369 l 217 369 l 217 503 l 640 503 q 587 599 635 563 q 467 636 539 636 l 467 636 q 308 569 360 636 q 256 376 256 503 l 256 376 q 279 256 256 310 q 348 169 303 201 q 460 138 393 138 l 460 138 q 583 174 532 138 q 644 267 633 210 l 644 267 l 835 267 q 719 60 819 136 q 460 -17 619 -17 z m 574 -183 l 574 -300 q 552 -303 564 -301 q 519 -304 540 -304 l 519 -304 q 385 -265 432 -304 q 339 -157 339 -225 l 339 -157 q 366 -62 339 -104 q 430 8 393 -21 q 497 53 467 38 l 497 53 l 574 0 q 506 -55 532 -18 q 481 -126 481 -92 l 481 -126 q 504 -172 481 -157 q 549 -186 528 -186 l 549 -186 q 560 -185 554 -186 q 574 -183 565 -185 l 574 -183 z "
    },
    "f": {
      "ha": 903,
      "x_min": 100,
      "x_max": 825,
      "o": "m 519 0 l 328 0 l 328 849 q 360 986 328 928 q 452 1078 393 1044 q 589 1113 511 1113 l 589 1113 l 825 1113 l 825 958 l 660 958 q 590 950 622 958 q 538 919 557 942 q 519 850 519 896 l 519 850 l 519 0 z m 724 615 l 165 615 l 165 769 l 724 769 l 724 615 z m 808 0 l 100 0 l 100 154 l 808 154 l 808 0 z "
    },
    "g": {
      "ha": 903,
      "x_min": 72,
      "x_max": 822,
      "o": "m 635 679 l 635 679 l 639 769 l 822 769 l 822 96 q 728 -172 822 -79 q 435 -264 633 -264 l 435 -264 q 250 -237 328 -264 q 128 -155 172 -210 q 82 -17 85 -100 l 82 -17 l 283 -17 q 329 -86 290 -64 q 446 -108 368 -108 l 446 -108 q 585 -60 540 -108 q 631 76 631 -11 l 631 76 l 631 140 q 534 63 592 90 q 406 35 476 35 l 406 35 q 233 84 308 35 q 115 219 158 133 q 72 408 72 304 l 72 408 q 117 610 72 526 q 241 740 163 694 q 418 785 319 785 l 418 785 q 542 756 486 785 q 635 679 599 726 z m 453 182 l 453 182 q 563 222 515 182 q 631 326 611 261 l 631 326 l 631 494 q 563 599 611 560 q 453 638 515 638 l 453 638 q 354 608 397 638 q 287 527 311 578 q 263 411 263 476 l 263 411 q 287 295 263 347 q 354 213 311 243 q 453 182 397 182 z "
    },
    "ğ": {
      "ha": 903,
      "x_min": 72,
      "x_max": 822,
      "o": "m 635 679 l 635 679 l 639 769 l 822 769 l 822 96 q 728 -172 822 -79 q 435 -264 633 -264 l 435 -264 q 250 -237 328 -264 q 128 -155 172 -210 q 82 -17 85 -100 l 82 -17 l 283 -17 q 329 -86 290 -64 q 446 -108 368 -108 l 446 -108 q 585 -60 540 -108 q 631 76 631 -11 l 631 76 l 631 140 q 534 63 592 90 q 406 35 476 35 l 406 35 q 233 84 308 35 q 115 219 158 133 q 72 408 72 304 l 72 408 q 117 610 72 526 q 241 740 163 694 q 418 785 319 785 l 418 785 q 542 756 486 785 q 635 679 599 726 z m 453 182 l 453 182 q 563 222 515 182 q 631 326 611 261 l 631 326 l 631 494 q 563 599 611 560 q 453 638 515 638 l 453 638 q 354 608 397 638 q 287 527 311 578 q 263 411 263 476 l 263 411 q 287 295 263 347 q 354 213 311 243 q 453 182 397 182 z m 449 853 l 449 853 q 317 882 374 853 q 231 965 261 911 q 200 1093 200 1019 l 200 1093 l 342 1093 q 372 1002 342 1033 q 449 971 401 971 l 449 971 q 527 1002 497 971 q 557 1093 557 1033 l 557 1093 l 700 1093 q 669 965 700 1019 q 581 882 638 911 q 449 853 525 853 z "
    },
    "ĝ": {
      "ha": 903,
      "x_min": 72,
      "x_max": 822,
      "o": "m 635 679 l 635 679 l 639 769 l 822 769 l 822 96 q 728 -172 822 -79 q 435 -264 633 -264 l 435 -264 q 250 -237 328 -264 q 128 -155 172 -210 q 82 -17 85 -100 l 82 -17 l 283 -17 q 329 -86 290 -64 q 446 -108 368 -108 l 446 -108 q 585 -60 540 -108 q 631 76 631 -11 l 631 76 l 631 140 q 534 63 592 90 q 406 35 476 35 l 406 35 q 233 84 308 35 q 115 219 158 133 q 72 408 72 304 l 72 408 q 117 610 72 526 q 241 740 163 694 q 418 785 319 785 l 418 785 q 542 756 486 785 q 635 679 599 726 z m 453 182 l 453 182 q 563 222 515 182 q 631 326 611 261 l 631 326 l 631 494 q 563 599 611 560 q 453 638 515 638 l 453 638 q 354 608 397 638 q 287 527 311 578 q 263 411 263 476 l 263 411 q 287 295 263 347 q 354 213 311 243 q 453 182 397 182 z m 358 856 l 179 856 l 346 1093 l 554 1093 l 721 856 l 543 856 l 450 996 l 358 856 z "
    },
    "ģ": {
      "ha": 903,
      "x_min": 72,
      "x_max": 822,
      "o": "m 635 679 l 635 679 l 639 769 l 822 769 l 822 96 q 728 -172 822 -79 q 435 -264 633 -264 l 435 -264 q 250 -237 328 -264 q 128 -155 172 -210 q 82 -17 85 -100 l 82 -17 l 283 -17 q 329 -86 290 -64 q 446 -108 368 -108 l 446 -108 q 585 -60 540 -108 q 631 76 631 -11 l 631 76 l 631 140 q 534 63 592 90 q 406 35 476 35 l 406 35 q 233 84 308 35 q 115 219 158 133 q 72 408 72 304 l 72 408 q 117 610 72 526 q 241 740 163 694 q 418 785 319 785 l 418 785 q 542 756 486 785 q 635 679 599 726 z m 453 182 l 453 182 q 563 222 515 182 q 631 326 611 261 l 631 326 l 631 494 q 563 599 611 560 q 453 638 515 638 l 453 638 q 354 608 397 638 q 287 527 311 578 q 263 411 263 476 l 263 411 q 287 295 263 347 q 354 213 311 243 q 453 182 397 182 z m 450 1082 l 581 1082 l 581 868 l 403 868 l 450 1082 z "
    },
    "ġ": {
      "ha": 903,
      "x_min": 72,
      "x_max": 822,
      "o": "m 635 679 l 635 679 l 639 769 l 822 769 l 822 96 q 728 -172 822 -79 q 435 -264 633 -264 l 435 -264 q 250 -237 328 -264 q 128 -155 172 -210 q 82 -17 85 -100 l 82 -17 l 283 -17 q 329 -86 290 -64 q 446 -108 368 -108 l 446 -108 q 585 -60 540 -108 q 631 76 631 -11 l 631 76 l 631 140 q 534 63 592 90 q 406 35 476 35 l 406 35 q 233 84 308 35 q 115 219 158 133 q 72 408 72 304 l 72 408 q 117 610 72 526 q 241 740 163 694 q 418 785 319 785 l 418 785 q 542 756 486 785 q 635 679 599 726 z m 453 182 l 453 182 q 563 222 515 182 q 631 326 611 261 l 631 326 l 631 494 q 563 599 611 560 q 453 638 515 638 l 453 638 q 354 608 397 638 q 287 527 311 578 q 263 411 263 476 l 263 411 q 287 295 263 347 q 354 213 311 243 q 453 182 397 182 z m 553 882 l 347 882 l 347 1067 l 553 1067 l 553 882 z "
    },
    "h": {
      "ha": 903,
      "x_min": 96,
      "x_max": 813,
      "o": "m 813 0 l 621 0 l 621 476 q 585 585 621 544 q 492 626 550 626 l 492 626 q 372 580 425 626 q 269 428 318 533 l 269 428 l 242 601 q 369 742 292 699 q 529 786 446 786 l 529 786 q 680 749 617 786 q 778 644 743 711 q 813 488 813 578 l 813 488 l 813 0 z m 288 0 l 96 0 l 96 1026 l 288 1026 l 288 0 z "
    },
    "ħ": {
      "ha": 903,
      "x_min": -6,
      "x_max": 813,
      "o": "m 813 0 l 621 0 l 621 439 q 585 548 621 507 q 492 589 550 589 l 492 589 q 371 542 424 589 q 269 390 318 494 l 269 390 l 242 564 q 368 704 292 660 q 529 749 444 749 l 529 749 q 680 711 617 749 q 778 607 743 674 q 813 450 813 540 l 813 450 l 813 0 z m 288 0 l 96 0 l 96 1025 l 288 1025 l 288 0 z m 517 814 l -6 814 l -6 950 l 517 950 l 517 814 z "
    },
    "ĥ": {
      "ha": 903,
      "x_min": -83,
      "x_max": 813,
      "o": "m 813 0 l 621 0 l 621 476 q 585 585 621 544 q 492 626 550 626 l 492 626 q 372 580 425 626 q 269 428 318 533 l 269 428 l 242 601 q 369 742 292 699 q 529 786 446 786 l 529 786 q 680 749 617 786 q 778 644 743 711 q 813 488 813 578 l 813 488 l 813 0 z m 288 0 l 96 0 l 96 1026 l 288 1026 l 288 0 z m 96 1103 l -83 1103 l 83 1340 l 292 1340 l 458 1103 l 281 1103 l 188 1243 l 96 1103 z "
    },
    "i": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 556 882 l 350 882 l 350 1067 l 556 1067 l 556 882 z "
    },
    "ı": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z "
    },
    "í": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 503 856 l 338 856 l 457 1093 l 658 1093 l 503 856 z "
    },
    "ĭ": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 451 853 l 451 853 q 320 882 376 853 q 233 965 264 911 q 203 1093 203 1019 l 203 1093 l 344 1093 q 374 1002 344 1033 q 451 971 404 971 l 451 971 q 530 1002 500 971 q 560 1093 560 1033 l 560 1093 l 703 1093 q 672 965 703 1019 q 584 882 640 911 q 451 853 528 853 z "
    },
    "î": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 361 856 l 182 856 l 349 1093 l 557 1093 l 724 856 l 546 856 l 453 996 l 361 856 z "
    },
    "ï": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 710 883 l 504 883 l 504 1069 l 710 1069 l 710 883 z m 400 883 l 196 883 l 196 1069 l 400 1069 l 400 883 z "
    },
    "ì": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 454 1094 l 574 857 l 407 857 l 251 1094 l 454 1094 z "
    },
    "ĳ": {
      "ha": 1806,
      "x_min": 97,
      "x_max": 1576,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 556 882 l 350 882 l 350 1067 l 556 1067 l 556 882 z m 1379 769 l 1571 769 l 1571 17 q 1540 -121 1571 -61 q 1454 -214 1510 -181 q 1321 -247 1399 -247 l 1321 -247 l 1000 -247 l 1000 -93 l 1250 -93 q 1346 -72 1313 -93 q 1379 15 1379 -51 l 1379 15 l 1379 769 z m 1475 615 l 1217 615 l 1217 769 l 1475 769 l 1475 615 z m 1576 882 l 1371 882 l 1371 1067 l 1576 1067 l 1576 882 z "
    },
    "ī": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 697 886 l 208 886 l 208 1028 l 697 1028 l 697 886 z "
    },
    "į": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 556 882 l 350 882 l 350 1067 l 556 1067 l 556 882 z m 546 -183 l 546 -300 q 524 -303 536 -301 q 492 -304 513 -304 l 492 -304 q 358 -265 404 -304 q 311 -157 311 -225 l 311 -157 q 338 -62 311 -104 q 402 8 365 -21 q 469 53 439 38 l 469 53 l 546 0 q 478 -55 504 -18 q 453 -126 453 -92 l 453 -126 q 476 -172 453 -157 q 521 -186 500 -186 l 521 -186 q 532 -185 526 -186 q 546 -183 538 -185 l 546 -183 z "
    },
    "ĩ": {
      "ha": 903,
      "x_min": 97,
      "x_max": 806,
      "o": "m 550 0 l 358 0 l 358 769 l 550 769 l 550 0 z m 806 0 l 97 0 l 97 154 l 806 154 l 806 0 z m 454 615 l 196 615 l 196 769 l 454 769 l 454 615 z m 310 857 l 310 857 l 169 879 q 219 1024 178 968 q 335 1079 260 1079 l 335 1079 q 401 1070 372 1079 q 452 1049 429 1061 q 495 1027 475 1036 q 533 1018 515 1018 l 533 1018 q 578 1039 564 1018 q 597 1089 593 1060 l 597 1089 l 739 1067 q 685 923 728 979 q 569 867 643 867 l 569 867 q 492 883 526 867 q 427 915 457 899 q 369 931 397 931 l 369 931 q 327 908 342 931 q 310 857 313 886 z "
    },
    "j": {
      "ha": 903,
      "x_min": 97,
      "x_max": 674,
      "o": "m 476 769 l 668 769 l 668 17 q 638 -121 668 -61 q 551 -214 607 -181 q 418 -247 496 -247 l 418 -247 l 97 -247 l 97 -93 l 347 -93 q 443 -72 410 -93 q 476 15 476 -51 l 476 15 l 476 769 z m 572 615 l 314 615 l 314 769 l 572 769 l 572 615 z m 674 882 l 468 882 l 468 1067 l 674 1067 l 674 882 z "
    },
    "ȷ": {
      "ha": 903,
      "x_min": 97,
      "x_max": 668,
      "o": "m 476 769 l 668 769 l 668 17 q 638 -121 668 -61 q 551 -214 607 -181 q 418 -247 496 -247 l 418 -247 l 97 -247 l 97 -93 l 347 -93 q 443 -72 410 -93 q 476 15 476 -51 l 476 15 l 476 769 z m 572 615 l 314 615 l 314 769 l 572 769 l 572 615 z "
    },
    "ĵ": {
      "ha": 903,
      "x_min": 97,
      "x_max": 842,
      "o": "m 476 769 l 668 769 l 668 17 q 638 -121 668 -61 q 551 -214 607 -181 q 418 -247 496 -247 l 418 -247 l 97 -247 l 97 -93 l 347 -93 q 443 -72 410 -93 q 476 15 476 -51 l 476 15 l 476 769 z m 572 615 l 314 615 l 314 769 l 572 769 l 572 615 z m 479 856 l 300 856 l 467 1093 l 675 1093 l 842 856 l 664 856 l 571 996 l 479 856 z "
    },
    "k": {
      "ha": 903,
      "x_min": 71,
      "x_max": 808,
      "o": "m 263 0 l 71 0 l 71 1025 l 263 1025 l 263 678 l 251 453 l 399 622 l 549 769 l 794 769 l 263 256 l 263 0 z m 808 0 l 586 0 l 332 453 l 506 525 l 808 0 z "
    },
    "ķ": {
      "ha": 903,
      "x_min": 71,
      "x_max": 808,
      "o": "m 263 0 l 71 0 l 71 1025 l 263 1025 l 263 678 l 251 453 l 399 622 l 549 769 l 794 769 l 263 256 l 263 0 z m 808 0 l 586 0 l 332 453 l 506 525 l 808 0 z m 478 -276 l 347 -276 l 347 -62 l 525 -62 l 478 -276 z "
    },
    "ĸ": {
      "ha": 903,
      "x_min": 71,
      "x_max": 818,
      "o": "m 778 769 l 190 161 l 197 396 l 533 769 l 778 769 z m 818 0 l 596 0 l 324 453 l 497 525 l 818 0 z m 263 0 l 71 0 l 71 771 l 256 771 l 263 621 l 263 0 z "
    },
    "l": {
      "ha": 903,
      "x_min": 96,
      "x_max": 810,
      "o": "m 547 0 l 356 0 l 356 1025 l 547 1025 l 547 0 z m 810 0 l 96 0 l 96 154 l 810 154 l 810 0 z m 451 871 l 194 871 l 194 1025 l 451 1025 l 451 871 z "
    },
    "ĺ": {
      "ha": 903,
      "x_min": 96,
      "x_max": 810,
      "o": "m 547 0 l 356 0 l 356 1025 l 547 1025 l 547 0 z m 810 0 l 96 0 l 96 154 l 810 154 l 810 0 z m 451 871 l 194 871 l 194 1025 l 451 1025 l 451 871 z m 503 1094 l 338 1094 l 457 1332 l 658 1332 l 503 1094 z "
    },
    "ľ": {
      "ha": 903,
      "x_min": 96,
      "x_max": 810,
      "o": "m 547 0 l 356 0 l 356 1025 l 547 1025 l 547 0 z m 810 0 l 96 0 l 96 154 l 810 154 l 810 0 z m 451 871 l 194 871 l 194 1025 l 451 1025 l 451 871 z m 750 719 l 618 719 l 635 1025 l 803 1025 l 750 719 z "
    },
    "ļ": {
      "ha": 903,
      "x_min": 96,
      "x_max": 810,
      "o": "m 547 0 l 356 0 l 356 1025 l 547 1025 l 547 0 z m 810 0 l 96 0 l 96 154 l 810 154 l 810 0 z m 451 871 l 194 871 l 194 1025 l 451 1025 l 451 871 z m 493 -276 l 363 -276 l 363 -62 l 540 -62 l 493 -276 z "
    },
    "ŀ": {
      "ha": 903,
      "x_min": 96,
      "x_max": 1090,
      "o": "m 547 0 l 356 0 l 356 1025 l 547 1025 l 547 0 z m 810 0 l 96 0 l 96 154 l 810 154 l 810 0 z m 451 871 l 194 871 l 194 1025 l 451 1025 l 451 871 z m 1090 429 l 865 429 l 865 647 l 1090 647 l 1090 429 z "
    },
    "ł": {
      "ha": 903,
      "x_min": 96,
      "x_max": 810,
      "o": "m 685 571 l 213 399 l 213 561 l 685 733 l 685 571 z m 547 0 l 356 0 l 356 1025 l 547 1025 l 547 0 z m 810 0 l 96 0 l 96 154 l 810 154 l 810 0 z m 451 871 l 194 871 l 194 1025 l 451 1025 l 451 871 z "
    },
    "m": {
      "ha": 903,
      "x_min": 63,
      "x_max": 840,
      "o": "m 840 0 l 667 0 l 667 549 q 656 609 667 588 q 617 631 644 631 l 617 631 q 550 579 579 631 q 506 407 521 528 l 506 407 l 492 515 q 540 714 488 642 q 675 786 592 786 l 675 786 q 794 731 747 786 q 840 568 840 675 l 840 568 l 840 0 z m 235 0 l 63 0 l 63 769 l 231 769 l 235 625 l 235 0 z m 536 0 l 365 0 l 365 549 q 352 610 365 590 q 313 631 339 631 l 313 631 q 244 575 271 631 q 203 407 218 519 l 203 407 l 203 522 q 224 663 200 604 q 289 754 249 722 q 376 786 329 786 l 376 786 q 494 727 451 786 q 536 571 536 668 l 536 571 l 536 0 z "
    },
    "n": {
      "ha": 903,
      "x_min": 96,
      "x_max": 813,
      "o": "m 813 0 l 621 0 l 621 478 q 585 585 621 544 q 492 626 550 626 l 492 626 q 372 580 425 626 q 269 428 318 533 l 269 428 l 242 603 q 369 742 292 699 q 529 786 446 786 l 529 786 q 680 749 617 786 q 778 645 743 711 q 813 489 813 579 l 813 489 l 813 0 z m 288 0 l 96 0 l 96 769 l 281 769 l 288 621 l 288 0 z "
    },
    "ń": {
      "ha": 903,
      "x_min": 96,
      "x_max": 813,
      "o": "m 813 0 l 621 0 l 621 478 q 585 585 621 544 q 492 626 550 626 l 492 626 q 372 580 425 626 q 269 428 318 533 l 269 428 l 242 603 q 369 742 292 699 q 529 786 446 786 l 529 786 q 680 749 617 786 q 778 645 743 711 q 813 489 813 579 l 813 489 l 813 0 z m 288 0 l 96 0 l 96 769 l 281 769 l 288 621 l 288 0 z m 532 856 l 367 856 l 486 1093 l 688 1093 l 532 856 z "
    },
    "ň": {
      "ha": 903,
      "x_min": 96,
      "x_max": 813,
      "o": "m 813 0 l 621 0 l 621 478 q 585 585 621 544 q 492 626 550 626 l 492 626 q 372 580 425 626 q 269 428 318 533 l 269 428 l 242 603 q 369 742 292 699 q 529 786 446 786 l 529 786 q 680 749 617 786 q 778 645 743 711 q 813 489 813 579 l 813 489 l 813 0 z m 288 0 l 96 0 l 96 769 l 281 769 l 288 621 l 288 0 z m 376 856 l 210 1093 l 392 1093 l 481 957 l 571 1093 l 751 1093 l 585 856 l 376 856 z "
    },
    "ņ": {
      "ha": 903,
      "x_min": 96,
      "x_max": 813,
      "o": "m 813 0 l 621 0 l 621 478 q 585 585 621 544 q 492 626 550 626 l 492 626 q 372 580 425 626 q 269 428 318 533 l 269 428 l 242 603 q 369 742 292 699 q 529 786 446 786 l 529 786 q 680 749 617 786 q 778 645 743 711 q 813 489 813 579 l 813 489 l 813 0 z m 288 0 l 96 0 l 96 769 l 281 769 l 288 621 l 288 0 z m 501 -276 l 371 -276 l 371 -62 l 549 -62 l 501 -276 z "
    },
    "ñ": {
      "ha": 903,
      "x_min": 96,
      "x_max": 813,
      "o": "m 813 0 l 621 0 l 621 478 q 585 585 621 544 q 492 626 550 626 l 492 626 q 372 580 425 626 q 269 428 318 533 l 269 428 l 242 603 q 369 742 292 699 q 529 786 446 786 l 529 786 q 680 749 617 786 q 778 645 743 711 q 813 489 813 579 l 813 489 l 813 0 z m 288 0 l 96 0 l 96 769 l 281 769 l 288 621 l 288 0 z m 338 857 l 338 857 l 197 879 q 247 1024 206 968 q 363 1079 288 1079 l 363 1079 q 428 1070 400 1079 q 480 1049 457 1061 q 523 1027 503 1036 q 561 1018 543 1018 l 561 1018 q 606 1039 592 1018 q 625 1089 621 1060 l 625 1089 l 767 1067 q 713 923 756 979 q 597 867 671 867 l 597 867 q 519 883 554 867 q 455 915 485 899 q 397 931 425 931 l 397 931 q 355 908 369 931 q 338 857 340 886 z "
    },
    "ŋ": {
      "ha": 903,
      "x_min": 96,
      "x_max": 811,
      "o": "m 529 788 l 529 788 q 680 750 617 788 q 777 646 743 713 q 811 489 811 579 l 811 489 l 811 -8 q 781 -128 811 -74 q 696 -215 751 -182 q 563 -247 640 -247 l 563 -247 l 417 -247 l 417 -93 l 490 -93 q 586 -74 551 -93 q 621 4 621 -54 l 621 4 l 621 478 q 585 586 621 544 q 492 628 549 628 l 492 628 q 381 590 432 628 q 286 467 331 551 l 286 467 l 286 0 l 96 0 l 96 771 l 279 771 l 283 668 q 397 759 333 731 q 529 788 461 788 z "
    },
    "o": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 600 60 511 q 241 738 153 689 q 451 786 329 786 l 451 786 q 738 679 635 786 q 842 385 842 572 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 565 651 500 q 451 631 544 631 l 451 631 q 304 565 357 631 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z "
    },
    "ó": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 600 60 511 q 241 738 153 689 q 451 786 329 786 l 451 786 q 738 679 635 786 q 842 385 842 572 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 565 651 500 q 451 631 544 631 l 451 631 q 304 565 357 631 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z m 501 856 l 336 856 l 456 1093 l 657 1093 l 501 856 z "
    },
    "ŏ": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 600 60 511 q 241 738 153 689 q 451 786 329 786 l 451 786 q 738 679 635 786 q 842 385 842 572 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 565 651 500 q 451 631 544 631 l 451 631 q 304 565 357 631 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z m 450 853 l 450 853 q 319 882 375 853 q 232 965 263 911 q 201 1093 201 1019 l 201 1093 l 343 1093 q 373 1002 343 1033 q 450 971 403 971 l 450 971 q 528 1002 499 971 q 558 1093 558 1033 l 558 1093 l 701 1093 q 670 965 701 1019 q 583 882 639 911 q 450 853 526 853 z "
    },
    "ô": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 600 60 511 q 241 738 153 689 q 451 786 329 786 l 451 786 q 738 679 635 786 q 842 385 842 572 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 565 651 500 q 451 631 544 631 l 451 631 q 304 565 357 631 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z m 360 856 l 181 856 l 347 1093 l 556 1093 l 722 856 l 544 856 l 451 996 l 360 856 z "
    },
    "ö": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 600 60 511 q 241 738 153 689 q 451 786 329 786 l 451 786 q 738 679 635 786 q 842 385 842 572 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 565 651 500 q 451 631 544 631 l 451 631 q 304 565 357 631 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z m 708 883 l 503 883 l 503 1069 l 708 1069 l 708 883 z m 399 883 l 194 883 l 194 1069 l 399 1069 l 399 883 z "
    },
    "ò": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 600 60 511 q 241 738 153 689 q 451 786 329 786 l 451 786 q 738 679 635 786 q 842 385 842 572 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 565 651 500 q 451 631 544 631 l 451 631 q 304 565 357 631 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z m 453 1094 l 572 857 l 406 857 l 250 1094 l 453 1094 z "
    },
    "ő": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 600 60 511 q 241 738 153 689 q 451 786 329 786 l 451 786 q 738 679 635 786 q 842 385 842 572 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 565 651 500 q 451 631 544 631 l 451 631 q 304 565 357 631 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z m 410 856 l 260 856 l 361 1093 l 543 1093 l 410 856 z m 649 856 l 500 856 l 608 1093 l 792 1093 l 649 856 z "
    },
    "ō": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 600 60 511 q 241 738 153 689 q 451 786 329 786 l 451 786 q 738 679 635 786 q 842 385 842 572 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 565 651 500 q 451 631 544 631 l 451 631 q 304 565 357 631 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z m 696 886 l 207 886 l 207 1028 l 696 1028 l 696 886 z "
    },
    "ø": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 781 813 l 263 -111 l 122 -40 l 640 883 l 781 813 z m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 601 60 511 q 241 739 153 690 q 451 788 329 788 l 451 788 q 661 739 574 788 q 795 601 749 690 q 842 385 842 511 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 566 651 500 q 451 632 544 632 l 451 632 q 304 566 357 632 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z "
    },
    "ǿ": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 781 813 l 263 -111 l 122 -40 l 640 883 l 781 813 z m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 601 60 511 q 241 739 153 690 q 451 788 329 788 l 451 788 q 661 739 574 788 q 795 601 749 690 q 842 385 842 511 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 566 651 500 q 451 632 544 632 l 451 632 q 304 566 357 632 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z m 501 856 l 336 856 l 456 1093 l 657 1093 l 501 856 z "
    },
    "õ": {
      "ha": 903,
      "x_min": 60,
      "x_max": 842,
      "o": "m 451 -17 l 451 -17 q 241 32 329 -17 q 106 171 153 81 q 60 385 60 261 l 60 385 q 106 600 60 511 q 241 738 153 689 q 451 786 329 786 l 451 786 q 738 679 635 786 q 842 385 842 572 l 842 385 q 795 171 842 261 q 661 32 749 81 q 451 -17 574 -17 z m 451 139 l 451 139 q 598 205 544 139 q 651 385 651 271 l 651 385 q 598 565 651 500 q 451 631 544 631 l 451 631 q 304 565 357 631 q 251 385 251 500 l 251 385 q 304 205 251 271 q 451 139 357 139 z m 308 857 l 308 857 l 168 879 q 217 1024 176 968 q 333 1079 258 1079 l 333 1079 q 399 1070 371 1079 q 451 1049 428 1061 q 494 1027 474 1036 q 532 1018 514 1018 l 532 1018 q 577 1039 563 1018 q 596 1089 592 1060 l 596 1089 l 738 1067 q 684 923 726 979 q 568 867 642 867 l 568 867 q 490 883 525 867 q 426 915 456 899 q 368 931 396 931 l 368 931 q 326 908 340 931 q 308 857 311 886 z "
    },
    "œ": {
      "ha": 903,
      "x_min": 28,
      "x_max": 888,
      "o": "m 282 -17 l 282 -17 q 93 86 158 -17 q 28 383 28 189 l 28 383 q 93 682 28 578 q 282 786 158 786 l 282 786 q 470 683 406 786 q 535 383 535 579 l 535 383 q 470 86 535 189 q 282 -17 406 -17 z m 282 139 l 282 139 q 347 196 325 139 q 369 383 369 253 l 369 383 q 347 572 369 514 q 282 631 325 631 l 282 631 q 216 572 239 631 q 193 383 193 514 l 193 383 q 216 196 193 253 q 282 139 239 139 z m 621 -17 l 621 -17 q 433 90 497 -17 q 369 383 369 197 l 369 383 q 435 679 369 572 q 626 786 501 786 l 626 786 q 785 735 726 786 q 865 590 843 683 q 879 371 888 496 l 879 371 l 503 371 l 506 503 l 697 501 q 675 606 696 572 q 621 640 654 640 l 621 640 q 555 575 575 640 q 535 378 535 510 l 535 378 q 544 240 535 293 q 574 163 554 188 q 622 139 593 139 l 622 139 q 679 169 654 139 q 711 261 704 199 l 711 261 l 875 261 q 800 58 868 132 q 621 -17 732 -17 z "
    },
    "p": {
      "ha": 903,
      "x_min": 88,
      "x_max": 854,
      "o": "m 508 786 l 508 786 q 688 733 611 786 q 810 590 765 681 q 854 385 854 499 l 854 385 q 810 181 854 272 q 688 37 765 90 q 508 -17 611 -17 l 508 -17 q 379 10 439 -17 q 279 83 319 38 l 279 83 l 279 -247 l 88 -247 l 88 769 l 272 769 l 276 685 q 377 759 317 732 q 508 786 438 786 z m 461 142 l 461 142 q 566 174 521 142 q 637 260 611 206 q 663 385 663 315 l 663 385 q 637 510 663 456 q 566 597 611 565 q 461 628 521 628 l 461 628 q 351 591 400 628 q 279 492 301 554 l 279 492 l 279 279 q 351 179 301 217 q 461 142 400 142 z "
    },
    "þ": {
      "ha": 903,
      "x_min": 88,
      "x_max": 854,
      "o": "m 510 788 l 510 788 q 688 734 611 788 q 810 590 765 681 q 854 385 854 499 l 854 385 q 810 181 854 272 q 688 37 765 90 q 510 -17 611 -17 l 510 -17 q 376 12 438 -17 q 278 85 315 40 l 278 85 l 278 -247 l 88 -247 l 88 1026 l 278 1026 l 278 686 q 378 760 318 733 q 510 788 439 788 z m 463 142 l 463 142 q 567 174 522 142 q 637 260 611 206 q 663 385 663 315 l 663 385 q 637 510 663 456 q 567 597 611 565 q 463 629 522 629 l 463 629 q 349 592 399 629 q 278 489 300 554 l 278 489 l 278 282 q 349 180 300 218 q 463 142 399 142 z "
    },
    "q": {
      "ha": 903,
      "x_min": 50,
      "x_max": 815,
      "o": "m 394 786 l 394 786 q 527 759 467 786 q 626 685 588 732 l 626 685 l 631 769 l 815 769 l 815 -247 l 624 -247 l 624 83 q 525 10 585 38 q 394 -17 465 -17 l 394 -17 q 215 37 293 -17 q 94 181 138 90 q 50 385 50 272 l 50 385 q 94 590 50 499 q 215 733 138 681 q 394 786 293 786 z m 442 142 l 442 142 q 553 179 504 142 q 624 279 603 217 l 624 279 l 624 492 q 553 591 603 554 q 442 628 504 628 l 442 628 q 338 597 383 628 q 266 510 292 565 q 240 385 240 456 l 240 385 q 266 260 240 315 q 338 174 292 206 q 442 142 383 142 z "
    },
    "r": {
      "ha": 903,
      "x_min": 115,
      "x_max": 822,
      "o": "m 822 403 l 631 403 l 631 503 q 600 592 631 558 q 510 626 569 626 l 510 626 q 381 577 440 626 q 274 415 321 528 l 274 415 l 253 583 q 330 695 286 650 q 427 763 374 740 q 546 786 481 786 l 546 786 q 744 714 667 786 q 822 503 822 642 l 822 503 l 822 403 z m 307 0 l 115 0 l 115 769 l 299 769 l 307 619 l 307 0 z "
    },
    "ŕ": {
      "ha": 903,
      "x_min": 115,
      "x_max": 822,
      "o": "m 822 403 l 631 403 l 631 503 q 600 592 631 558 q 510 626 569 626 l 510 626 q 381 577 440 626 q 274 415 321 528 l 274 415 l 253 583 q 330 695 286 650 q 427 763 374 740 q 546 786 481 786 l 546 786 q 744 714 667 786 q 822 503 822 642 l 822 503 l 822 403 z m 307 0 l 115 0 l 115 769 l 299 769 l 307 619 l 307 0 z m 533 856 l 368 856 l 488 1093 l 689 1093 l 533 856 z "
    },
    "ř": {
      "ha": 903,
      "x_min": 115,
      "x_max": 822,
      "o": "m 822 403 l 631 403 l 631 503 q 600 592 631 558 q 510 626 569 626 l 510 626 q 381 577 440 626 q 274 415 321 528 l 274 415 l 253 583 q 330 695 286 650 q 427 763 374 740 q 546 786 481 786 l 546 786 q 744 714 667 786 q 822 503 822 642 l 822 503 l 822 403 z m 307 0 l 115 0 l 115 769 l 299 769 l 307 619 l 307 0 z m 378 856 l 211 1093 l 393 1093 l 482 957 l 572 1093 l 753 1093 l 586 856 l 378 856 z "
    },
    "ŗ": {
      "ha": 903,
      "x_min": 115,
      "x_max": 822,
      "o": "m 822 403 l 631 403 l 631 503 q 600 592 631 558 q 510 626 569 626 l 510 626 q 381 577 440 626 q 274 415 321 528 l 274 415 l 253 583 q 330 695 286 650 q 427 763 374 740 q 546 786 481 786 l 546 786 q 744 714 667 786 q 822 503 822 642 l 822 503 l 822 403 z m 307 0 l 115 0 l 115 769 l 299 769 l 307 619 l 307 0 z m 253 -276 l 122 -276 l 122 -62 l 300 -62 l 253 -276 z "
    },
    "s": {
      "ha": 903,
      "x_min": 115,
      "x_max": 803,
      "o": "m 471 -17 l 471 -17 q 285 19 365 -17 q 160 119 204 54 q 115 275 117 185 l 115 275 l 293 275 q 340 164 292 204 q 469 124 388 124 l 469 124 q 572 147 533 124 q 611 208 611 169 l 611 208 q 585 261 611 242 q 516 292 558 281 q 422 315 474 304 q 319 340 371 325 q 225 379 268 354 q 156 445 182 404 q 129 550 129 486 l 129 550 q 169 674 129 621 q 283 756 210 726 q 451 786 356 786 l 451 786 q 689 718 599 786 q 788 529 779 650 l 788 529 l 611 529 q 565 616 607 586 q 453 646 522 646 l 453 646 q 357 623 392 646 q 322 560 322 600 l 322 560 q 349 510 322 528 q 417 483 375 493 q 512 463 460 472 q 615 440 564 454 q 708 401 665 426 q 777 335 751 376 q 803 228 803 293 l 803 228 q 713 48 803 113 q 471 -17 622 -17 z "
    },
    "ś": {
      "ha": 903,
      "x_min": 115,
      "x_max": 803,
      "o": "m 471 -17 l 471 -17 q 285 19 365 -17 q 160 119 204 54 q 115 275 117 185 l 115 275 l 293 275 q 340 164 292 204 q 469 124 388 124 l 469 124 q 572 147 533 124 q 611 208 611 169 l 611 208 q 585 261 611 242 q 516 292 558 281 q 422 315 474 304 q 319 340 371 325 q 225 379 268 354 q 156 445 182 404 q 129 550 129 486 l 129 550 q 169 674 129 621 q 283 756 210 726 q 451 786 356 786 l 451 786 q 689 718 599 786 q 788 529 779 650 l 788 529 l 611 529 q 565 616 607 586 q 453 646 522 646 l 453 646 q 357 623 392 646 q 322 560 322 600 l 322 560 q 349 510 322 528 q 417 483 375 493 q 512 463 460 472 q 615 440 564 454 q 708 401 665 426 q 777 335 751 376 q 803 228 803 293 l 803 228 q 713 48 803 113 q 471 -17 622 -17 z m 514 856 l 349 856 l 468 1093 l 669 1093 l 514 856 z "
    },
    "š": {
      "ha": 903,
      "x_min": 115,
      "x_max": 803,
      "o": "m 471 -17 l 471 -17 q 285 19 365 -17 q 160 119 204 54 q 115 275 117 185 l 115 275 l 293 275 q 340 164 292 204 q 469 124 388 124 l 469 124 q 572 147 533 124 q 611 208 611 169 l 611 208 q 585 261 611 242 q 516 292 558 281 q 422 315 474 304 q 319 340 371 325 q 225 379 268 354 q 156 445 182 404 q 129 550 129 486 l 129 550 q 169 674 129 621 q 283 756 210 726 q 451 786 356 786 l 451 786 q 689 718 599 786 q 788 529 779 650 l 788 529 l 611 529 q 565 616 607 586 q 453 646 522 646 l 453 646 q 357 623 392 646 q 322 560 322 600 l 322 560 q 349 510 322 528 q 417 483 375 493 q 512 463 460 472 q 615 440 564 454 q 708 401 665 426 q 777 335 751 376 q 803 228 803 293 l 803 228 q 713 48 803 113 q 471 -17 622 -17 z m 360 856 l 193 1093 l 375 1093 l 464 957 l 554 1093 l 735 1093 l 568 856 l 360 856 z "
    },
    "ş": {
      "ha": 903,
      "x_min": 115,
      "x_max": 803,
      "o": "m 471 -17 l 471 -17 q 285 19 365 -17 q 160 119 204 54 q 115 275 117 185 l 115 275 l 293 275 q 340 164 292 204 q 469 124 388 124 l 469 124 q 572 147 533 124 q 611 208 611 169 l 611 208 q 585 261 611 242 q 516 292 558 281 q 422 315 474 304 q 319 340 371 325 q 225 379 268 354 q 156 445 182 404 q 129 550 129 486 l 129 550 q 169 674 129 621 q 283 756 210 726 q 451 786 356 786 l 451 786 q 689 718 599 786 q 788 529 779 650 l 788 529 l 611 529 q 565 616 607 586 q 453 646 522 646 l 453 646 q 357 623 392 646 q 322 560 322 600 l 322 560 q 349 510 322 528 q 417 483 375 493 q 512 463 460 472 q 615 440 564 454 q 708 401 665 426 q 777 335 751 376 q 803 228 803 293 l 803 228 q 713 48 803 113 q 471 -17 622 -17 z m 444 -308 l 444 -308 q 376 -301 411 -308 q 308 -285 340 -294 l 308 -285 l 308 -197 q 358 -210 331 -206 q 413 -215 386 -215 l 413 -215 q 464 -206 442 -215 q 486 -179 486 -197 l 486 -179 q 466 -153 486 -162 q 419 -144 446 -144 l 419 -144 q 394 -147 407 -144 q 369 -153 381 -149 l 369 -153 l 339 -103 l 424 38 l 519 6 l 471 -68 q 483 -66 478 -67 q 494 -65 489 -65 l 494 -65 q 558 -78 528 -65 q 608 -115 589 -90 q 628 -179 628 -140 l 628 -179 q 603 -252 628 -224 q 536 -294 578 -281 q 444 -308 494 -308 z "
    },
    "ŝ": {
      "ha": 903,
      "x_min": 115,
      "x_max": 803,
      "o": "m 471 -17 l 471 -17 q 285 19 365 -17 q 160 119 204 54 q 115 275 117 185 l 115 275 l 293 275 q 340 164 292 204 q 469 124 388 124 l 469 124 q 572 147 533 124 q 611 208 611 169 l 611 208 q 585 261 611 242 q 516 292 558 281 q 422 315 474 304 q 319 340 371 325 q 225 379 268 354 q 156 445 182 404 q 129 550 129 486 l 129 550 q 169 674 129 621 q 283 756 210 726 q 451 786 356 786 l 451 786 q 689 718 599 786 q 788 529 779 650 l 788 529 l 611 529 q 565 616 607 586 q 453 646 522 646 l 453 646 q 357 623 392 646 q 322 560 322 600 l 322 560 q 349 510 322 528 q 417 483 375 493 q 512 463 460 472 q 615 440 564 454 q 708 401 665 426 q 777 335 751 376 q 803 228 803 293 l 803 228 q 713 48 803 113 q 471 -17 622 -17 z m 371 856 l 192 856 l 358 1093 l 567 1093 l 733 856 l 556 856 l 463 996 l 371 856 z "
    },
    "ș": {
      "ha": 903,
      "x_min": 115,
      "x_max": 803,
      "o": "m 471 -17 l 471 -17 q 285 19 365 -17 q 160 119 204 54 q 115 275 117 185 l 115 275 l 293 275 q 340 164 292 204 q 469 124 388 124 l 469 124 q 572 147 533 124 q 611 208 611 169 l 611 208 q 585 261 611 242 q 516 292 558 281 q 422 315 474 304 q 319 340 371 325 q 225 379 268 354 q 156 445 182 404 q 129 550 129 486 l 129 550 q 169 674 129 621 q 283 756 210 726 q 451 786 356 786 l 451 786 q 689 718 599 786 q 788 529 779 650 l 788 529 l 611 529 q 565 616 607 586 q 453 646 522 646 l 453 646 q 357 623 392 646 q 322 560 322 600 l 322 560 q 349 510 322 528 q 417 483 375 493 q 512 463 460 472 q 615 440 564 454 q 708 401 665 426 q 777 335 751 376 q 803 228 803 293 l 803 228 q 713 48 803 113 q 471 -17 622 -17 z m 493 -276 l 363 -276 l 363 -62 l 540 -62 l 493 -276 z "
    },
    "ß": {
      "ha": 903,
      "x_min": 75,
      "x_max": 868,
      "o": "m 493 -17 l 493 -17 q 427 -12 464 -17 q 354 3 390 -7 l 354 3 l 354 160 q 413 144 376 150 q 482 139 450 139 l 482 139 q 583 158 539 139 q 651 210 626 176 q 676 294 676 244 l 676 294 q 621 409 676 367 q 472 451 565 451 l 472 451 l 354 451 l 354 607 l 458 607 q 538 624 506 607 q 588 670 571 640 q 604 739 604 700 l 604 739 q 583 810 604 781 q 528 856 563 840 q 449 872 493 872 l 449 872 q 315 816 365 872 q 265 675 265 760 l 265 675 l 265 0 l 75 0 l 75 675 q 120 856 75 776 q 249 981 165 935 q 450 1026 333 1026 l 450 1026 q 633 990 557 1026 q 751 890 710 953 q 793 749 793 826 l 793 749 q 742 602 793 661 q 611 519 692 543 l 611 519 l 606 564 q 745 524 686 561 q 836 426 804 488 q 868 288 868 365 l 868 288 q 822 126 868 194 q 691 20 775 57 q 493 -17 607 -17 z "
    },
    "t": {
      "ha": 903,
      "x_min": 106,
      "x_max": 796,
      "o": "m 796 0 l 514 0 q 383 33 439 0 q 297 126 326 67 q 267 263 267 186 l 267 263 l 267 904 l 458 994 l 458 263 q 469 203 458 228 q 508 167 479 179 q 589 154 536 154 l 589 154 l 796 154 l 796 0 z m 663 615 l 106 615 l 106 769 l 663 769 l 663 615 z "
    },
    "ŧ": {
      "ha": 903,
      "x_min": 106,
      "x_max": 796,
      "o": "m 665 346 l 114 346 l 114 469 l 665 469 l 665 346 z m 796 0 l 514 0 q 383 33 439 0 q 297 126 326 67 q 267 263 267 186 l 267 263 l 267 904 l 458 994 l 458 263 q 469 203 458 228 q 508 167 479 179 q 589 154 536 154 l 589 154 l 796 154 l 796 0 z m 663 615 l 106 615 l 106 769 l 663 769 l 663 615 z "
    },
    "ť": {
      "ha": 903,
      "x_min": 106,
      "x_max": 796,
      "o": "m 796 0 l 514 0 q 383 33 439 0 q 297 126 326 67 q 267 263 267 186 l 267 263 l 267 904 l 458 994 l 458 263 q 469 203 458 228 q 508 167 479 179 q 589 154 536 154 l 589 154 l 796 154 l 796 0 z m 663 615 l 106 615 l 106 769 l 663 769 l 663 615 z m 654 829 l 522 829 l 539 1135 l 707 1135 l 654 829 z "
    },
    "ţ": {
      "ha": 903,
      "x_min": 106,
      "x_max": 796,
      "o": "m 796 0 l 514 0 q 383 33 439 0 q 297 126 326 67 q 267 263 267 186 l 267 263 l 267 904 l 458 994 l 458 263 q 469 203 458 228 q 508 167 479 179 q 589 154 536 154 l 589 154 l 796 154 l 796 0 z m 663 615 l 106 615 l 106 769 l 663 769 l 663 615 z m 514 -308 l 514 -308 q 445 -301 481 -308 q 378 -285 410 -294 l 378 -285 l 378 -197 q 428 -210 400 -206 q 482 -215 456 -215 l 482 -215 q 533 -206 511 -215 q 556 -179 556 -197 l 556 -179 q 535 -153 556 -162 q 489 -144 515 -144 l 489 -144 q 463 -147 476 -144 q 439 -153 450 -149 l 439 -153 l 408 -103 l 493 38 l 589 6 l 540 -68 q 553 -66 547 -67 q 564 -65 558 -65 l 564 -65 q 628 -78 597 -65 q 678 -115 658 -90 q 697 -179 697 -140 l 697 -179 q 672 -252 697 -224 q 606 -294 647 -281 q 514 -308 564 -308 z "
    },
    "ț": {
      "ha": 903,
      "x_min": 106,
      "x_max": 796,
      "o": "m 796 0 l 514 0 q 383 33 439 0 q 297 126 326 67 q 267 263 267 186 l 267 263 l 267 904 l 458 994 l 458 263 q 469 203 458 228 q 508 167 479 179 q 589 154 536 154 l 589 154 l 796 154 l 796 0 z m 663 615 l 106 615 l 106 769 l 663 769 l 663 615 z m 563 -276 l 432 -276 l 432 -62 l 610 -62 l 563 -276 z "
    },
    "u": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z "
    },
    "ú": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 499 854 l 333 854 l 453 1092 l 654 1092 l 499 854 z "
    },
    "ŭ": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 447 851 l 447 851 q 316 881 372 851 q 229 964 260 910 q 199 1092 199 1018 l 199 1092 l 340 1092 q 370 1001 340 1032 q 447 969 400 969 l 447 969 q 526 1001 496 969 q 556 1092 556 1032 l 556 1092 l 699 1092 q 667 964 699 1018 q 580 881 636 910 q 447 851 524 851 z "
    },
    "û": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 357 854 l 178 854 l 344 1092 l 553 1092 l 719 854 l 542 854 l 449 994 l 357 854 z "
    },
    "ü": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 706 882 l 500 882 l 500 1068 l 706 1068 l 706 882 z m 396 882 l 192 882 l 192 1068 l 396 1068 l 396 882 z "
    },
    "ù": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 450 1093 l 569 856 l 403 856 l 247 1093 l 450 1093 z "
    },
    "ű": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 407 854 l 257 854 l 358 1092 l 540 1092 l 407 854 z m 646 854 l 497 854 l 606 1092 l 789 1092 l 646 854 z "
    },
    "ū": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 693 885 l 204 885 l 204 1026 l 693 1026 l 693 885 z "
    },
    "ų": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 757 -183 l 757 -300 q 735 -303 747 -301 q 703 -304 724 -304 l 703 -304 q 569 -265 615 -304 q 522 -157 522 -225 l 522 -157 q 549 -62 522 -104 q 613 8 576 -21 q 681 53 650 38 l 681 53 l 757 0 q 690 -55 715 -18 q 664 -126 664 -92 l 664 -126 q 688 -172 664 -157 q 732 -186 711 -186 l 732 -186 q 743 -185 738 -186 q 757 -183 749 -185 l 757 -183 z "
    },
    "ů": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 447 821 l 447 821 q 322 865 369 821 q 274 983 274 910 l 274 983 q 322 1100 274 1056 q 447 1144 371 1144 l 447 1144 q 575 1100 526 1144 q 624 983 624 1056 l 624 983 q 575 865 624 910 q 447 821 526 821 z m 447 915 l 447 915 q 499 934 479 915 q 518 983 518 953 l 518 983 q 499 1033 518 1014 q 449 1051 479 1051 l 449 1051 q 399 1033 418 1051 q 379 985 379 1014 l 379 985 q 399 934 379 953 q 447 915 418 915 z "
    },
    "ũ": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 79 769 l 271 769 l 271 292 q 306 184 271 225 q 400 143 342 143 l 400 143 q 520 190 467 143 q 622 342 574 236 l 622 342 l 650 167 q 523 27 600 71 q 363 -17 446 -17 l 363 -17 q 212 21 275 -17 q 114 124 149 58 q 79 281 79 190 l 79 281 l 79 769 z m 604 769 l 796 769 l 796 0 l 611 0 l 604 149 l 604 769 z m 306 856 l 306 856 l 165 878 q 215 1022 174 967 q 331 1078 256 1078 l 331 1078 q 397 1069 368 1078 q 448 1047 425 1060 q 491 1026 471 1035 q 529 1017 511 1017 l 529 1017 q 574 1038 560 1017 q 593 1088 589 1058 l 593 1088 l 735 1065 q 681 922 724 978 q 565 865 639 865 l 565 865 q 488 881 522 865 q 423 913 453 897 q 365 929 393 929 l 365 929 q 323 907 338 929 q 306 856 308 885 z "
    },
    "v": {
      "ha": 903,
      "x_min": 58,
      "x_max": 843,
      "o": "m 561 0 l 342 0 l 58 769 l 260 769 l 404 356 l 454 169 l 506 356 l 649 769 l 843 769 l 561 0 z "
    },
    "w": {
      "ha": 903,
      "x_min": 25,
      "x_max": 879,
      "o": "m 357 0 l 138 0 l 25 772 l 200 772 l 251 365 l 268 194 l 296 365 l 368 772 l 539 772 l 617 365 l 644 192 l 663 365 l 711 772 l 879 772 l 769 0 l 549 0 l 472 381 l 451 594 l 432 381 l 357 0 z "
    },
    "ẃ": {
      "ha": 903,
      "x_min": 25,
      "x_max": 879,
      "o": "m 357 0 l 138 0 l 25 772 l 200 772 l 251 365 l 268 194 l 296 365 l 368 772 l 539 772 l 617 365 l 644 192 l 663 365 l 711 772 l 879 772 l 769 0 l 549 0 l 472 381 l 451 594 l 432 381 l 357 0 z m 504 856 l 339 856 l 458 1093 l 660 1093 l 504 856 z "
    },
    "ŵ": {
      "ha": 903,
      "x_min": 25,
      "x_max": 879,
      "o": "m 357 0 l 138 0 l 25 772 l 200 772 l 251 365 l 268 194 l 296 365 l 368 772 l 539 772 l 617 365 l 644 192 l 663 365 l 711 772 l 879 772 l 769 0 l 549 0 l 472 381 l 451 594 l 432 381 l 357 0 z m 361 856 l 182 856 l 349 1093 l 557 1093 l 724 856 l 546 856 l 453 996 l 361 856 z "
    },
    "ẅ": {
      "ha": 903,
      "x_min": 25,
      "x_max": 879,
      "o": "m 357 0 l 138 0 l 25 772 l 200 772 l 251 365 l 268 194 l 296 365 l 368 772 l 539 772 l 617 365 l 644 192 l 663 365 l 711 772 l 879 772 l 769 0 l 549 0 l 472 381 l 451 594 l 432 381 l 357 0 z m 710 883 l 504 883 l 504 1069 l 710 1069 l 710 883 z m 400 883 l 196 883 l 196 1069 l 400 1069 l 400 883 z "
    },
    "ẁ": {
      "ha": 903,
      "x_min": 25,
      "x_max": 879,
      "o": "m 357 0 l 138 0 l 25 772 l 200 772 l 251 365 l 268 194 l 296 365 l 368 772 l 539 772 l 617 365 l 644 192 l 663 365 l 711 772 l 879 772 l 769 0 l 549 0 l 472 381 l 451 594 l 432 381 l 357 0 z m 454 1094 l 574 857 l 407 857 l 251 1094 l 454 1094 z "
    },
    "x": {
      "ha": 903,
      "x_min": 74,
      "x_max": 829,
      "o": "m 813 769 l 567 397 l 829 0 l 614 0 l 556 83 l 449 257 l 340 83 l 282 0 l 74 0 l 333 393 l 85 769 l 297 769 l 342 707 l 450 533 l 558 707 l 603 769 l 813 769 z "
    },
    "y": {
      "ha": 903,
      "x_min": 57,
      "x_max": 846,
      "o": "m 254 769 l 381 465 l 451 269 l 522 465 l 650 769 l 846 769 l 500 -49 q 424 -176 467 -129 q 328 -243 382 -222 q 206 -264 275 -264 l 206 -264 l 65 -264 l 65 -110 l 188 -110 q 251 -92 225 -110 q 299 -44 278 -75 q 338 28 319 -14 l 338 28 l 353 65 l 57 769 l 254 769 z "
    },
    "ý": {
      "ha": 903,
      "x_min": 57,
      "x_max": 846,
      "o": "m 254 769 l 381 465 l 451 269 l 522 465 l 650 769 l 846 769 l 500 -49 q 424 -176 467 -129 q 328 -243 382 -222 q 206 -264 275 -264 l 206 -264 l 65 -264 l 65 -110 l 188 -110 q 251 -92 225 -110 q 299 -44 278 -75 q 338 28 319 -14 l 338 28 l 353 65 l 57 769 l 254 769 z m 506 856 l 340 856 l 460 1093 l 661 1093 l 506 856 z "
    },
    "ŷ": {
      "ha": 903,
      "x_min": 57,
      "x_max": 846,
      "o": "m 254 769 l 381 465 l 451 269 l 522 465 l 650 769 l 846 769 l 500 -49 q 424 -176 467 -129 q 328 -243 382 -222 q 206 -264 275 -264 l 206 -264 l 65 -264 l 65 -110 l 188 -110 q 251 -92 225 -110 q 299 -44 278 -75 q 338 28 319 -14 l 338 28 l 353 65 l 57 769 l 254 769 z m 363 856 l 183 856 l 350 1093 l 558 1093 l 725 856 l 547 856 l 454 996 l 363 856 z "
    },
    "ÿ": {
      "ha": 903,
      "x_min": 57,
      "x_max": 846,
      "o": "m 254 769 l 381 465 l 451 269 l 522 465 l 650 769 l 846 769 l 500 -49 q 424 -176 467 -129 q 328 -243 382 -222 q 206 -264 275 -264 l 206 -264 l 65 -264 l 65 -110 l 188 -110 q 251 -92 225 -110 q 299 -44 278 -75 q 338 28 319 -14 l 338 28 l 353 65 l 57 769 l 254 769 z m 711 883 l 506 883 l 506 1069 l 711 1069 l 711 883 z m 401 883 l 197 883 l 197 1069 l 401 1069 l 401 883 z "
    },
    "ỳ": {
      "ha": 903,
      "x_min": 57,
      "x_max": 846,
      "o": "m 254 769 l 381 465 l 451 269 l 522 465 l 650 769 l 846 769 l 500 -49 q 424 -176 467 -129 q 328 -243 382 -222 q 206 -264 275 -264 l 206 -264 l 65 -264 l 65 -110 l 188 -110 q 251 -92 225 -110 q 299 -44 278 -75 q 338 28 319 -14 l 338 28 l 353 65 l 57 769 l 254 769 z m 456 1094 l 575 857 l 408 857 l 253 1094 l 456 1094 z "
    },
    "z": {
      "ha": 903,
      "x_min": 121,
      "x_max": 783,
      "o": "m 783 0 l 121 0 l 121 139 l 415 472 l 557 617 l 350 615 l 139 615 l 139 769 l 783 769 l 783 633 l 486 297 l 343 153 l 550 154 l 783 154 l 783 0 z "
    },
    "ź": {
      "ha": 903,
      "x_min": 121,
      "x_max": 783,
      "o": "m 783 0 l 121 0 l 121 139 l 415 472 l 557 617 l 350 615 l 139 615 l 139 769 l 783 769 l 783 633 l 486 297 l 343 153 l 550 154 l 783 154 l 783 0 z m 501 856 l 336 856 l 456 1093 l 657 1093 l 501 856 z "
    },
    "ž": {
      "ha": 903,
      "x_min": 121,
      "x_max": 783,
      "o": "m 783 0 l 121 0 l 121 139 l 415 472 l 557 617 l 350 615 l 139 615 l 139 769 l 783 769 l 783 633 l 486 297 l 343 153 l 550 154 l 783 154 l 783 0 z m 347 856 l 181 1093 l 363 1093 l 451 957 l 542 1093 l 722 1093 l 556 856 l 347 856 z "
    },
    "ż": {
      "ha": 903,
      "x_min": 121,
      "x_max": 783,
      "o": "m 783 0 l 121 0 l 121 139 l 415 472 l 557 617 l 350 615 l 139 615 l 139 769 l 783 769 l 783 633 l 486 297 l 343 153 l 550 154 l 783 154 l 783 0 z m 554 882 l 349 882 l 349 1067 l 554 1067 l 554 882 z "
    },
    "ª": {
      "ha": 903,
      "x_min": 204,
      "x_max": 669,
      "o": "m 669 463 l 539 463 l 535 578 l 535 797 q 509 861 535 838 q 439 885 483 885 l 439 885 q 367 863 396 885 q 335 804 338 842 l 335 804 l 204 804 q 272 932 210 881 q 443 983 335 983 l 443 983 q 612 930 554 983 q 669 790 669 876 l 669 790 l 669 463 z m 386 450 l 386 450 q 301 469 339 450 q 240 522 263 488 q 218 606 218 557 l 218 606 q 281 735 218 690 q 439 779 343 779 l 439 779 l 547 779 l 547 681 l 439 679 q 376 662 399 679 q 353 619 353 644 l 353 619 q 370 574 353 592 q 421 557 388 557 l 421 557 q 508 594 474 557 q 558 692 542 632 l 558 692 l 581 613 q 544 519 572 554 q 474 467 515 483 q 386 450 432 450 z "
    },
    "º": {
      "ha": 903,
      "x_min": 204,
      "x_max": 697,
      "o": "m 451 450 l 451 450 q 272 520 340 450 q 204 718 204 590 l 204 718 q 272 915 204 844 q 451 985 340 985 l 451 985 q 630 915 563 985 q 697 718 697 844 l 697 718 q 630 520 697 590 q 451 450 563 450 z m 451 554 l 451 554 q 531 596 501 554 q 560 718 560 638 l 560 718 q 531 840 560 800 q 451 881 501 881 l 451 881 q 372 840 400 881 q 343 718 343 800 l 343 718 q 372 596 343 638 q 451 554 400 554 z "
    },
    "¹": {
      "ha": 903,
      "x_min": 225,
      "x_max": 750,
      "o": "m 592 417 l 436 417 l 436 879 l 225 722 l 225 875 l 456 1039 l 592 1039 l 592 417 z m 231 417 l 231 543 l 750 543 l 750 417 l 231 417 z "
    },
    "²": {
      "ha": 903,
      "x_min": 208,
      "x_max": 688,
      "o": "m 688 418 l 213 418 l 213 524 q 396 663 326 603 q 497 768 465 722 q 528 850 528 814 l 528 850 q 507 903 528 882 q 450 924 486 924 l 450 924 q 388 897 411 924 q 361 822 364 869 l 361 822 l 208 822 q 278 990 211 929 q 451 1050 346 1050 l 451 1050 q 621 999 556 1050 q 686 860 686 947 l 686 860 q 660 747 686 799 q 580 646 633 694 q 446 544 526 597 l 446 544 l 688 544 l 688 418 z "
    },
    "³": {
      "ha": 903,
      "x_min": 211,
      "x_max": 697,
      "o": "m 457 407 l 457 407 q 333 433 389 407 q 244 512 276 460 q 213 636 211 564 l 213 636 l 364 636 q 390 563 365 590 q 454 535 415 535 l 454 535 q 515 556 492 535 q 539 608 539 578 l 539 608 q 509 665 539 644 q 436 685 479 685 l 436 685 l 406 685 l 406 792 l 439 792 q 499 812 476 792 q 521 861 521 832 l 521 861 q 502 906 521 889 q 451 924 483 924 l 451 924 q 397 903 421 924 q 371 840 372 883 l 371 840 l 218 840 q 253 955 221 908 q 338 1026 285 1001 q 454 1050 390 1050 l 454 1050 q 613 1003 553 1050 q 674 881 674 957 l 674 881 q 643 788 674 822 q 581 746 613 754 l 581 746 q 663 696 628 736 q 697 600 697 656 l 697 600 q 633 461 697 515 q 457 407 568 407 z "
    },
    "⁴": {
      "ha": 903,
      "x_min": 164,
      "x_max": 739,
      "o": "m 622 417 l 467 417 l 467 549 l 164 549 l 164 653 l 436 1039 l 622 1039 l 622 674 l 739 674 l 739 549 l 622 549 l 622 417 z m 467 885 l 318 674 l 467 674 l 467 885 z "
    },
    "⁄": {
      "ha": 903,
      "x_min": 57,
      "x_max": 846,
      "o": "m 233 0 l 57 0 l 671 969 l 846 969 l 233 0 z "
    },
    "½": {
      "ha": 1806,
      "x_min": 181,
      "x_max": 1631,
      "o": "m 547 347 l 392 347 l 392 810 l 181 653 l 181 806 l 411 969 l 547 969 l 547 347 z m 186 347 l 186 474 l 706 474 l 706 347 l 186 347 z m 733 0 l 557 0 l 1171 969 l 1346 969 l 733 0 z m 1631 0 l 1156 0 l 1156 106 q 1339 244 1269 185 q 1440 350 1408 304 q 1471 432 1471 396 l 1471 432 q 1450 485 1471 464 q 1393 506 1429 506 l 1393 506 q 1331 478 1354 506 q 1304 404 1307 451 l 1304 404 l 1151 404 q 1222 572 1154 511 q 1394 632 1289 632 l 1394 632 q 1564 581 1499 632 q 1629 442 1629 529 l 1629 442 q 1603 328 1629 381 q 1523 228 1576 276 q 1389 126 1469 179 l 1389 126 l 1631 126 l 1631 0 z "
    },
    "¼": {
      "ha": 1806,
      "x_min": 181,
      "x_max": 1606,
      "o": "m 547 347 l 392 347 l 392 810 l 181 653 l 181 806 l 411 969 l 547 969 l 547 347 z m 186 347 l 186 474 l 706 474 l 706 347 l 186 347 z m 724 0 l 547 0 l 1161 969 l 1336 969 l 724 0 z m 1489 0 l 1333 0 l 1333 132 l 1031 132 l 1031 236 l 1303 622 l 1489 622 l 1489 257 l 1606 257 l 1606 132 l 1489 132 l 1489 0 z m 1333 468 l 1185 257 l 1333 257 l 1333 468 z "
    },
    "¾": {
      "ha": 1806,
      "x_min": 211,
      "x_max": 1617,
      "o": "m 457 338 l 457 338 q 333 364 389 338 q 244 442 276 390 q 213 567 211 494 l 213 567 l 364 567 q 390 493 365 521 q 454 465 415 465 l 454 465 q 515 487 492 465 q 539 539 539 508 l 539 539 q 509 595 539 575 q 436 615 479 615 l 436 615 l 406 615 l 406 722 l 439 722 q 499 742 476 722 q 521 792 521 763 l 521 792 q 502 837 521 819 q 451 854 483 854 l 451 854 q 397 834 421 854 q 371 771 372 814 l 371 771 l 218 771 q 253 885 221 839 q 338 956 285 932 q 454 981 390 981 l 454 981 q 613 934 553 981 q 674 811 674 888 l 674 811 q 643 719 674 753 q 581 676 613 685 l 581 676 q 663 626 628 667 q 697 531 697 586 l 697 531 q 633 392 697 446 q 457 338 568 338 z m 689 0 l 513 0 l 1126 969 l 1301 969 l 689 0 z m 1501 0 l 1346 0 l 1346 132 l 1043 132 l 1043 236 l 1315 622 l 1501 622 l 1501 257 l 1618 257 l 1618 132 l 1501 132 l 1501 0 z m 1346 468 l 1197 257 l 1346 257 l 1346 468 z "
    },
    "\r": {
      "ha": 903,
      "x_min": null,
      "x_max": null,
      "o": ""
    },
    "﻿": {
      "ha": 0,
      "x_min": null,
      "x_max": null,
      "o": ""
    },
    "\u0000": {
      "ha": 0,
      "x_min": null,
      "x_max": null,
      "o": ""
    },
    " ": {
      "ha": 903,
      "x_min": null,
      "x_max": null,
      "o": ""
    },
    " ": {
      "ha": 903,
      "x_min": null,
      "x_max": null,
      "o": ""
    },
    " ": {
      "ha": 903,
      "x_min": null,
      "x_max": null,
      "o": ""
    },
    ".": {
      "ha": 903,
      "x_min": 339,
      "x_max": 564,
      "o": "m 564 0 l 339 0 l 339 218 l 564 218 l 564 0 z "
    },
    ",": {
      "ha": 903,
      "x_min": 269,
      "x_max": 563,
      "o": "m 457 3 l 338 0 l 338 217 l 563 217 l 563 0 l 444 -258 l 269 -258 l 338 -153 l 457 3 z "
    },
    ":": {
      "ha": 903,
      "x_min": 338,
      "x_max": 564,
      "o": "m 563 483 l 338 483 l 338 701 l 563 701 l 563 483 z m 564 0 l 339 0 l 339 218 l 564 218 l 564 0 z "
    },
    ";": {
      "ha": 903,
      "x_min": 279,
      "x_max": 568,
      "o": "m 450 0 l 331 0 l 331 231 l 568 231 l 568 0 l 456 -261 l 279 -261 l 450 0 z m 561 482 l 338 482 l 338 699 l 561 699 l 561 482 z "
    },
    "…": {
      "ha": 903,
      "x_min": 40,
      "x_max": 864,
      "o": "m 256 0 l 40 0 l 40 210 l 256 210 l 256 0 z m 560 0 l 344 0 l 344 210 l 560 210 l 560 0 z m 864 0 l 649 0 l 649 210 l 864 210 l 864 0 z "
    },
    "!": {
      "ha": 903,
      "x_min": 339,
      "x_max": 564,
      "o": "m 524 289 l 381 289 l 340 804 l 340 969 l 564 969 l 564 804 l 524 289 z m 563 0 l 339 0 l 339 218 l 563 218 l 563 0 z "
    },
    "¡": {
      "ha": 903,
      "x_min": 333,
      "x_max": 569,
      "o": "m 333 539 l 333 769 l 569 769 l 569 539 l 333 539 z m 381 481 l 524 481 l 564 -35 l 564 -200 l 340 -200 l 340 -35 l 381 481 z "
    },
    "?": {
      "ha": 903,
      "x_min": 101,
      "x_max": 800,
      "o": "m 532 286 l 357 286 l 357 313 q 375 412 357 372 q 422 478 393 451 q 482 526 450 504 q 542 570 514 547 q 588 624 569 593 q 606 703 606 656 l 606 703 q 588 769 606 740 q 538 815 571 799 q 461 831 504 831 l 461 831 q 342 778 390 831 q 296 640 294 725 l 296 640 l 103 640 q 146 822 101 744 q 272 943 190 900 q 461 986 354 986 l 461 986 q 644 951 568 986 q 760 853 719 917 q 800 703 800 789 l 800 703 q 781 593 800 638 q 733 519 763 549 q 669 468 703 490 q 606 425 636 446 q 556 378 575 404 q 533 313 536 353 l 533 313 l 532 286 z m 554 0 l 331 0 l 331 218 l 554 218 l 554 0 z "
    },
    "¿": {
      "ha": 903,
      "x_min": 103,
      "x_max": 803,
      "o": "m 371 483 l 546 483 l 546 457 q 528 358 546 397 q 481 292 510 318 q 421 244 453 265 q 361 199 389 222 q 315 145 333 176 q 297 67 297 114 l 297 67 q 315 0 297 29 q 365 -45 332 -29 q 442 -61 399 -61 l 442 -61 q 560 -8 513 -61 q 607 129 608 44 l 607 129 l 800 129 q 757 -53 801 25 q 631 -174 713 -131 q 442 -217 549 -217 l 442 -217 q 259 -182 335 -217 q 143 -83 183 -147 q 103 67 103 -19 l 103 67 q 122 176 103 132 q 170 250 140 221 q 233 301 200 279 q 297 344 267 324 q 347 391 328 365 q 369 457 367 417 l 369 457 l 371 483 z m 349 769 l 572 769 l 572 551 l 349 551 l 349 769 z "
    },
    "·": {
      "ha": 903,
      "x_min": 394,
      "x_max": 618,
      "o": "m 618 329 l 393 329 l 393 547 l 618 547 l 618 329 z "
    },
    "•": {
      "ha": 903,
      "x_min": 282,
      "x_max": 621,
      "o": "m 621 256 l 282 256 l 282 578 l 621 578 l 621 256 z "
    },
    "*": {
      "ha": 903,
      "x_min": 169,
      "x_max": 735,
      "o": "m 451 615 l 336 468 l 221 553 l 346 693 l 169 740 l 215 871 l 378 808 l 378 986 l 524 986 l 524 808 l 689 871 l 735 740 l 557 693 l 683 553 l 568 468 l 451 615 z "
    },
    "#": {
      "ha": 903,
      "x_min": 3,
      "x_max": 900,
      "o": "m 560 0 l 390 0 l 657 972 l 826 972 l 560 0 z m 764 228 l 3 228 l 47 393 l 808 393 l 764 228 z m 247 0 l 78 0 l 344 972 l 514 972 l 247 0 z m 856 567 l 93 567 l 139 731 l 900 731 l 856 567 z "
    },
    "/": {
      "ha": 903,
      "x_min": 83,
      "x_max": 819,
      "o": "m 281 -136 l 83 -136 l 621 972 l 819 972 l 281 -136 z "
    },
    "\\": {
      "ha": 903,
      "x_min": 85,
      "x_max": 819,
      "o": "m 282 972 l 819 -136 l 622 -136 l 85 972 l 282 972 z "
    },
    "-": {
      "ha": 903,
      "x_min": 225,
      "x_max": 676,
      "o": "m 676 344 l 225 344 l 225 511 l 676 511 l 676 344 z "
    },
    "­": {
      "ha": 903,
      "x_min": 225,
      "x_max": 676,
      "o": "m 676 344 l 225 344 l 225 511 l 676 511 l 676 344 z "
    },
    "–": {
      "ha": 903,
      "x_min": 68,
      "x_max": 835,
      "o": "m 835 344 l 68 344 l 68 511 l 835 511 l 835 344 z "
    },
    "—": {
      "ha": 1806,
      "x_min": 181,
      "x_max": 1626,
      "o": "m 1626 343 l 181 343 l 181 510 l 1626 510 l 1626 343 z "
    },
    "_": {
      "ha": 903,
      "x_min": 94,
      "x_max": 808,
      "o": "m 808 -165 l 94 -165 l 94 0 l 808 0 l 808 -165 z "
    },
    "(": {
      "ha": 903,
      "x_min": 294,
      "x_max": 649,
      "o": "m 649 -136 l 456 -136 q 331 146 367 14 q 294 415 294 278 l 294 415 q 331 689 294 557 q 456 969 367 821 l 456 969 l 649 969 q 522 688 557 825 q 488 415 488 550 l 488 415 q 522 147 488 283 q 649 -136 557 10 l 649 -136 z "
    },
    ")": {
      "ha": 903,
      "x_min": 254,
      "x_max": 608,
      "o": "m 447 -136 l 447 -136 l 254 -136 q 382 147 347 10 q 417 415 417 283 l 417 415 q 382 688 417 550 q 254 969 347 825 l 254 969 l 447 969 q 573 689 538 821 q 608 415 608 557 l 608 415 q 573 146 608 278 q 447 -136 538 14 z "
    },
    "{": {
      "ha": 903,
      "x_min": 221,
      "x_max": 668,
      "o": "m 668 -136 l 603 -136 q 454 -105 519 -136 q 352 -12 389 -74 q 315 138 315 49 l 315 138 l 315 258 q 290 322 315 297 q 221 350 264 346 l 221 350 l 221 485 q 290 515 264 488 q 315 578 315 542 l 315 578 l 315 696 q 353 844 315 783 q 456 937 390 904 q 603 969 521 969 l 603 969 l 668 969 l 668 821 l 628 821 q 540 785 574 821 q 506 682 506 749 l 506 682 l 506 596 q 460 463 506 513 q 343 394 414 414 l 343 394 l 343 443 q 460 372 414 422 q 506 240 506 322 l 506 240 l 506 151 q 540 48 506 83 q 628 13 574 13 l 628 13 l 668 13 l 668 -136 z "
    },
    "}": {
      "ha": 903,
      "x_min": 235,
      "x_max": 683,
      "o": "m 300 -136 l 300 -136 l 235 -136 l 235 13 l 275 13 q 364 48 331 13 q 397 151 397 83 l 397 151 l 397 240 q 443 372 397 322 q 560 443 489 422 l 560 443 l 561 394 q 444 463 492 414 q 397 596 397 513 l 397 596 l 397 682 q 364 785 397 749 q 275 821 331 821 l 275 821 l 235 821 l 235 969 l 300 969 q 448 937 383 969 q 550 844 513 904 q 588 696 588 783 l 588 696 l 588 578 q 615 515 588 542 q 683 485 642 488 l 683 485 l 683 350 q 615 322 642 346 q 588 258 588 297 l 588 258 l 588 138 q 551 -12 588 49 q 449 -105 514 -74 q 300 -136 385 -136 z "
    },
    "[": {
      "ha": 903,
      "x_min": 282,
      "x_max": 663,
      "o": "m 471 -136 l 282 -136 l 282 972 l 471 972 l 471 -136 z m 663 -136 l 346 -136 l 346 13 l 663 13 l 663 -136 z m 663 824 l 346 824 l 346 972 l 663 972 l 663 824 z "
    },
    "]": {
      "ha": 903,
      "x_min": 240,
      "x_max": 621,
      "o": "m 621 972 l 621 -136 l 432 -136 l 432 972 l 621 972 z m 557 13 l 557 -136 l 240 -136 l 240 13 l 557 13 z m 557 972 l 557 824 l 240 824 l 240 972 l 557 972 z "
    },
    "‚": {
      "ha": 903,
      "x_min": 300,
      "x_max": 571,
      "o": "m 465 0 l 346 0 l 346 218 l 571 218 l 571 0 l 474 -219 l 300 -219 l 356 -118 l 465 0 z "
    },
    "„": {
      "ha": 903,
      "x_min": 161,
      "x_max": 726,
      "o": "m 621 0 l 501 0 l 501 218 l 726 218 l 726 0 l 629 -219 l 456 -219 l 511 -118 l 621 0 z m 326 0 l 207 0 l 207 218 l 432 218 l 432 0 l 335 -219 l 161 -219 l 217 -118 l 326 0 z "
    },
    "“": {
      "ha": 903,
      "x_min": 178,
      "x_max": 743,
      "o": "m 282 838 l 401 838 l 401 619 l 176 619 l 176 838 l 274 1057 l 447 1057 l 392 956 l 282 838 z m 576 838 l 696 838 l 696 619 l 471 619 l 471 838 l 568 1057 l 742 1057 l 686 956 l 576 838 z "
    },
    "”": {
      "ha": 903,
      "x_min": 161,
      "x_max": 726,
      "o": "m 621 839 l 501 839 l 501 1057 l 726 1057 l 726 839 l 629 619 l 456 619 l 511 721 l 621 839 z m 326 839 l 207 839 l 207 1057 l 432 1057 l 432 839 l 335 619 l 161 619 l 217 721 l 326 839 z "
    },
    "‘": {
      "ha": 903,
      "x_min": 326,
      "x_max": 597,
      "o": "m 432 838 l 551 838 l 551 619 l 326 619 l 326 838 l 424 1057 l 597 1057 l 542 956 l 432 838 z "
    },
    "’": {
      "ha": 903,
      "x_min": 313,
      "x_max": 583,
      "o": "m 478 839 l 358 839 l 358 1057 l 583 1057 l 583 839 l 486 619 l 313 619 l 368 721 l 478 839 z "
    },
    "«": {
      "ha": 903,
      "x_min": 100,
      "x_max": 793,
      "o": "m 496 86 l 296 86 l 100 381 l 296 675 l 496 675 l 294 381 l 496 86 z m 793 86 l 593 86 l 397 381 l 593 675 l 793 675 l 592 381 l 793 86 z "
    },
    "»": {
      "ha": 903,
      "x_min": 101,
      "x_max": 796,
      "o": "m 796 381 l 600 86 l 400 86 l 600 381 l 400 675 l 600 675 l 796 381 z m 497 381 l 301 86 l 101 86 l 303 381 l 101 675 l 301 675 l 497 381 z "
    },
    "‹": {
      "ha": 903,
      "x_min": 250,
      "x_max": 646,
      "o": "m 646 86 l 446 86 l 250 381 l 446 675 l 646 675 l 444 381 l 646 86 z "
    },
    "›": {
      "ha": 903,
      "x_min": 257,
      "x_max": 653,
      "o": "m 653 381 l 457 86 l 257 86 l 458 381 l 257 675 l 457 675 l 653 381 z "
    },
    "\"": {
      "ha": 903,
      "x_min": 204,
      "x_max": 700,
      "o": "m 678 646 l 517 646 l 494 1054 l 700 1054 l 678 646 z m 388 646 l 226 646 l 204 1054 l 410 1054 l 388 646 z "
    },
    "'": {
      "ha": 903,
      "x_min": 349,
      "x_max": 554,
      "o": "m 532 643 l 371 643 l 349 1051 l 554 1051 l 532 643 z "
    },
    "ƒ": {
      "ha": 903,
      "x_min": 89,
      "x_max": 813,
      "o": "m 260 0 l 121 0 l 89 149 l 229 149 q 299 170 274 149 q 329 235 325 192 l 329 235 l 388 765 q 462 919 399 867 q 643 971 525 971 l 643 971 l 781 971 l 813 822 l 674 822 q 604 801 631 822 q 574 736 578 781 l 574 736 l 515 206 q 479 94 510 140 q 394 24 449 47 q 260 0 340 0 l 260 0 z m 726 419 l 179 419 l 179 563 l 726 563 l 726 419 z "
    },
    "@": {
      "ha": 903,
      "x_min": 24,
      "x_max": 879,
      "o": "m 603 -239 l 451 -239 q 226 -165 322 -239 q 76 44 129 -90 q 24 360 24 179 l 24 360 q 79 689 24 549 q 237 907 135 829 q 475 985 339 985 l 475 985 q 689 918 599 985 q 829 731 779 851 q 879 449 879 611 l 879 449 q 861 279 879 349 q 813 167 843 210 q 743 104 782 124 q 663 85 704 85 l 663 85 l 510 85 l 506 174 q 469 100 497 128 q 403 72 442 72 l 403 72 q 322 110 358 72 q 264 217 286 149 q 242 375 242 285 l 242 375 q 264 535 242 467 q 322 640 286 603 q 403 676 358 676 l 403 676 q 469 650 442 676 q 506 576 497 624 l 506 576 l 510 665 l 642 665 l 642 210 l 664 210 q 719 276 703 210 q 735 449 735 342 l 735 449 q 703 663 735 574 q 613 801 671 753 q 475 850 554 850 l 475 850 q 315 791 383 850 q 210 622 247 732 q 172 360 172 513 l 172 360 q 208 113 172 217 q 308 -48 243 8 q 458 -104 372 -104 l 458 -104 l 688 -104 l 603 -239 z m 446 247 l 446 247 q 485 273 469 247 q 501 332 500 299 l 501 332 l 501 418 q 485 478 500 453 q 446 503 469 503 l 446 503 q 403 468 419 503 q 386 375 386 433 l 386 375 q 403 283 386 318 q 446 247 419 247 z "
    },
    "&": {
      "ha": 903,
      "x_min": 40,
      "x_max": 890,
      "o": "m 357 -17 l 357 -17 q 192 18 264 -17 q 81 115 121 53 q 40 260 40 178 l 40 260 q 62 374 40 324 q 122 464 83 425 q 211 529 160 503 l 211 529 q 151 631 169 585 q 132 736 132 678 l 132 736 q 167 868 132 811 q 263 956 201 925 q 403 986 325 986 l 403 986 q 538 957 479 986 q 628 876 596 928 q 661 753 661 824 l 661 753 q 639 652 661 700 q 574 567 617 604 q 472 501 532 529 l 472 501 l 611 331 q 644 408 629 365 q 668 492 658 451 l 668 492 l 860 492 q 808 333 840 413 q 732 190 776 254 l 732 190 l 890 0 l 649 0 l 599 63 q 488 4 549 25 q 357 -17 428 -17 z m 364 147 l 364 147 q 431 159 400 147 q 489 192 463 171 l 489 192 l 308 410 q 265 374 285 394 q 235 330 246 354 q 224 276 224 306 l 224 276 q 263 183 224 219 q 364 147 301 147 z m 376 614 l 376 614 q 458 671 432 642 q 483 739 483 700 l 483 739 q 462 806 483 782 q 401 829 440 829 l 401 829 q 339 803 363 829 q 315 738 315 776 l 315 738 q 331 680 315 708 q 376 614 347 651 z "
    },
    "¶": {
      "ha": 903,
      "x_min": 57,
      "x_max": 828,
      "o": "m 529 -264 l 358 -264 l 358 357 q 199 395 267 357 q 94 502 131 433 q 57 665 57 571 l 57 665 q 94 827 57 758 q 199 933 131 896 q 358 969 267 969 l 358 969 l 529 969 l 529 -264 z m 828 -264 l 657 -264 l 657 969 l 828 969 l 828 -264 z "
    },
    "§": {
      "ha": 903,
      "x_min": 81,
      "x_max": 817,
      "o": "m 457 -264 l 457 -264 q 254 -222 339 -264 q 125 -103 169 -179 q 86 71 81 -28 l 86 71 l 285 71 q 324 -60 279 -10 q 457 -111 368 -111 l 457 -111 q 538 -98 504 -111 q 591 -60 572 -85 q 610 -1 610 -35 l 610 -1 q 582 59 610 36 q 509 96 554 82 q 410 122 464 110 q 301 152 356 135 q 201 197 246 169 q 129 269 157 225 q 101 382 101 314 l 101 382 q 125 477 101 435 q 192 544 149 519 l 192 544 q 124 622 146 578 q 101 728 101 667 l 101 728 q 148 861 101 803 q 274 953 194 919 q 449 986 353 986 l 449 986 q 628 952 547 986 q 760 852 710 918 q 813 693 811 786 l 813 693 l 613 693 q 589 770 613 739 q 529 818 565 801 q 449 835 493 835 l 449 835 q 349 807 389 835 q 310 732 310 779 l 310 732 q 338 674 310 694 q 410 640 365 653 q 509 615 454 626 q 618 585 564 603 q 717 538 672 568 q 790 458 763 507 q 817 333 817 410 l 817 333 q 800 247 817 288 q 749 175 783 207 l 749 175 q 799 103 781 146 q 817 -1 817 60 l 817 -1 q 770 -138 817 -79 q 643 -231 724 -197 q 457 -264 563 -264 z m 582 260 l 582 260 q 602 294 594 276 q 610 332 610 311 l 610 332 q 594 379 610 360 q 554 413 579 399 q 495 437 529 426 q 424 456 461 447 q 349 476 388 465 l 349 476 q 320 441 331 461 q 310 397 310 421 l 310 397 q 325 349 310 368 q 368 317 340 329 q 431 296 396 304 q 505 280 465 288 q 582 260 544 272 z "
    },
    "©": {
      "ha": 903,
      "x_min": 26,
      "x_max": 879,
      "o": "m 454 211 l 454 211 q 340 247 390 211 q 262 346 289 283 q 235 490 235 408 l 235 490 q 263 635 235 572 q 342 735 292 699 q 457 769 392 771 l 457 769 q 588 713 535 768 q 649 569 642 657 l 649 569 l 538 569 q 508 643 532 614 q 451 672 485 672 l 451 672 q 376 622 406 672 q 347 493 347 571 l 347 493 q 376 360 347 413 q 451 308 404 308 l 451 308 q 513 338 489 308 q 542 414 538 368 l 542 414 l 650 414 q 588 269 643 326 q 454 211 533 211 z m 453 -17 l 453 -17 q 232 48 328 -17 q 81 226 136 113 q 26 485 26 339 l 26 485 q 81 744 26 631 q 232 922 136 857 q 453 986 328 986 l 453 986 q 674 922 578 986 q 824 744 769 857 q 879 485 879 631 l 879 485 q 824 226 879 339 q 674 48 769 113 q 453 -17 578 -17 z m 451 81 l 451 81 q 615 133 544 81 q 726 276 686 185 q 767 485 767 367 l 767 485 q 726 694 767 603 q 615 838 686 786 q 451 889 544 889 l 451 889 q 289 838 360 889 q 178 694 218 786 q 139 485 139 603 l 139 485 q 178 276 139 367 q 289 133 218 185 q 451 81 360 81 z "
    },
    "®": {
      "ha": 903,
      "x_min": 129,
      "x_max": 772,
      "o": "m 461 663 l 388 663 l 388 733 l 461 733 q 490 744 479 733 q 501 771 501 756 l 501 771 q 491 797 501 788 q 463 806 481 806 l 463 806 l 388 806 l 388 876 l 464 876 q 557 849 524 876 q 590 771 590 822 l 590 771 q 553 693 590 724 q 461 663 515 663 l 461 663 z m 419 563 l 333 563 l 333 876 l 419 876 l 419 563 z m 594 563 l 504 563 q 494 603 501 581 q 469 648 488 626 q 413 683 451 669 l 413 683 l 493 708 q 561 647 536 682 q 594 563 586 613 l 594 563 z m 450 406 l 450 406 q 284 447 357 406 q 170 559 211 489 q 129 718 129 629 l 129 718 q 170 875 129 804 q 284 987 211 946 q 450 1028 357 1028 l 450 1028 q 618 987 546 1028 q 731 876 690 946 q 772 718 772 806 l 772 718 q 731 559 772 629 q 618 447 690 489 q 450 406 546 406 z m 449 476 l 449 476 q 570 508 517 476 q 654 593 624 539 q 685 718 685 647 l 685 718 q 654 842 685 789 q 570 927 624 896 q 449 958 517 958 l 449 958 q 329 927 382 958 q 247 842 276 896 q 217 718 217 789 l 217 718 q 247 593 217 647 q 329 508 276 539 q 449 476 382 476 z "
    },
    "™": {
      "ha": 903,
      "x_min": 24,
      "x_max": 871,
      "o": "m 251 564 l 132 564 l 132 874 l 24 874 l 24 974 l 363 974 l 363 874 l 251 874 l 251 564 z m 474 564 l 353 564 l 417 974 l 550 974 l 601 811 l 613 753 l 624 811 l 674 974 l 807 974 l 871 564 l 750 564 l 728 731 l 722 797 l 703 731 l 658 564 l 567 564 l 521 731 l 504 799 l 497 731 l 474 564 z "
    },
    "°": {
      "ha": 903,
      "x_min": 201,
      "x_max": 701,
      "o": "m 451 507 l 451 507 q 320 537 376 507 q 233 621 264 567 q 201 747 201 675 l 201 747 q 233 873 201 819 q 320 956 264 926 q 451 986 376 986 l 451 986 q 583 956 526 986 q 670 873 639 926 q 701 747 701 819 l 701 747 q 670 621 701 675 q 583 537 639 567 q 451 507 526 507 z m 451 629 l 451 629 q 525 662 497 629 q 553 747 553 694 l 553 747 q 525 832 553 800 q 451 864 497 864 l 451 864 q 379 832 407 864 q 351 747 351 800 l 351 747 q 379 662 351 694 q 451 629 407 629 z "
    },
    "|": {
      "ha": 903,
      "x_min": 350,
      "x_max": 551,
      "o": "m 551 -264 l 350 -264 l 350 1026 l 551 1026 l 551 -264 z "
    },
    "¦": {
      "ha": 903,
      "x_min": 350,
      "x_max": 551,
      "o": "m 551 504 l 350 504 l 350 1026 l 551 1026 l 551 504 z m 551 -264 l 350 -264 l 350 257 l 551 257 l 551 -264 z "
    },
    "ℓ": {
      "ha": 903,
      "x_min": 103,
      "x_max": 810,
      "o": "m 710 0 l 517 0 q 385 24 444 0 q 290 102 325 47 q 254 251 254 157 l 254 251 l 254 636 q 291 826 254 747 q 394 946 328 904 q 550 988 461 988 l 550 988 q 739 918 668 988 q 810 714 810 849 l 810 714 q 783 555 810 626 q 701 428 756 483 q 563 334 647 372 q 365 276 479 296 q 103 256 250 256 l 103 256 l 103 411 q 255 417 179 411 q 399 441 331 424 q 519 492 467 458 q 603 580 572 525 q 633 715 633 635 l 633 715 q 609 803 633 779 q 550 826 585 826 l 550 826 q 470 776 494 826 q 446 636 446 725 l 446 636 l 446 263 q 471 182 446 210 q 551 154 496 154 l 551 154 l 799 154 l 710 0 z "
    },
    "†": {
      "ha": 903,
      "x_min": 169,
      "x_max": 732,
      "o": "m 540 200 l 363 200 l 363 971 l 540 971 l 540 200 z m 732 651 l 169 651 l 169 818 l 732 818 l 732 651 z "
    },
    "₿": {
      "ha": 903,
      "x_min": 99,
      "x_max": 842,
      "o": "m 382 944 l 238 944 l 238 1107 l 382 1107 l 382 944 z m 382 -135 l 238 -135 l 238 28 l 382 28 l 382 -135 z m 600 944 l 457 944 l 457 1107 l 600 1107 l 600 944 z m 600 -135 l 457 -135 l 457 28 l 600 28 l 600 -135 z m 671 507 l 671 507 q 763 458 725 489 q 822 383 801 428 q 842 279 842 338 l 842 279 q 799 131 842 194 q 677 34 756 68 q 492 0 599 0 l 492 0 l 99 0 l 99 969 l 478 969 q 730 901 640 969 q 819 721 819 832 l 819 721 q 801 628 819 669 q 750 557 783 588 q 671 507 717 526 z m 467 803 l 467 803 l 300 803 l 300 574 l 467 574 q 572 606 532 574 q 611 690 611 639 l 611 690 q 571 772 611 742 q 467 803 531 803 z m 300 407 l 300 167 l 486 167 q 593 199 553 167 q 633 286 633 232 l 633 286 q 593 375 633 343 q 481 407 553 407 l 481 407 l 300 407 z "
    },
    "¢": {
      "ha": 903,
      "x_min": 93,
      "x_max": 851,
      "o": "m 571 735 l 381 735 l 381 910 l 571 910 l 571 735 z m 571 -135 l 381 -135 l 381 25 l 571 25 l 571 -135 z m 483 -17 l 483 -17 q 275 32 363 -17 q 140 171 188 81 q 93 385 93 261 l 93 385 q 142 601 93 511 q 281 739 192 692 q 488 786 369 786 l 488 786 q 668 750 589 786 q 794 648 747 714 q 847 492 842 582 l 847 492 l 656 492 q 601 594 644 560 q 488 629 557 629 l 488 629 q 382 601 428 629 q 310 518 336 572 q 285 385 285 464 l 285 385 q 341 203 285 268 q 483 138 397 138 l 483 138 q 565 154 529 138 q 626 202 601 171 q 660 274 651 233 l 660 274 l 851 274 q 792 120 842 185 q 663 19 742 56 q 483 -17 583 -17 z "
    },
    "¤": {
      "ha": 903,
      "x_min": 14,
      "x_max": 889,
      "o": "m 340 746 l 196 626 l 14 822 l 144 956 l 340 746 z m 339 182 l 146 -26 l 14 107 l 194 303 l 339 182 z m 889 107 l 758 -26 l 564 183 l 707 304 l 889 107 z m 889 822 l 708 626 l 563 751 l 758 956 l 889 822 z m 456 58 l 456 58 q 256 112 343 58 q 118 257 168 165 q 68 465 68 349 l 68 465 q 118 671 68 579 q 256 816 168 763 q 456 869 343 869 l 456 869 q 657 816 569 869 q 794 671 744 763 q 843 465 843 579 l 843 465 q 794 257 843 349 q 657 112 744 165 q 456 58 569 58 z m 454 218 l 454 218 q 561 249 514 218 q 635 337 608 281 q 663 465 663 393 l 663 465 q 635 593 663 538 q 561 680 608 649 q 454 711 514 711 l 454 711 q 349 680 396 711 q 275 593 301 649 q 249 465 249 538 l 249 465 q 275 337 249 393 q 349 249 301 281 q 454 218 396 218 z "
    },
    "$": {
      "ha": 903,
      "x_min": 82,
      "x_max": 825,
      "o": "m 547 944 l 357 944 l 357 1107 l 547 1107 l 547 944 z m 547 -137 l 357 -137 l 357 28 l 547 28 l 547 -137 z m 460 -17 l 460 -17 q 258 28 343 -17 q 128 154 174 72 q 82 350 82 236 l 82 350 l 281 350 q 326 201 281 253 q 460 150 372 150 l 460 150 q 573 182 531 150 q 615 271 615 214 l 615 271 q 588 338 615 313 q 513 378 560 363 q 412 408 467 394 q 301 442 357 422 q 199 492 246 461 q 125 573 153 522 q 97 701 97 624 l 97 701 q 141 847 97 782 q 264 949 185 911 q 450 986 343 986 l 450 986 q 631 949 549 986 q 765 840 714 911 q 819 668 817 769 l 819 668 l 617 668 q 591 751 615 718 q 530 802 567 785 q 450 819 493 819 l 450 819 q 347 790 388 819 q 306 707 306 761 l 306 707 q 333 644 306 668 q 408 606 361 621 q 510 578 454 592 q 620 547 565 565 q 722 495 675 528 q 797 409 768 463 q 825 272 825 356 l 825 272 q 778 122 825 186 q 648 20 731 57 q 460 -17 565 -17 z "
    },
    "€": {
      "ha": 903,
      "x_min": 19,
      "x_max": 843,
      "o": "m 522 514 l 19 514 l 19 643 l 542 643 l 522 514 z m 486 322 l 19 322 l 19 451 l 507 451 l 486 322 z m 490 -17 l 490 -17 q 281 45 368 -17 q 148 220 194 107 q 101 485 101 333 l 101 485 q 148 751 101 639 q 282 925 194 864 q 494 986 369 986 l 494 986 q 660 947 585 986 q 784 831 736 907 q 839 647 832 756 l 839 647 l 635 647 q 592 774 628 726 q 493 822 557 822 l 493 822 q 355 734 404 822 q 306 483 306 646 l 306 483 q 329 300 306 375 q 394 186 353 225 q 490 147 436 147 l 490 147 q 596 195 558 147 q 639 329 633 243 l 639 329 l 843 329 q 788 144 833 221 q 668 25 743 67 q 490 -17 593 -17 z "
    },
    "£": {
      "ha": 903,
      "x_min": 107,
      "x_max": 797,
      "o": "m 786 0 l 113 0 l 113 167 q 217 269 183 215 q 250 375 250 324 l 250 375 q 235 453 250 415 q 203 530 221 490 q 172 612 186 569 q 157 703 157 654 l 157 703 q 198 850 157 786 q 313 951 239 914 q 483 989 386 989 l 483 989 q 708 906 625 989 q 797 675 792 822 l 797 675 l 599 675 q 565 784 599 746 q 479 822 531 822 l 479 822 q 420 807 447 822 q 376 763 393 792 q 358 692 358 735 l 358 692 q 372 615 358 654 q 402 534 385 575 q 432 452 419 493 q 444 369 444 411 l 444 369 q 426 282 444 326 q 371 196 408 238 q 275 122 333 154 l 275 122 l 251 167 l 786 167 l 786 0 z m 710 407 l 107 407 l 107 543 l 710 543 l 710 407 z "
    },
    "¥": {
      "ha": 903,
      "x_min": 36,
      "x_max": 867,
      "o": "m 800 365 l 97 365 l 97 503 l 800 503 l 800 365 z m 549 0 l 347 0 l 347 392 l 36 969 l 254 969 l 372 751 l 451 561 l 533 753 l 651 969 l 867 969 l 549 392 l 549 0 z "
    },
    "∕": {
      "ha": 903,
      "x_min": 57,
      "x_max": 846,
      "o": "m 233 0 l 57 0 l 671 969 l 846 969 l 233 0 z "
    },
    "+": {
      "ha": 903,
      "x_min": 96,
      "x_max": 807,
      "o": "m 807 343 l 96 343 l 96 510 l 807 510 l 807 343 z m 533 69 l 368 69 l 368 782 l 533 782 l 533 69 z "
    },
    "−": {
      "ha": 903,
      "x_min": 93,
      "x_max": 808,
      "o": "m 808 343 l 93 343 l 93 510 l 808 510 l 808 343 z "
    },
    "×": {
      "ha": 903,
      "x_min": 126,
      "x_max": 776,
      "o": "m 776 632 l 243 97 l 126 214 l 660 750 l 776 632 z m 776 214 l 660 97 l 126 632 l 243 750 l 776 214 z "
    },
    "÷": {
      "ha": 903,
      "x_min": 93,
      "x_max": 808,
      "o": "m 808 343 l 93 343 l 93 510 l 808 510 l 808 343 z m 564 65 l 339 65 l 339 283 l 564 283 l 564 65 z m 564 581 l 339 581 l 339 799 l 564 799 l 564 581 z "
    },
    "=": {
      "ha": 903,
      "x_min": 93,
      "x_max": 808,
      "o": "m 808 501 l 93 501 l 93 668 l 808 668 l 808 501 z m 808 179 l 93 179 l 93 346 l 808 346 l 808 179 z "
    },
    ">": {
      "ha": 903,
      "x_min": 86,
      "x_max": 801,
      "o": "m 801 344 l 86 85 l 86 261 l 579 428 l 86 593 l 86 769 l 801 511 l 801 344 z "
    },
    "<": {
      "ha": 903,
      "x_min": 90,
      "x_max": 806,
      "o": "m 806 261 l 806 85 l 90 344 l 90 511 l 806 769 l 806 593 l 313 428 l 806 261 z "
    },
    "±": {
      "ha": 903,
      "x_min": 93,
      "x_max": 808,
      "o": "m 807 468 l 96 468 l 96 633 l 807 633 l 807 468 z m 536 229 l 365 229 l 365 868 l 536 868 l 536 229 z m 808 0 l 93 0 l 93 167 l 808 167 l 808 0 z "
    },
    "~": {
      "ha": 903,
      "x_min": 68,
      "x_max": 836,
      "o": "m 597 294 l 597 294 q 510 310 550 294 q 436 347 469 325 q 373 384 403 368 q 314 400 343 400 l 314 400 q 256 373 281 400 q 231 299 232 346 l 231 299 l 68 299 q 103 444 68 386 q 194 531 139 501 q 306 561 249 561 l 306 561 q 394 545 354 561 q 468 508 435 529 q 531 472 501 488 q 589 456 561 456 l 589 456 q 645 483 622 456 q 672 556 668 510 l 672 556 l 836 556 q 799 415 833 474 q 710 325 764 356 q 597 294 657 294 z "
    },
    "¬": {
      "ha": 903,
      "x_min": 93,
      "x_max": 808,
      "o": "m 808 158 l 618 158 l 618 510 l 808 510 l 808 158 z m 808 343 l 93 343 l 93 510 l 808 510 l 808 343 z "
    },
    "^": {
      "ha": 903,
      "x_min": 126,
      "x_max": 776,
      "o": "m 310 485 l 126 485 l 374 969 l 528 969 l 776 485 l 592 485 l 451 767 l 310 485 z "
    },
    "µ": {
      "ha": 903,
      "x_min": 81,
      "x_max": 796,
      "o": "m 251 -264 l 81 -264 l 81 401 l 269 401 l 269 128 l 251 94 l 251 -264 z m 81 769 l 271 769 l 271 292 q 307 184 271 225 q 400 143 343 143 l 400 143 q 522 190 467 143 q 622 342 576 236 l 622 342 l 650 167 q 524 28 601 72 q 363 -17 447 -17 l 363 -17 q 213 21 276 -17 q 115 125 149 58 q 81 281 81 192 l 81 281 l 81 769 z m 606 769 l 796 769 l 796 0 l 613 0 l 606 149 l 606 769 z "
    },
    "%": {
      "ha": 903,
      "x_min": 39,
      "x_max": 864,
      "o": "m 221 0 l 68 0 l 682 969 l 835 969 l 221 0 z m 661 -17 l 661 -17 q 554 13 600 -17 q 483 97 508 43 q 458 224 458 151 l 458 224 q 483 351 458 297 q 554 433 508 404 q 661 463 600 463 l 661 463 q 768 433 722 463 q 839 351 814 404 q 864 224 864 297 l 864 224 q 839 97 864 151 q 768 13 814 43 q 661 -17 722 -17 z m 661 106 l 661 106 q 710 138 692 106 q 728 224 728 169 l 728 224 q 710 308 728 276 q 661 340 692 340 l 661 340 q 614 308 632 340 q 596 224 596 276 l 596 224 q 614 138 596 169 q 661 106 632 106 z m 242 510 l 242 510 q 135 540 181 510 q 64 624 89 569 q 39 750 39 678 l 39 750 q 64 877 39 824 q 135 960 89 931 q 242 989 181 989 l 242 989 q 349 960 304 989 q 419 877 394 931 q 444 750 444 824 l 444 750 q 419 624 444 678 q 349 540 394 569 q 242 510 304 510 z m 242 632 l 242 632 q 290 664 272 632 q 308 750 308 696 l 308 750 q 290 835 308 803 q 242 867 272 867 l 242 867 q 195 835 213 867 q 178 750 178 803 l 178 750 q 195 664 178 696 q 242 632 213 632 z "
    },
    "↑": {
      "ha": 903,
      "x_min": 100,
      "x_max": 803,
      "o": "m 232 464 l 100 333 l 100 564 l 451 914 l 803 564 l 803 333 l 668 467 l 526 619 l 533 410 l 533 0 l 368 0 l 368 410 l 375 618 l 232 464 z "
    },
    "↓": {
      "ha": 903,
      "x_min": 100,
      "x_max": 803,
      "o": "m 671 433 l 803 564 l 803 333 l 451 -17 l 100 333 l 100 564 l 235 431 l 376 278 l 369 488 l 369 897 l 535 897 l 535 488 l 528 279 l 671 433 z "
    },
    "¨": {
      "ha": 903,
      "x_min": 318,
      "x_max": 832,
      "o": "m 833 883 l 628 883 l 628 1069 l 833 1069 l 833 883 z m 524 883 l 319 883 l 319 1069 l 524 1069 l 524 883 z "
    },
    "`": {
      "ha": 903,
      "x_min": 299,
      "x_max": 621,
      "o": "m 501 1093 l 621 856 l 454 856 l 299 1093 l 501 1093 z "
    },
    "´": {
      "ha": 903,
      "x_min": 511,
      "x_max": 832,
      "o": "m 676 856 l 511 856 l 631 1093 l 832 1093 l 676 856 z "
    },
    "ˆ": {
      "ha": 903,
      "x_min": 292,
      "x_max": 833,
      "o": "m 471 856 l 292 856 l 458 1093 l 667 1093 l 833 856 l 656 856 l 563 996 l 471 856 z "
    },
    "˚": {
      "ha": 903,
      "x_min": 483,
      "x_max": 833,
      "o": "m 657 822 l 657 822 q 531 867 579 822 q 483 985 483 911 l 483 985 q 532 1101 483 1057 q 657 1146 581 1146 l 657 1146 q 785 1101 736 1146 q 833 985 833 1057 l 833 985 q 785 867 833 911 q 657 822 736 822 z m 657 917 l 657 917 q 708 935 689 917 q 728 985 728 954 l 728 985 q 708 1034 728 1015 q 658 1053 689 1053 l 658 1053 q 608 1034 628 1053 q 589 986 589 1015 l 589 986 q 608 935 589 954 q 657 917 628 917 z "
    },
    "˜": {
      "ha": 903,
      "x_min": 190,
      "x_max": 760,
      "o": "m 331 857 l 331 857 l 190 879 q 240 1024 199 968 q 356 1079 281 1079 l 356 1079 q 422 1070 393 1079 q 473 1049 450 1061 q 516 1027 496 1036 q 554 1018 536 1018 l 554 1018 q 599 1039 585 1018 q 618 1089 614 1060 l 618 1089 l 760 1067 q 706 923 749 979 q 590 867 664 867 l 590 867 q 513 883 547 867 q 448 915 478 899 q 390 931 418 931 l 390 931 q 348 908 363 931 q 331 857 333 886 z "
    },
    "¯": {
      "ha": 903,
      "x_min": 346,
      "x_max": 833,
      "o": "m 833 886 l 344 886 l 344 1028 l 833 1028 l 833 886 z "
    },
    "¸": {
      "ha": 903,
      "x_min": 513,
      "x_max": 833,
      "o": "m 650 -308 l 650 -308 q 581 -301 617 -308 q 514 -285 546 -294 l 514 -285 l 514 -197 q 564 -210 536 -206 q 618 -215 592 -215 l 618 -215 q 669 -206 647 -215 q 692 -179 692 -197 l 692 -179 q 672 -153 692 -162 q 625 -144 651 -144 l 625 -144 q 599 -147 613 -144 q 575 -153 586 -149 l 575 -153 l 544 -103 l 629 38 l 725 6 l 676 -68 q 689 -66 683 -67 q 700 -65 694 -65 l 700 -65 q 764 -78 733 -65 q 814 -115 794 -90 q 833 -179 833 -140 l 833 -179 q 808 -252 833 -224 q 742 -294 783 -281 q 650 -308 700 -308 z "
    }
  },
  "ascender": 1301,
  "descender": -319,
  "underlinePosition": -139,
  "underlineThickness": 69,
  "boundingBox": {
    "yMin": -307,
    "xMin": -65,
    "yMax": 1526,
    "xMax": 2632
  },
  "resolution": 1000,
  "original_font_information": {
    "256": {
      "en": "Weight"
    },
    "262": {
      "en": "SemiBold"
    },
    "275": {
      "en": "Italic"
    },
    "276": {
      "en": "Roman"
    },
    "copyright": {
      "en": "Copyright 2021 The Azeret Project Authors (https://github.com/displaay/azeret)"
    },
    "fontFamily": {
      "en": "Azeret Mono Thin SemiBold"
    },
    "fontSubfamily": {
      "en": "Regular"
    },
    "uniqueID": {
      "en": "1.002;NONE;AzeretMonoThin-SemiBold"
    },
    "fullName": {
      "en": "Azeret Mono Thin SemiBold"
    },
    "version": {
      "en": "Version 1.002"
    },
    "postScriptName": {
      "en": "AzeretMonoThin-SemiBold"
    },
    "licenseURL": {
      "en": "https://scripts.sil.org/OFL"
    },
    "preferredFamily": {
      "en": "Azeret Mono Thin"
    },
    "preferredSubfamily": {
      "en": "SemiBold"
    }
  },
  "cssFontWeight": "600",
  "cssFontStyle": "normal"
}